import {
  DatePicker,
  IDatePickerStrings,
  IDatePickerStyleProps,
  IDatePickerStyles,
  IStyleFunctionOrObject,
  IconButton,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { toLocaleDateLong, toLocaleDateNumeric, toLocaleDateShort } from 'utils/datetime';
import { cancelIcon } from 'globalStyles';

interface ILocalizedDatePickerProps {
  value?: Date;
  onDateChange: (value: Date | undefined) => void;
  firstDayOfWeek?: number;
  showWeekNumbers?: boolean;
  showMonthPickerAsOverlay?: boolean;
  firstWeekOfYear?: number;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  showGoToToday?: boolean;
  styles?: IStyleFunctionOrObject<IDatePickerStyleProps, IDatePickerStyles>;
  disabled?: boolean;
  underline?: boolean;
  format?: 'long' | 'short' | 'numeric';
  allowEmptyValue?: boolean;
}

const LocalizedDatePicker = (props: ILocalizedDatePickerProps) => {
  const { t } = useTranslation(['dateTimeComponent']);

  const dateChangeHandler = (date: Date | null | undefined) => {
    if (date) {
      props.onDateChange(date);
    }
  };

  const DayPickerStrings: IDatePickerStrings = {
    months: [
      t('dateTimeComponent:Months.January'),
      t('dateTimeComponent:Months.February'),
      t('dateTimeComponent:Months.March'),
      t('dateTimeComponent:Months.April'),
      t('dateTimeComponent:Months.May'),
      t('dateTimeComponent:Months.June'),
      t('dateTimeComponent:Months.July'),
      t('dateTimeComponent:Months.August'),
      t('dateTimeComponent:Months.September'),
      t('dateTimeComponent:Months.October'),
      t('dateTimeComponent:Months.November'),
      t('dateTimeComponent:Months.December'),
    ],

    shortMonths: [
      t('dateTimeComponent:MonthsShort.Jan'),
      t('dateTimeComponent:MonthsShort.Feb'),
      t('dateTimeComponent:MonthsShort.Mar'),
      t('dateTimeComponent:MonthsShort.Apr'),
      t('dateTimeComponent:MonthsShort.May'),
      t('dateTimeComponent:MonthsShort.Jun'),
      t('dateTimeComponent:MonthsShort.Jul'),
      t('dateTimeComponent:MonthsShort.Aug'),
      t('dateTimeComponent:MonthsShort.Sep'),
      t('dateTimeComponent:MonthsShort.Oct'),
      t('dateTimeComponent:MonthsShort.Nov'),
      t('dateTimeComponent:MonthsShort.Dec'),
    ],

    days: [
      t('dateTimeComponent:WeekDays.Sunday'),
      t('dateTimeComponent:WeekDays.Monday'),
      t('dateTimeComponent:WeekDays.Tuesday'),
      t('dateTimeComponent:WeekDays.Wednesday'),
      t('dateTimeComponent:WeekDays.Thursday'),
      t('dateTimeComponent:WeekDays.Friday'),
      t('dateTimeComponent:WeekDays.Saturday'),
    ],

    shortDays: [
      t('dateTimeComponent:WeekDays.Sunday')[0],
      t('dateTimeComponent:WeekDays.Monday')[0],
      t('dateTimeComponent:WeekDays.Tuesday')[0],
      t('dateTimeComponent:WeekDays.Wednesday')[0],
      t('dateTimeComponent:WeekDays.Thursday')[0],
      t('dateTimeComponent:WeekDays.Friday')[0],
      t('dateTimeComponent:WeekDays.Saturday')[0],
    ],

    goToToday: t('dateTimeComponent:Extra.GoToToday'),
    prevMonthAriaLabel: t('dateTimeComponent:Extra.GoToPreviousMonth'),
    nextMonthAriaLabel: t('dateTimeComponent:Extra.GoToNextMonth'),
    prevYearAriaLabel: t('dateTimeComponent:Extra.GoToPreviousYear'),
    nextYearAriaLabel: t('dateTimeComponent:Extra.GoToNextYear'),

    isRequiredErrorMessage: t('dateTimeComponent:Errors.isRequiredErrorMessage'),
    invalidInputErrorMessage: t('dateTimeComponent:Errors.invalidInputErrorMessage'),
    isOutOfBoundsErrorMessage: t('dateTimeComponent:Errors.isOutOfBoundsErrorMessage'),
    isResetStatusMessage: t('dateTimeComponent:Errors.isResetStatusMessage'),
  };

  const onFormatDate = (date?: Date): string => {
    switch (props.format) {
      case 'long':
        return toLocaleDateLong(date);
      case 'short':
        return toLocaleDateShort(date);
      case 'numeric':
        return toLocaleDateNumeric(date);
      default:
        return toLocaleDateLong(date);
    }
  };

  const borderStyle = '1px solid grey';

  return (
    <Stack horizontal verticalAlign="end">
      <Stack.Item grow>
        <DatePicker
          disabled={props.disabled}
          styles={props.styles}
          showWeekNumbers={props.showWeekNumbers}
          firstWeekOfYear={props.firstWeekOfYear}
          showMonthPickerAsOverlay={props.showMonthPickerAsOverlay}
          firstDayOfWeek={props.firstDayOfWeek}
          value={props.value}
          strings={DayPickerStrings}
          onSelectDate={(date: Date | null | undefined) => dateChangeHandler(date)}
          minDate={props.minDate}
          maxDate={props.maxDate}
          highlightSelectedMonth={true}
          highlightCurrentMonth={true}
          formatDate={onFormatDate}
          label={props.label}
          showGoToToday={props.showGoToToday ?? true}
          underlined={props.underline}
        />
      </Stack.Item>
      {props.allowEmptyValue && (
        <Stack.Item>
          <TooltipHost content={t('dateTimeComponent:Extra.ClearDate')}>
            <IconButton
              disabled={!props.value}
              onClick={() => {
                props.onDateChange(undefined);
              }}
              iconProps={cancelIcon}
              styles={{
                root: { top: -5, borderRight: borderStyle, borderTop: borderStyle, borderBottom: borderStyle },
              }}
            />
          </TooltipHost>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default LocalizedDatePicker;
