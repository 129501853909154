import Theme_Translation from './theme_Translation';
import Task from './tasks/task';
import Joi from 'joi';
import { getLocalizedMessageOptions } from '../services/Localization/joiValidation';
import { isObjectEqual } from 'utils/object';
import { TFunction } from 'i18next';
import { PDCAState } from './pdca';
import Entity from './entity';
import ISOControl from './isoControl';
import { getPDCAStateText } from 'globalFunctions';

export default class Theme {
  themeId: number;

  code: string;

  parentThemeId?: number;

  state: PDCAState;

  groupId?: string;

  ownerId?: string;

  commentTrailId: number;

  auditTrailId: number;

  customNormIds?: number[];

  isoControlIds?: number[];

  isoNormIds?: number[];

  trans?: Theme_Translation[];

  tasks: Task[];

  monitoringParent?: Entity;

  tagIds: number[];

  isoControls?: ISOControl[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description?: string;

  background?: string;

  implementation?: string;

  constructor() {
    this.themeId = 0;
    this.code = '';
    this.name = '';
    this.transIdx = -1;
    this.commentTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.auditTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.state = PDCAState.Plan;
    this.tasks = [];
    this.tagIds = [];
  }

  static getControlStateText = (state: PDCAState, t: TFunction<string[]>): string => {
    return getPDCAStateText(state, t);
  };

  isEqual(item: Theme) {
    if (item.themeId !== this.themeId) return false;
    if (item.parentThemeId !== this.parentThemeId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.background !== this.background) return false;
    if (item.implementation !== this.implementation) return false;
    if (item.code !== this.code) return false;
    if (item.state !== this.state) return false;
    if (item.groupId !== this.groupId) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (!isObjectEqual(item.isoNormIds, this.isoNormIds)) return false;
    if (!isObjectEqual(item.customNormIds, this.customNormIds)) return false;
    if (!isObjectEqual(item.isoControlIds, this.isoControlIds)) return false;
    if (!isObjectEqual(item.trans, this.trans)) return false;
    if (!isObjectEqual(item.tagIds, this.tagIds)) return false;

    return true;
  }

  clone(): Theme {
    const newTheme = new Theme();

    newTheme.themeId = this.themeId;
    newTheme.parentThemeId = this.parentThemeId;
    newTheme.name = this.name;
    newTheme.description = this.description;
    newTheme.background = this.background;
    newTheme.implementation = this.implementation;
    newTheme.code = this.code;
    newTheme.state = this.state;
    newTheme.groupId = this.groupId;
    newTheme.ownerId = this.ownerId;
    newTheme.commentTrailId = this.commentTrailId;
    newTheme.auditTrailId = this.auditTrailId;
    newTheme.transIdx = this.transIdx;
    newTheme.trans = this.trans ? [...this.trans] : undefined;
    newTheme.isoNormIds = this.isoNormIds;
    newTheme.customNormIds = this.customNormIds;
    newTheme.monitoringParent = this.monitoringParent?.clone();
    newTheme.isoControls = this.isoControls ? [...this.isoControls] : undefined;
    newTheme.tagIds = [...this.tagIds];

    return newTheme;
  }

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }
}

export class ThemeTaskStats {
  themeId: number;

  taskTotalCount: number;

  taskCompletedCount: number;

  constructor() {
    this.themeId = 0;
    this.taskTotalCount = 0;
    this.taskCompletedCount = 0;
  }
}
