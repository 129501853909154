import _ from 'lodash';

export function areDifferent<T>(
  array1: T[] | undefined,
  array2: T[] | undefined,
  comparator: (a1: T, a2: T) => boolean,
) {
  array1 = array1 ?? [];
  array2 = array2 ?? [];
  if (array1.length === 0 && array2.length === 0) {
    return false;
  }
  if (array1.length !== array2.length) {
    return true;
  }

  if (_.differenceWith<T, T>(array1, array2, comparator).length > 0) {
    return true;
  }
  if (_.differenceWith<T, T>(array2, array1, comparator).length > 0) {
    return true;
  }

  return false;
}

export function rotateArrayRight<T>(count: number, arr: T[]): T[] {
  // reverse helper function
  function reverse(arr: T[], start: number, end: number) {
    while (start < end) {
      [arr[start], arr[end]] = [arr[end], arr[start]];
      start++;
      end--;
    }
  }

  count %= arr.length;

  reverse(arr, 0, arr.length - 1);
  reverse(arr, 0, count - 1);
  reverse(arr, count, arr.length - 1);

  return arr;
}

export function rotateArrayLeft<T>(count: number, arr: T[]): T[] {
  // reverse helper function
  function reverse(arr: T[], start: number, end: number) {
    while (start < end) {
      [arr[start], arr[end]] = [arr[end], arr[start]];
      start++;
      end--;
    }
  }

  count %= arr.length;
  if (count === 0) return arr;

  reverse(arr, 0, arr.length - 1);
  reverse(arr, 0, arr.length - 1 - count);
  reverse(arr, arr.length - count, arr.length - 1);

  return arr;
}

export function numberArrayToString(ar: number[]): string {
  return ar.toString();
}

export function fromStringToNumberArray(ar: string): number[] {
  try {
    const split = ar.split(',');

    return split.map((item) => +item);
  } catch {
    return [];
  }
}

type ObjectKey = string | number | symbol;

export const groupBy = <K extends ObjectKey, TItem extends Record<K, ObjectKey>>(
  items: TItem[],
  key: K,
): Record<ObjectKey, TItem[]> =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {} as Record<ObjectKey, TItem[]>,
  );

export function moveItem<T>(arr: (T | undefined)[], oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}
