import { TeamsChannel } from 'models/teamsChannel';
import Group from 'models/group';
import http from './httpService';
import IdListDTO from 'models/dto/IdListDTO';
import { mapFromGroups, mapFromTeamChannels } from 'mappings/dataMapping';
import AppError from 'utils/appError';
import GroupDTO from 'models/dto/groupDTO';

export async function apiGetGroups(updateFirst: boolean, accessToken: string): Promise<Group[]> {
  try {
    const ar = await http.get<GroupDTO[]>(`/groups?update=${updateFirst}`, http.getRequestConfig(accessToken));

    return mapFromGroups(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAdGroups(searchText: string | undefined, accessToken: string): Promise<Group[]> {
  try {
    if (!searchText) {
      searchText = '';
    }

    const ar = await http.get<GroupDTO[]>(`/groups/ad?search=${searchText}`, http.getRequestConfig(accessToken));

    return mapFromGroups(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddGroup(accessToken: string, group: Group): Promise<Group> {
  try {
    const groupDTO: GroupDTO = new GroupDTO(group.id, group.name, group.email);
    const ar = await http.post<GroupDTO>('/groups', groupDTO, http.getRequestConfig(accessToken));

    return new Group(ar.data.groupId, ar.data.name, ar.data.email);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveGroup(accessToken: string, groupIds: string[]): Promise<void> {
  try {
    const idListDTO: IdListDTO = new IdListDTO(groupIds);
    await http.post<IdListDTO>('/groups/delete', idListDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetChannelsForGroup(groupId: string, accessToken: string): Promise<TeamsChannel[]> {
  try {
    const ar = await http.get<TeamsChannel[]>(`/groups/${groupId}/channels`, http.getRequestConfig(accessToken));

    //handle 204 NoContent for AD groups that have no Team
    if (!ar.data) {
      return [];
    }

    return mapFromTeamChannels(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
