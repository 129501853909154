import Tag from 'models/tag';
import { apiGetTags } from 'services/Api/tagService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, IEntityCache } from './entityCache';

export class TagCache extends EntityCache<Tag> implements IEntityCache<Tag, TagCache> {
  private isLoading: boolean = false;

  private tagsPromise: Promise<Tag[]> | undefined;

  itemSet: Map<number, Tag> = new Map<number, Tag>();

  has(id: number | undefined): boolean {
    let tag = this.items?.find((i) => i.tagId === id);
    if (!tag) return false;

    return true;
  }

  get(id: number | undefined): Tag {
    let tag = this.items?.find((i) => i.tagId === id);
    if (tag) {
      return tag;
    }
    if (id) {
      this.addMiss();
      tag = Tag.getEmptyTag();
    } else {
      tag = Tag.getEmptyTag();
    }

    return tag;
  }

  getForValue(group: string, value: string): Tag | undefined {
    const groupLower = group.toLowerCase();
    const valueLower = value.toLowerCase();
    let tag = this.items?.find(
      (i) => i.tagName.toLowerCase() === groupLower && i.tagValue.toLowerCase() === valueLower,
    );

    return tag;
  }

  add(item: Tag) {
    this.items.push(item);
    this.itemSet.set(item.tagId, item);
  }

  update(item: Tag) {
    const idx = this.items.findIndex((i) => i.tagId === item.tagId);
    if (idx >= 0) {
      this.items[idx] = item;
      this.itemSet.set(item.tagId, item);
    }
  }

  remove(item: Tag) {
    const idx = this.items.findIndex((i) => i.tagId === item.tagId);
    if (idx >= 0) {
      this.items.splice(idx, 1);
      this.itemSet.delete(item.tagId);
    }
  }

  getItemsForId(ids: number[] | undefined): Tag[] {
    if (!ids) return [];

    this.items.forEach((i) => {
      this.itemSet.set(i.tagId, i);
    });

    const result: Tag[] = [];
    ids.forEach((i) => {
      const tag = this.itemSet.get(i);
      if (tag) {
        result.push(tag);
      } else {
        result.push(Tag.getEmptyTag());
      }
    });

    return result;
  }

  async getItems(refresh: boolean = false): Promise<Tag[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.tagsPromise = apiGetTags(accessToken);
          this.isLoading = true;
          this.items = await this.tagsPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.isLoading = false;
          this.tagsPromise = undefined;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.tagsPromise) {
      return await this.tagsPromise;
    } else {
      return this.items;
    }
  }

  clone(): TagCache {
    const tagCache: TagCache = new TagCache();
    tagCache.items = this.items?.map((u) => u.clone());

    return tagCache;
  }
}
