import Control_Translation from './control_Translation';
import Task from './tasks/task';
import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { isObjectEqual } from 'utils/object';
import { TFunction } from 'i18next';
import { PDCAState } from './pdca';
import Entity from './entity';
import ISOControl from './isoControl';
import { getPDCAStateText } from 'globalFunctions';
import { sortOnCode } from 'utils/sorting';
import Dashboard from './dashboard';

export enum ControlTypes {
  Theme = 0,
  Measure = 1,
}

//order of this enum sets the order in filters
export enum ApplicabilityReasons {
  Basic = 0,
  RiskAnalysis = 1,
  LawsAndRegulations = 2,
  Contract = 4,
  NotApplicable = 3,
}

export enum ImplementationStates {
  Auto = 0,
  Full = 1,
  InProgress = 2,
}

export default class Control {
  controlId: number;

  code: string;

  parentControlId?: number;

  controlType?: ControlTypes;

  state: PDCAState;

  applicabilityReason: ApplicabilityReasons[];

  implementationState: ImplementationStates;

  groupId?: string;

  ownerId?: string;

  commentTrailId: number;

  auditTrailId: number;

  customNormIds?: number[];

  isoControlIds?: number[];

  isoNormIds?: number[];

  dashboard?: Dashboard;

  trans?: Control_Translation[];

  tasks: Task[];

  monitoringParent?: Entity;

  tagIds: number[];

  isoControls?: ISOControl[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description?: string;

  background?: string;

  implementation?: string;

  outOfScopeReason?: string;

  constructor() {
    this.controlId = 0;
    this.code = '';
    this.name = '';
    this.transIdx = -1;
    this.commentTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.auditTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.state = PDCAState.Plan;
    this.applicabilityReason = [ApplicabilityReasons.Basic];
    this.tasks = [];
    this.tagIds = [];
    this.implementationState = ImplementationStates.Auto;
  }

  static getControlStateText = (state: PDCAState, t: TFunction<string[]>): string => {
    return getPDCAStateText(state, t);
  };

  static getImplementationStateText = (state: ImplementationStates, t: TFunction<string[]>): string => {
    switch (state) {
      case ImplementationStates.Full:
        return t('control:ImplementationState.Full');
      case ImplementationStates.InProgress:
        return t('control:ImplementationState.InProgress');
      case ImplementationStates.Auto:
        return t('control:ImplementationState.Auto');
    }
  };

  static getApplicabilityReasonText = (applicabilities: ApplicabilityReasons[], t: TFunction<string[]>): string => {
    const reasons: string[] = [];

    if (applicabilities.includes(ApplicabilityReasons.Basic)) {
      reasons.push(t('control:ApplicabilityReason.Basic'));
    }

    if (applicabilities.includes(ApplicabilityReasons.RiskAnalysis)) {
      reasons.push(t('control:ApplicabilityReason.RiskAnalysis'));
    }

    if (applicabilities.includes(ApplicabilityReasons.LawsAndRegulations)) {
      reasons.push(t('control:ApplicabilityReason.LawsAndRegulations'));
    }

    if (applicabilities.includes(ApplicabilityReasons.Contract)) {
      reasons.push(t('control:ApplicabilityReason.Contract'));
    }

    if (applicabilities.includes(ApplicabilityReasons.NotApplicable)) {
      reasons.push(t('control:ApplicabilityReason.NotApplicable'));
    }

    return reasons.join(', ');
  };

  static hasScheduledParent = (controls: Control[], control: Control): boolean => {
    return this.getScheduledParent(controls, control) !== undefined;
  };

  static getScheduledParent = (controls: Control[], control: Control): Entity | undefined => {
    if (!control.parentControlId) return undefined;
    let parent: Control | undefined = controls.find((c) => c.controlId === control.parentControlId);
    let max: number = 0;

    while (parent !== undefined && max < 100) {
      if (parent && parent.monitoringParent) {
        return parent.monitoringParent;
      }

      // eslint-disable-next-line no-loop-func
      parent = controls.find((c) => c.controlId === parent?.parentControlId);
      max++;
    }

    return undefined;
  };

  static getControlImplementationStateText = (
    controls: Control[],
    control: Control,
    t: TFunction<string[]>,
  ): string => {
    if (control.applicabilityReason.includes(ApplicabilityReasons.NotApplicable))
      return t('translation:General.Words.NotApplicable');

    switch (control.implementationState) {
      case ImplementationStates.Full:
        return t('control:ImplementationState.Full');
      case ImplementationStates.InProgress:
        return t('control:ImplementationState.InProgress');
      case ImplementationStates.Auto:
        if (
          control.state !== PDCAState.Plan &&
          (control.monitoringParent !== undefined || this.hasScheduledParent(controls, control))
        ) {
          return t('control:ImplementationState.Full');
        } else {
          return t('control:ImplementationState.InProgress');
        }
    }
  };

  static getControlImplementationState = (
    controls: Control[],
    control: Control,
    t: TFunction<string[]>,
  ): ImplementationStates => {
    if (control.applicabilityReason.includes(ApplicabilityReasons.NotApplicable))
      //Returning ImplementationStates.Auto as it will not affect the filtering norms
      return ImplementationStates.Auto;

    switch (control.implementationState) {
      case ImplementationStates.Full:
        return ImplementationStates.Full;
      case ImplementationStates.InProgress:
        return ImplementationStates.InProgress;
      case ImplementationStates.Auto:
        if (
          control.state !== PDCAState.Plan &&
          (control.monitoringParent !== undefined || this.hasScheduledParent(controls, control))
        ) {
          return ImplementationStates.Full;
        } else {
          return ImplementationStates.InProgress;
        }
    }
  };

  isEqual(item: Control) {
    if (item.controlId !== this.controlId) return false;
    if (item.parentControlId !== this.parentControlId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.background !== this.background) return false;
    if (item.implementation !== this.implementation) return false;
    if (item.code !== this.code) return false;
    if (item.state !== this.state) return false;
    if (item.applicabilityReason !== this.applicabilityReason) return false;
    if (item.implementationState !== this.implementationState) return false;
    if (item.outOfScopeReason !== this.outOfScopeReason) return false;
    if (item.groupId !== this.groupId) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (!isObjectEqual(item.isoNormIds, this.isoNormIds)) return false;
    if (!isObjectEqual(item.customNormIds, this.customNormIds)) return false;
    if (!isObjectEqual(item.isoControlIds, this.isoControlIds)) return false;
    if (!isObjectEqual(item.trans, this.trans)) return false;
    if (!isObjectEqual(item.tagIds, this.tagIds)) return false;

    return true;
  }

  clone(): Control {
    const newControl = new Control();

    newControl.controlId = this.controlId;
    newControl.parentControlId = this.parentControlId;
    newControl.controlType = this.controlType;
    newControl.name = this.name;
    newControl.description = this.description;
    newControl.background = this.background;
    newControl.implementation = this.implementation;
    newControl.code = this.code;
    newControl.state = this.state;
    newControl.applicabilityReason = this.applicabilityReason;
    newControl.outOfScopeReason = this.outOfScopeReason;
    newControl.implementationState = this.implementationState;
    newControl.groupId = this.groupId;
    newControl.ownerId = this.ownerId;
    newControl.commentTrailId = this.commentTrailId;
    newControl.auditTrailId = this.auditTrailId;
    newControl.transIdx = this.transIdx;
    newControl.trans = this.trans ? [...this.trans] : undefined;
    newControl.tasks = [...this.tasks];
    newControl.isoNormIds = this.isoNormIds;
    newControl.isoControlIds = this.isoControlIds;
    newControl.customNormIds = this.customNormIds;
    newControl.monitoringParent = this.monitoringParent?.clone();
    newControl.isoControls = this.isoControls ? [...this.isoControls] : undefined;
    newControl.tagIds = [...this.tagIds];
    newControl.dashboard = this.dashboard?.clone();

    return newControl;
  }

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }

  static sortOnHierarchy(controls: Control[]): Control[] {
    const result: Control[] = [];
    //get all root nodes and nodes that don't have a parent in this collection (can be the case for filtered controls)
    const parents: Control[] = controls.filter(
      (control) => !control.parentControlId || !controls.some((c) => c.controlId === control.parentControlId),
    );
    //sort the parents
    parents.sort((a, b) => sortOnCode(a.code, b.code));
    //add all children
    for (let idx = 0; idx < parents.length; idx++) {
      const parent = parents[idx];
      result.push(parent);
      result.push(...Control.getSortedChildren(controls, parent));
    }

    return result;
  }

  static getSortedChildren(controls: Control[], parent: Control): Control[] {
    const result: Control[] = [];
    const children = controls.filter((control) => control.parentControlId === parent.controlId);
    children.sort((a, b) => sortOnCode(a.code, b.code));

    for (let idx = 0; idx < children.length; idx++) {
      const child = children[idx];
      result.push(child);
      result.push(...Control.getSortedChildren(controls, child));
    }

    return result;
  }
}

export class ControlTaskStats {
  controlId: number;

  taskTotalCount: number;

  taskCompletedCount: number;

  constructor() {
    this.controlId = 0;
    this.taskTotalCount = 0;
    this.taskCompletedCount = 0;
  }
}

export class ControlAlertStats {
  controlId: number;

  kpiId: number;

  alertsFiredCount: number;

  constructor() {
    this.controlId = 0;
    this.kpiId = 0;
    this.alertsFiredCount = 0;
  }
}
