export enum PermissionTypes {
  ModuleOrg = 1,
  ModuleRequirements = 2,
  ModuleAssets = 3,
  ModuleRisks = 4,
  ModuleControls = 5,
  ModuleAnnualPlan = 6,
  ModuleTasks = 7,
  ModuleLibrary = 8,
  ModuleStandards = 9,
  ModuleAdmin = 10,
  ModulePartner = 11,
}
