import Joi from 'joi';
import Objective from 'models/objective/objective';
import { PDCAState } from 'models/pdca';
import Task from 'models/tasks/task';
import { TFunction } from 'i18next';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { isObjectEqual } from 'utils/object';
import { Process_Translation } from './process_Translation';
import { getPDCAStateText } from 'globalFunctions';
import Dashboard from 'models/dashboard';

export default class Process {
  processId: number;

  code: string;

  parentProcessId?: number;

  state: PDCAState;

  groupId?: string;

  ownerId?: string;

  commentTrailId: number;

  auditTrailId: number;

  objectives: Objective[];

  trans?: Process_Translation[];

  tasks: Task[];

  dashboard?: Dashboard;

  tagIds: number[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description?: string;

  constructor() {
    this.processId = -1;
    this.code = '';
    this.name = '';
    this.transIdx = -1;
    this.commentTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.auditTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.state = PDCAState.Plan;
    this.tasks = [];
    this.tagIds = [];
    this.objectives = [];
  }

  static getProcessStateText = (state: PDCAState, t: TFunction<string[]>): string => {
    return getPDCAStateText(state, t);
  };

  isEqual(item: Process) {
    if (item.processId !== this.processId) return false;
    if (item.parentProcessId !== this.parentProcessId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.code !== this.code) return false;
    if (item.state !== this.state) return false;
    if (item.groupId !== this.groupId) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (!isObjectEqual(item.trans, this.trans)) return false;

    return true;
  }

  clone(): Process {
    const output = new Process();
    output.processId = this.processId;
    output.parentProcessId = this.parentProcessId;
    output.code = this.code;
    output.name = this.name;
    output.description = this.description;
    output.state = this.state;
    output.groupId = this.groupId;
    output.ownerId = this.ownerId;
    output.commentTrailId = this.commentTrailId;
    output.auditTrailId = this.auditTrailId;
    output.transIdx = this.transIdx;
    output.trans = this.trans ? [...this.trans] : undefined;
    output.tasks = [...this.tasks];
    output.tagIds = [...this.tagIds];
    output.objectives = [...this.objectives];
    output.dashboard = this.dashboard?.clone();

    return output;
  }

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }
}
