import React, { useContext, createRef, useEffect, useState } from 'react';
import {
  TagPicker,
  ITag,
  IBasePickerSuggestionsProps,
  IPickerItemProps,
  Stack,
  CommandBarButton,
  ICommandBarStyles,
  ValidationState,
  IBasePicker,
  ISuggestionItemProps,
  IInputProps,
  IStyleFunctionOrObject,
  IBasePickerStyleProps,
  IBasePickerStyles,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalSuggestionsMaxRecords } from 'globalConstants';
import AppContext from 'App/AppContext';
import Tag from 'models/tag';
import { newIcon } from 'globalStyles';
import CreateTagCallOut from 'components/CallOuts/CreateTagCallOut';
import { sortOnTag } from 'utils/sorting';
import { KeyValueTag } from 'components/Tags/KeyValueTag';

interface IKeyValueTagPickerProps {
  selectedTags: Tag[];
  itemLimit?: number;
  disabled?: boolean;
  onRemove: (item: Tag) => void;
  onAdd: (item: Tag) => void;
  onCreate?: (item: Tag) => void;
  isLoading?: boolean;
  allowCreate?: boolean;
  styles?: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined;
  maxTagWidth?: number;
}

const KeyValueTagPicker = (props: IKeyValueTagPickerProps) => {
  const { t } = useTranslation(['translation', 'tag']);
  const appContext = useContext(AppContext);
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[] | undefined>(props.selectedTags);
  const [createNewItem, setCreateNewItem] = useState<boolean>(false);
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const tagPickerRef = createRef<IBasePicker<ITag>>();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    const tags = await appContext.globalDataCache.tags.getItems();
    setTags(tags);
  };

  useEffect(() => {
    //set selected tags in state so they can be modified
    setSelectedTags(props.selectedTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTags]);

  const buttonStyles: Partial<ICommandBarStyles> = {
    root: {
      height: 30,
    },
  };

  const getITag = (tag: Tag): ITag => {
    return {
      name: tag.value(),
      key: tag.tagId.toString(),
    };
  };

  const getITags = (tags: Tag[] | undefined): ITag[] | undefined => {
    return tags?.map((t) => getITag(t));
  };

  const onResolveSuggestions = async (filter: string, selectedItems: ITag[] | undefined): Promise<ITag[]> => {
    if (filter) setFilter(filter);

    if (filter && tags) {
      const filterLower = filter.toLowerCase();

      return tags
        .filter(
          (tag: Tag) =>
            !selectedTags?.some((t) => t.tagId === tag.tagId) &&
            (tag.tagName.toLowerCase().includes(filterLower) || tag.tagValue.toLowerCase().includes(filterLower)),
        )
        .sort((a, b) => {
          return sortOnTag(a, b);
        })
        .map((tag: Tag): ITag => {
          return getITag(tag);
        });
    }

    return [];
  };

  const onRenderCreateNewTag = () => {
    return (
      <CommandBarButton
        iconProps={newIcon}
        styles={buttonStyles}
        text={t('tag:Picker.Add.Button')}
        onClick={() => {
          tagPickerRef.current?.completeSuggestion(true);
        }}
      />
    );
  };

  const suggestionProps: IBasePickerSuggestionsProps = {
    showRemoveButtons: false,
    resultsMaximumNumber: globalSuggestionsMaxRecords,
    noResultsFoundText: t('translation:General.Suggestions.NoData'),
    onRenderNoResultFound: props.onCreate && props.allowCreate ? onRenderCreateNewTag : undefined,
  };

  const removeSelectedItem = (tag: Tag) => {
    //remove from selected items
    const items = selectedTags?.filter((t) => t.tagId !== tag.tagId);
    setSelectedTags(items);
    if (props.onRemove) {
      props.onRemove(tag);
    }
  };

  const onRenderItem = (pickerProps: IPickerItemProps<ITag>): JSX.Element => {
    const tagItem = pickerProps.item;
    if (!selectedTags) return <span />;

    const tag = selectedTags.find((t) => t.tagId.toString() === tagItem.key);
    if (!tag) return <span />;

    return <KeyValueTag key={tag.tagId} tag={tag} maxWidth={props.maxTagWidth} onRemove={removeSelectedItem} />;
  };

  const onRenderSuggestionsItem = (pickerProps: ITag, itemProps: ISuggestionItemProps<ITag>): JSX.Element => {
    const tagItem = pickerProps;
    if (!tags) return <span />;

    const tag = tags.find((t) => t.tagId.toString() === tagItem.key);
    if (!tag) return <span />;

    return <KeyValueTag tag={tag} />;
  };

  const onSelectItem = (item?: ITag | undefined) => {
    if (item) {
      let id: string;
      if (!item.key) {
        setCreateNewItem(true);

        return null;
      } else {
        id = item.key as string;
      }

      const tag = tags?.find((t) => t.tagId.toString() === id);
      if (tag) {
        const newSelectedTags = selectedTags?.slice(0) || [];
        newSelectedTags.push(tag);
        setSelectedTags(newSelectedTags);
        if (props.onAdd) {
          props.onAdd(tag);
        }
      }

      return item;
    }

    return null;
  };

  const onCreateItem = (tag: Tag) => {
    //add to selected items
    const newSelectedTags = selectedTags?.slice(0) || [];
    newSelectedTags.push(tag);
    setSelectedTags(newSelectedTags);
    if (props.onCreate) {
      props.onCreate(tag);
    }
  };

  const targetId = 'uniqueTargetId' + Math.random().toString(36).substring(7);

  const inputProps: IInputProps = {
    placeholder: props.allowCreate ? t('tag:Picker.Placeholder') : t('tag:Picker.PlaceholderNoCreate'),
  };

  return (
    <Stack id={targetId}>
      <TagPicker
        componentRef={tagPickerRef}
        itemLimit={props.itemLimit}
        onResolveSuggestions={onResolveSuggestions}
        pickerSuggestionsProps={suggestionProps}
        selectedItems={getITags(selectedTags)}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        disabled={props.disabled}
        onRenderItem={onRenderItem}
        createGenericItem={(input: string) => {
          return { key: '', name: input } as ITag;
        }}
        onItemSelected={onSelectItem}
        onValidateInput={(input: string) => {
          return input ? ValidationState.valid : ValidationState.invalid;
        }}
        inputProps={inputProps}
        styles={props.styles}
      />
      {createNewItem &&
        tags && ( //to destroy the state of the call-out
          <CreateTagCallOut
            tagCache={tags}
            targetId={targetId}
            onClose={() => setCreateNewItem(false)}
            onCreate={onCreateItem}
            isVisible={createNewItem}
            defaultGroup={filter}
          />
        )}
    </Stack>
  );
};

export default KeyValueTagPicker;
