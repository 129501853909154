import {
  mapFromDashboard,
  mapFromISOControls,
  mapFromObjectives,
  mapFromProcesses,
  mapToDashboard,
} from 'mappings/dataMapping';
import Control, { ControlAlertStats, ControlTaskStats } from 'models/control';
import ControlScene from 'models/controlScene';
import Control_Translation from 'models/control_Translation';
import ControlDTO, { ControlAlertStatsDTO, ControlTaskStatsDTO } from 'models/dto/controlDTO';
import ControlSceneDTO from 'models/dto/controlSceneDTO';
import Control_TranslationDTO from 'models/dto/control_TranslationDTO';
import { mapFromLanguage, mapToLanguage, mapFromNorms, mapFromThemes, mapFromEntity } from './dataMapping';
import { mapFromTasksArray } from './dataMapping';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import ControlContextDTO from 'models/dto/controlContextDTO';
import ControlContext from 'models/controlContext';
import IdListDTO from 'models/dto/IdListDTO';
import { mapFromKPIs } from './kpiMapping';

export function mapFromControls(controlDTOs: ControlDTO[], globalDataCache: GlobalDataCache): Control[] {
  let controlList: Control[] = [];

  for (let controlDTO of controlDTOs) {
    const newControl = mapFromControl(controlDTO, globalDataCache);
    controlList.push(newControl as Control);
  }

  return controlList;
}

export function mapFromControl_Translation(
  control_TranslationDTOs: Control_TranslationDTO[] | undefined,
): Control_Translation[] | undefined {
  if (!control_TranslationDTOs) {
    return undefined;
  }

  let control_TranslationList: Control_Translation[] = [];

  for (let control_TranslationDTO of control_TranslationDTOs) {
    const newControl_Translation = new Control_Translation();
    newControl_Translation.controlId = control_TranslationDTO.controlId;
    newControl_Translation.languageId = control_TranslationDTO.languageId;
    newControl_Translation.name = control_TranslationDTO.name;
    newControl_Translation.description = control_TranslationDTO.description;
    newControl_Translation.implementation = control_TranslationDTO.implementation;
    newControl_Translation.background = control_TranslationDTO.background;
    newControl_Translation.outOfScopeReason = control_TranslationDTO.outOfScopeReason;
    newControl_Translation.lang = mapFromLanguage(control_TranslationDTO.lang);
    control_TranslationList.push(newControl_Translation);
  }

  return control_TranslationList;
}

export function mapToControls(controls: Control[]): ControlDTO[] {
  let controlList: ControlDTO[] = [];

  for (let control of controls) {
    const newControlDto = mapToControl(control);
    controlList.push(newControlDto as ControlDTO);
  }

  return controlList;
}

export function mapToControl(control: Control): ControlDTO {
  const newControlDTO = new ControlDTO();

  newControlDTO.controlId = control.controlId;
  newControlDTO.parentControlId = control.parentControlId;
  newControlDTO.code = control.code;
  newControlDTO.groupId = control.groupId;
  newControlDTO.ownerId = control.ownerId;
  newControlDTO.commentTrailId = control.commentTrailId;
  newControlDTO.auditTrailId = control.auditTrailId;
  newControlDTO.state = control.state;
  newControlDTO.applicabilityReason = new IdListDTO(control.applicabilityReason.map((a) => a.toString()));
  newControlDTO.implementationState = control.implementationState;
  newControlDTO.dashboard = control.dashboard ? mapToDashboard(control.dashboard) : undefined;
  newControlDTO.trans = mapToControl_Translation(control.trans);

  if (newControlDTO.trans && control.transIdx >= 0) {
    newControlDTO.trans[control.transIdx].name = control.name;
    newControlDTO.trans[control.transIdx].description = control.description;
    newControlDTO.trans[control.transIdx].background = control.background;
    newControlDTO.trans[control.transIdx].implementation = control.implementation;
    newControlDTO.trans[control.transIdx].outOfScopeReason = control.outOfScopeReason;
  }

  return newControlDTO;
}

export function mapFromControl(
  controlDTO: ControlDTO | undefined,
  globalDataCache: GlobalDataCache,
): Control | undefined {
  if (!controlDTO) {
    return undefined;
  }

  const newControl = new Control();

  newControl.controlId = controlDTO.controlId;
  newControl.parentControlId = controlDTO.parentControlId;
  newControl.controlType = controlDTO.controlType;
  newControl.code = controlDTO.code;
  newControl.state = controlDTO.state;
  newControl.applicabilityReason = controlDTO.applicabilityReason.idList?.map((a) => Number.parseInt(a)) ?? [];
  newControl.implementationState = controlDTO.implementationState;
  newControl.dashboard = controlDTO.dashboard ? mapFromDashboard(controlDTO.dashboard, globalDataCache) : undefined;
  newControl.groupId = controlDTO.groupId;
  newControl.ownerId = controlDTO.ownerId;
  newControl.commentTrailId = controlDTO.commentTrailId;
  newControl.auditTrailId = controlDTO.auditTrailId;
  newControl.customNormIds = controlDTO.customNormIds?.idList?.map<number>((i) => Number(i));
  newControl.isoControlIds = controlDTO.isoControlIds?.idList?.map<number>((i) => Number(i));
  newControl.isoNormIds = controlDTO.isoNormIds?.idList?.map<number>((i) => Number(i));
  newControl.trans = mapFromControl_Translation(controlDTO.trans);
  newControl.tasks = mapFromTasksArray(controlDTO.tasks, globalDataCache);
  newControl.tagIds = controlDTO.tagIds?.idList?.map<number>((i) => Number(i)) ?? [];
  newControl.monitoringParent = controlDTO.monitoringParent ? mapFromEntity(controlDTO.monitoringParent) : undefined;
  newControl.isoControls = controlDTO.isoControls
    ? mapFromISOControls(controlDTO.isoControls, globalDataCache)
    : undefined;

  if (newControl.trans && newControl.trans.length > 0) {
    newControl.transIdx = 0;
    newControl.name = newControl.trans[0].name;
    newControl.description = newControl.trans[0].description;
    newControl.background = newControl.trans[0].background;
    newControl.implementation = newControl.trans[0].implementation;
    newControl.outOfScopeReason = newControl.trans[0].outOfScopeReason;
  }

  return newControl;
}

export function mapToControl_Translation(
  control_Translations: Control_Translation[] | undefined,
): Control_TranslationDTO[] | undefined {
  if (!control_Translations) {
    return undefined;
  }

  let control_TranslationList: Control_TranslationDTO[] = [];

  for (let control_Translation of control_Translations) {
    const newIControl_TranslationDTO = new Control_TranslationDTO();
    newIControl_TranslationDTO.controlId = control_Translation.controlId;
    newIControl_TranslationDTO.languageId = control_Translation.languageId;
    newIControl_TranslationDTO.name = control_Translation.name;
    newIControl_TranslationDTO.description = control_Translation.description;
    newIControl_TranslationDTO.background = control_Translation.background;
    newIControl_TranslationDTO.implementation = control_Translation.implementation;
    newIControl_TranslationDTO.outOfScopeReason = control_Translation.outOfScopeReason;
    newIControl_TranslationDTO.lang = mapToLanguage(control_Translation.lang);
    control_TranslationList.push(newIControl_TranslationDTO);
  }

  return control_TranslationList;
}

export function mapFromControlContext(controlContextDTO: ControlContextDTO, globalDataCache: GlobalDataCache) {
  let controlContext = new ControlContext();
  controlContext.children = mapFromControls(controlContextDTO.children, globalDataCache);
  controlContext.parent = mapFromControl(controlContextDTO.parent, globalDataCache);
  controlContext.controls = mapFromControls(controlContextDTO.controls, globalDataCache);
  controlContext.themes = mapFromThemes(controlContextDTO.themes, globalDataCache);
  controlContext.processes = mapFromProcesses(controlContextDTO.processes, globalDataCache);
  controlContext.objectives = mapFromObjectives(controlContextDTO.objectives, globalDataCache);
  controlContext.kpis = mapFromKPIs(controlContextDTO.kpis, globalDataCache);

  return controlContext;
}

export function mapFromControlScene(controlSceneDTO: ControlSceneDTO, globalDataCache: GlobalDataCache): ControlScene {
  let controlScene = new ControlScene();

  controlScene.controls = mapFromControls(controlSceneDTO.controls, globalDataCache);
  controlScene.norms = mapFromNorms(controlSceneDTO.norms);

  return controlScene;
}

export function mapFromControlTaskStats(controlTaskStatsDTOs: ControlTaskStatsDTO[]): ControlTaskStats[] {
  const controlTaskStats: ControlTaskStats[] = [];

  for (const controlTaskStatsDTO of controlTaskStatsDTOs) {
    const controlTaskStat = new ControlTaskStats();
    controlTaskStat.controlId = controlTaskStatsDTO.controlId;
    controlTaskStat.taskTotalCount = controlTaskStatsDTO.taskTotalCount;
    controlTaskStat.taskCompletedCount = controlTaskStatsDTO.taskCompletedCount;

    controlTaskStats.push(controlTaskStat);
  }

  return controlTaskStats;
}

export function mapFromControlAlertStats(controlAlertStatsDTOs: ControlAlertStatsDTO[]): ControlAlertStats[] {
  const controlAlertStats: ControlAlertStats[] = [];

  for (const controlAlertStatsDTO of controlAlertStatsDTOs) {
    const controlAlertStat = new ControlAlertStats();
    controlAlertStat.controlId = controlAlertStatsDTO.controlId;
    controlAlertStat.kpiId = controlAlertStatsDTO.kpiId;
    controlAlertStat.alertsFiredCount = controlAlertStatsDTO.alertsFiredCount;

    controlAlertStats.push(controlAlertStat);
  }

  return controlAlertStats;
}
