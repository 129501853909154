import { Callout, Stack, TextField, PrimaryButton, DefaultButton, Text, DirectionalHint, Separator } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { isEmpty } from 'utils/string';

interface IEditTextFieldCallOut {
  targetId: string;
  onClose: () => void;
  onUpdate: (newText: string) => void;
  isVisible: boolean;
  value?: string;
  isBusy?: boolean;
  maxLenght?: number;
  minLength?: number;
  height?: number;
  width?: number;
  maxHeight?: number;
  required?: boolean;
  readOnly?: boolean;
}

export const EditTextFieldCallOut = (props: IEditTextFieldCallOut) => {
  const [newValue, setNewValue] = useState<string>('');
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);

  useEffect(() => {
    setNewValue(props.value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.isVisible]);

  return (
    <Callout
      hidden={!props.isVisible}
      styles={{
        root: {
          padding: '10px 12px',
        },
      }}
      setInitialFocus
      target={`#${props.targetId}`}
      directionalHint={DirectionalHint.bottomLeftEdge}
      onDismiss={props.onClose}
      beakWidth={16}
    >
      <Stack tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <TextField
            required={props.required}
            readOnly={props.readOnly}
            autoFocus={!appContext.isMobileView}
            value={newValue}
            style={{
              resize: 'vertical',
              maxHeight: props.maxHeight || 400,
              height: props.height || undefined,
              width: props.width || 300,
            }}
            rows={5}
            multiline
            maxLength={props.maxLenght}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
              if (newValue === undefined) setNewValue('');
              else setNewValue(newValue);
            }}
          />
          {(props.minLength || props.maxLenght) && (
            <Text variant="small">{t('translation:General.Text.Length', { len: newValue.length })}</Text>
          )}
        </Stack.Item>
        <Separator/>
        <Stack.Item>
          <Stack horizontal tokens={globalStackTokensGapSmall} horizontalAlign="end">
            <Stack.Item>
              <PrimaryButton
                onClick={() => {
                  props.onUpdate(newValue);
                  props.onClose();
                }}
                disabled={
                  props.isBusy ||
                  props.readOnly ||
                  (props.required === true && isEmpty(newValue)) ||
                  (props.minLength !== undefined && props.minLength > 0 && newValue.length < props.minLength)
                }
              >
                {t('translation:General.Button.Save')}
              </PrimaryButton>
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                onClick={() => {
                  props.onClose();
                }}
              >
                {t('translation:General.Button.Cancel')}
              </DefaultButton>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Callout>
  );
};

export default EditTextFieldCallOut;
