import { Stack, IconButton, TooltipHost } from '@fluentui/react';
import Tag from 'models/tag';
import { Text, ITextStyles } from '@fluentui/react';
import { cancelIcon, globalStackTokensGapExtraSmall } from 'globalStyles';

interface IKeyValueTagProps {
  tag: Tag;
  onRemove?: (item: Tag) => void;
  onClick?: (item: Tag) => void;
  compact?: boolean;
  width?: number;
  maxWidth?: number; //set this to set a maximum width for example when the parent container has a maximum size
  showBorder?: boolean;
}

export const KeyValueTag = (props: IKeyValueTagProps) => {
  const getTagStyles = (tag: Tag) => {
    return {
      root: {
        background: tag.tagColor,
        borderRadius: '10px',
        border: props.showBorder ? '1px solid blue' : undefined,
        margin: '2px',
        height: props.compact ? '18px' : '22px',
      },
    };
  };

  const getTagButtonStyles = (tag: Tag) => {
    return {
      root: {
        borderRadius: '8px',
        height: props.compact ? '18px' : '22px',
        width: props.compact ? '18px' : '22px',
      },
    };
  };

  const getStackItemStyles = (tag: Tag) => {
    if (props.onClick) {
      return {
        root: {
          selectors: {
            '&:hover': {
              cursor: 'pointer',
            },
          },
          width: props.width,
          maxWidth: props.maxWidth,
        },
      };
    } else {
      return {
        root: {
          width: props.width,
          maxWidth: props.maxWidth,
        },
      };
    }
  };

  const tagTextStyles: ITextStyles = {
    root: {
      marginLeft: '5px',
      marginRight: '5px',
    },
  };

  return (
    <Stack
      key={'tag' + props.tag.tagId.toString()}
      horizontal
      styles={getTagStyles(props.tag)}
      verticalAlign="start"
      horizontalAlign="space-between"
    >
      <Stack.Item
        grow
        style={{ overflow: 'hidden' }} //this ensures that the text is cut off nicely, otherwise it will run out of the stack.item
        styles={getStackItemStyles(props.tag)}
        onClick={() => {
          if (props.onClick) {
            props.onClick(props.tag);
          }
        }}
      >
        <TooltipHost content={props.tag.tagInfo}>
          <Text
            block
            nowrap
            styles={tagTextStyles}
            key={props.tag.tagId.toString()}
            variant={props.compact ? 'small' : 'medium'}
          >
            {props.tag.value()}
          </Text>
        </TooltipHost>
      </Stack.Item>
      {props.onRemove && (
        <Stack.Item>
          <IconButton
            onClick={() => {
              if (props.onRemove) {
                props.onRemove(props.tag);
              }
            }}
            styles={getTagButtonStyles(props.tag)}
            iconProps={cancelIcon}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};

interface IKeyValueTagsProps {
  tags: Tag[];
  onClick?: (item: Tag) => void;
  compact?: boolean;
  tagWidth?: number; //set this to stretch all tasks to exactly this width
  tagMaxWidth?: number;
}

export const KeyValueTags = (props: IKeyValueTagsProps) => {
  return (
    <Stack horizontal wrap tokens={globalStackTokensGapExtraSmall} styles={{ root: { marginRight: 5 } }}>
      {props.tags?.map((_tag, index) => {
        return (
          <Stack.Item key={'tag' + index.toString()} styles={{ root: { margin: 0 } }}>
            <KeyValueTag
              compact={props.compact}
              width={props.tagWidth}
              maxWidth={props.tagMaxWidth}
              tag={_tag}
              onClick={props.onClick}
            />
          </Stack.Item>
        );
      })}
    </Stack>
  );
};
