import Joi from 'joi';
import KPI from '../kpi/kpi';
import { TaskTypeFormElement_Translation } from './taskTypeFormElement_Translation';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import ResourceLink from 'models/resourceLink';
import { newGuid } from 'utils/guid';
import { ResourceListType } from 'models/resourceList';
import Task from './task';

export enum TaskTypeFieldType {
  KPI = 0,
  AttachmentPage = 1,
  AttachmentDocument = 2,
  AttachmentListItem = 3,
  AttachmentURL = 4,
  ContextProcess = 5,
  ContextObjective = 6,
  ContextAsset = 7,
  ContextRisk = 8,
  ContextControl = 9,
  ContextRequirement = 10,
}

export enum FormFieldType {
  KPI = 0,
  Attachment = 1,
  Context = 2,
}

export default class TaskTypeFormElement {
  taskTypeId: number;

  fieldId: number;

  fieldType: TaskTypeFieldType;

  fieldMode?: number;

  kpiId?: number;

  sortOrder: number;

  listId?: number;

  kpi?: KPI;

  trans: TaskTypeFormElement_Translation[];

  //these are flattened from the trans object
  transIdx: number;

  name: string;

  description?: string;

  expectedResult?: string;

  instruction?: string;

  //these are internal
  key: string; //not part of API, used to uniquely identify this

  errorMessage?: string;

  constructor() {
    this.taskTypeId = 0;
    this.fieldId = 0;
    this.fieldType = 0;
    this.sortOrder = 0;
    this.taskTypeId = 0;
    this.trans = [];
    this.name = '';
    this.transIdx = -1;
    this.key = newGuid();
  }

  getAttachments(source: ResourceLink[]): ResourceLink[] {
    const listType = this.getListTypeOfAttachmentFieldType();

    return source.filter((s) => s.list.listType === listType);
  }

  getListTypeOfAttachmentFieldType(): ResourceListType | undefined {
    switch (this.fieldType) {
      case TaskTypeFieldType.AttachmentDocument:
        return ResourceListType.DocumentLibrary;
      case TaskTypeFieldType.AttachmentListItem:
        return ResourceListType.CustomList;
      case TaskTypeFieldType.AttachmentPage:
        return ResourceListType.SitePageLibrary;
      case TaskTypeFieldType.AttachmentURL:
        return ResourceListType.WebURL;
    }

    return undefined;
  }

  getMaxCount = (): number => {
    if (!this.fieldMode) return 0;

    return this.fieldMode < 0 ? Math.abs(this.fieldMode) : 10;
  };

  getMinCount = (): number => {
    if (!this.fieldMode) return 0;

    return this.fieldMode > 0 ? this.fieldMode : 0;
  };

  getCurrentContextCount = (task: Task): number => {
    switch (this.fieldType) {
      case TaskTypeFieldType.ContextAsset:
        return task.assets.length;
      case TaskTypeFieldType.ContextControl:
        return task.controls.length;
      case TaskTypeFieldType.ContextObjective:
        return task.objectives.length;
      case TaskTypeFieldType.ContextProcess:
        return task.processes.length;
      case TaskTypeFieldType.ContextRequirement:
        return task.themes.length;
      case TaskTypeFieldType.ContextRisk:
        return task.risks.length;
      default:
        return 0;
    }
  };

  isEqual(taskType: TaskTypeFormElement): boolean {
    if (taskType.name !== this.name) return false;
    if (taskType.description !== this.description) return false;
    if (taskType.instruction !== this.instruction) return false;
    if (taskType.expectedResult !== this.expectedResult) return false;
    if (taskType.fieldType !== this.fieldType) return false;
    if (taskType.fieldMode !== this.fieldMode) return false;
    if (taskType.fieldId !== this.fieldId) return false;
    if (taskType.taskTypeId !== this.taskTypeId) return false;
    if (taskType.kpiId !== this.kpiId) return false;
    if (taskType.sortOrder !== this.sortOrder) return false;
    if (taskType.listId !== this.listId) return false;

    return true;
  }

  clone(): TaskTypeFormElement {
    const output = new TaskTypeFormElement();
    output.key = this.key;
    output.taskTypeId = this.taskTypeId;
    output.fieldId = this.fieldId;
    output.fieldType = this.fieldType;
    output.fieldMode = this.fieldMode;
    output.kpi = this.kpi?.clone();
    output.kpiId = this.kpiId;
    output.sortOrder = this.sortOrder;
    output.listId = this.listId;
    output.description = this.description;
    output.name = this.name;
    output.instruction = this.instruction;
    output.expectedResult = this.expectedResult;
    output.transIdx = this.transIdx;
    output.trans = [...this.trans];
    output.errorMessage = this.errorMessage;

    return output;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate(
      {
        name: this.name,
      },
      { abortEarly: false },
    );
  }
}
