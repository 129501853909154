import { mapFromObjectives } from './objectiveMapping';
import { mapFromProcesses } from './processMapping';
import Theme, { ThemeTaskStats } from 'models/theme';
import ThemeScene from 'models/themeScene';
import Theme_Translation from 'models/theme_Translation';
import ThemeDTO, { ThemeTaskStatsDTO } from 'models/dto/themeDTO';
import ThemeSceneDTO from 'models/dto/themeSceneDTO';
import Theme_TranslationDTO from 'models/dto/theme_TranslationDTO';
import {
  mapFromLanguage,
  mapToLanguage,
  mapFromNorms,
  mapFromControls,
  mapFromRisksArray,
  mapFromEntity,
  mapFromISOControls,
} from './dataMapping';
import { mapFromTasksArray } from './dataMapping';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import ThemeContext from 'models/themeContext';
import ThemeContextDTO from 'models/dto/themeContextDTO';

export function mapFromThemes(themeDTOs: ThemeDTO[], globalDataCache: GlobalDataCache): Theme[] {
  let themeList: Theme[] = [];

  for (let themeDTO of themeDTOs) {
    const newTheme = mapFromTheme(themeDTO, globalDataCache);
    themeList.push(newTheme as Theme);
  }

  return themeList;
}

export function mapFromTheme_Translation(
  theme_TranslationDTOs: Theme_TranslationDTO[] | undefined,
): Theme_Translation[] | undefined {
  if (!theme_TranslationDTOs) {
    return undefined;
  }

  let theme_TranslationList: Theme_Translation[] = [];

  for (let theme_TranslationDTO of theme_TranslationDTOs) {
    const newTheme_Translation = new Theme_Translation();
    newTheme_Translation.themeId = theme_TranslationDTO.themeId;
    newTheme_Translation.languageId = theme_TranslationDTO.languageId;
    newTheme_Translation.name = theme_TranslationDTO.name;
    newTheme_Translation.description = theme_TranslationDTO.description;
    newTheme_Translation.background = theme_TranslationDTO.background;
    newTheme_Translation.implementation = theme_TranslationDTO.implementation;
    newTheme_Translation.outOfScopeReason = theme_TranslationDTO.outOfScopeReason;
    newTheme_Translation.lang = mapFromLanguage(theme_TranslationDTO.lang);
    theme_TranslationList.push(newTheme_Translation);
  }

  return theme_TranslationList;
}

export function mapToThemes(themes: Theme[]): ThemeDTO[] {
  let themeList: ThemeDTO[] = [];

  for (let theme of themes) {
    const newThemeDto = mapToTheme(theme);
    themeList.push(newThemeDto as ThemeDTO);
  }

  return themeList;
}

export function mapToTheme(theme: Theme): ThemeDTO {
  const newThemeDTO = new ThemeDTO();

  newThemeDTO.themeId = theme.themeId;
  newThemeDTO.parentThemeId = theme.parentThemeId;
  newThemeDTO.code = theme.code;
  newThemeDTO.groupId = theme.groupId;
  newThemeDTO.ownerId = theme.ownerId;
  newThemeDTO.commentTrailId = theme.commentTrailId;
  newThemeDTO.auditTrailId = theme.auditTrailId;
  newThemeDTO.state = theme.state;
  newThemeDTO.trans = mapToTheme_Translation(theme.trans);

  if (newThemeDTO.trans && theme.transIdx >= 0) {
    newThemeDTO.trans[theme.transIdx].name = theme.name;
    newThemeDTO.trans[theme.transIdx].description = theme.description;
    newThemeDTO.trans[theme.transIdx].background = theme.background;
    newThemeDTO.trans[theme.transIdx].implementation = theme.implementation;
  }

  return newThemeDTO;
}

export function mapFromTheme(themeDTO: ThemeDTO | undefined, globalDataCache: GlobalDataCache): Theme | undefined {
  if (!themeDTO) {
    return undefined;
  }

  const newTheme = new Theme();

  newTheme.themeId = themeDTO.themeId;
  newTheme.parentThemeId = themeDTO.parentThemeId;
  newTheme.code = themeDTO.code;
  newTheme.state = themeDTO.state;
  newTheme.groupId = themeDTO.groupId;
  newTheme.ownerId = themeDTO.ownerId;
  newTheme.commentTrailId = themeDTO.commentTrailId;
  newTheme.auditTrailId = themeDTO.auditTrailId;
  newTheme.customNormIds = themeDTO.customNormIds?.idList?.map<number>((i) => Number(i));
  newTheme.isoControlIds = themeDTO.isoControlIds?.idList?.map<number>((i) => Number(i));
  newTheme.isoNormIds = themeDTO.isoNormIds?.idList?.map<number>((i) => Number(i));
  newTheme.trans = mapFromTheme_Translation(themeDTO.trans);
  newTheme.tasks = mapFromTasksArray(themeDTO.tasks, globalDataCache);
  newTheme.tagIds = themeDTO.tagIds?.idList?.map<number>((i) => Number(i)) ?? [];
  newTheme.monitoringParent = themeDTO.monitoringParent ? mapFromEntity(themeDTO.monitoringParent) : undefined;
  newTheme.isoControls = themeDTO.isoControls ? mapFromISOControls(themeDTO.isoControls, globalDataCache) : undefined;

  if (newTheme.trans && newTheme.trans.length > 0) {
    newTheme.transIdx = 0;
    newTheme.name = newTheme.trans[0].name;
    newTheme.description = newTheme.trans[0].description;
    newTheme.background = newTheme.trans[0].background;
    newTheme.implementation = newTheme.trans[0].implementation;
  }

  return newTheme;
}

export function mapToTheme_Translation(
  theme_Translations: Theme_Translation[] | undefined,
): Theme_TranslationDTO[] | undefined {
  if (!theme_Translations) {
    return undefined;
  }

  let theme_TranslationList: Theme_TranslationDTO[] = [];

  for (let theme_Translation of theme_Translations) {
    const newITheme_TranslationDTO = new Theme_TranslationDTO();
    newITheme_TranslationDTO.themeId = theme_Translation.themeId;
    newITheme_TranslationDTO.languageId = theme_Translation.languageId;
    newITheme_TranslationDTO.name = theme_Translation.name;
    newITheme_TranslationDTO.description = theme_Translation.description;
    newITheme_TranslationDTO.background = theme_Translation.background;
    newITheme_TranslationDTO.implementation = theme_Translation.implementation;
    newITheme_TranslationDTO.outOfScopeReason = theme_Translation.outOfScopeReason;
    newITheme_TranslationDTO.lang = mapToLanguage(theme_Translation.lang);
    theme_TranslationList.push(newITheme_TranslationDTO);
  }

  return theme_TranslationList;
}

export function mapFromThemeScene(themeSceneDTO: ThemeSceneDTO, globalDataCache: GlobalDataCache): ThemeScene {
  let themeScene = new ThemeScene();

  themeScene.themes = mapFromThemes(themeSceneDTO.themes, globalDataCache);
  themeScene.norms = mapFromNorms(themeSceneDTO.norms);

  return themeScene;
}

export function mapFromThemeContext(themeContextDTO: ThemeContextDTO, globalDataCache: GlobalDataCache) {
  let themeContext = new ThemeContext();
  themeContext.children = mapFromThemes(themeContextDTO.children, globalDataCache);
  themeContext.parent = mapFromTheme(themeContextDTO.parent, globalDataCache);
  themeContext.themes = mapFromThemes(themeContextDTO.themes, globalDataCache);
  themeContext.controls = mapFromControls(themeContextDTO.controls, globalDataCache);
  themeContext.risks = mapFromRisksArray(themeContextDTO.risks, globalDataCache);
  themeContext.processes = mapFromProcesses(themeContextDTO.processes, globalDataCache);
  themeContext.objectives = mapFromObjectives(themeContextDTO.objectives, globalDataCache);

  return themeContext;
}

export function mapFromThemeTaskStats(themeTaskStatsDTOs: ThemeTaskStatsDTO[]): ThemeTaskStats[] {
  const themeTaskStats: ThemeTaskStats[] = [];

  for (const themeTaskStatsDTO of themeTaskStatsDTOs) {
    const themeTaskStat = new ThemeTaskStats();
    themeTaskStat.themeId = themeTaskStatsDTO.themeId;
    themeTaskStat.taskTotalCount = themeTaskStatsDTO.taskTotalCount;
    themeTaskStat.taskCompletedCount = themeTaskStatsDTO.taskCompletedCount;

    themeTaskStats.push(themeTaskStat);
  }

  return themeTaskStats;
}
