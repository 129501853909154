import Task from 'models/tasks/task';
import { SystemTaskTypes } from 'models/tasks/taskType';
import { apiGetActiveTaskTemplates } from 'services/Api/taskService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, IEntityCache } from './entityCache';

export class TemplateCache extends EntityCache<Task> implements IEntityCache<Task, TemplateCache> {
  private isLoading: boolean = false;

  private templatePromise: Promise<Task[]> | undefined;

  itemSet: Map<number, Task> = new Map<number, Task>();

  has(id: number | undefined): boolean {
    let task = this.items?.find((i) => i.taskId === id);
    if (!task) return false;

    return true;
  }

  get(id: number | undefined): Task {
    let task = this.items?.find((i) => i.taskId === id);
    if (task) {
      return task;
    }
    if (id) {
      this.addMiss();
      task = new Task();
    } else {
      task = new Task();
    }

    return task;
  }

  add(item: Task) {
    this.items.push(item);
    this.itemSet.set(item.taskId, item);
  }

  update(item: Task) {
    const idx = this.items.findIndex((i) => i.taskId === item.taskId);
    if (idx >= 0) {
      this.items[idx] = item;
      this.itemSet.set(item.taskId, item);
    }
  }

  remove(item: Task) {
    const idx = this.items.findIndex((i) => i.taskId === item.taskId);
    if (idx >= 0) {
      this.items.splice(idx, 1);
      this.itemSet.delete(item.taskId);
    }
  }

  getItemsForId(ids: number[] | undefined): Task[] {
    if (!ids) return [];

    this.items.forEach((i) => {
      this.itemSet.set(i.taskId, i);
    });

    const result: Task[] = [];
    ids.forEach((i) => {
      const tag = this.itemSet.get(i);
      if (tag) {
        result.push(tag);
      } else {
        result.push(new Task());
      }
    });

    return result;
  }

  getItemsForSystemTaskType(systemTaskType: SystemTaskTypes): Task[] {
    const result: Task[] = this.items.filter((i) => i.systemTaskType === systemTaskType);

    return result;
  }

  async getItems(refresh: boolean = false): Promise<Task[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && this.cacheRef && !this.isLoading) {
        try {
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.templatePromise = apiGetActiveTaskTemplates(accessToken, this.cacheRef);
          this.isLoading = true;
          this.items = await this.templatePromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.isLoading = false;
          this.templatePromise = undefined;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.templatePromise) {
      return await this.templatePromise;
    } else {
      return this.items;
    }
  }

  clone(): TemplateCache {
    const templateCache: TemplateCache = new TemplateCache();
    templateCache.items = this.items?.map((u) => u.clone());

    return templateCache;
  }
}
