import { Client } from '@microsoft/microsoft-graph-client';
import AppError from 'utils/appError';
import { Drive, DriveItem, DriveItemVersion } from 'microsoft-graph';
import axios from 'axios';
import { ICreateUploadSessionResponse, IDriveItem, IList } from './SharepointInterfaces';
import { globalMaxSharePointListSize } from 'globalConstants';
import logger from 'services/Logging/logService';

export const fileUploadMultipleOf = 4194304; //4 MB
export let cancelAsyncProcesState = false;

//
// Global
//

export function cancelGraphAsyncProces(state: boolean) {
  cancelAsyncProcesState = state;
}

//
// Sharepoint Drives
//
export const getValidFilename = (filename: string): string => {
  const invalidChars = '"*:<>?/\\|\'';
  let newFilename = filename;

  for (let char of invalidChars) {
    newFilename = filename.replaceAll(char, '_');
  }

  return newFilename.trim();
};

export const graphGetDrivesForSite = async (client: Client, siteId: string): Promise<Drive[]> => {
  try {
    const request = `/sites/${siteId}/drives`;
    logger.debug('graphGetDriveRoot', request);
    const res = await client.api(request).select('id,name,webUrl,driveType').get();

    if (res && res.value) {
      return res.value;
    } else {
      return [];
    }
  } catch (err) {
    logger.debug('graphGetDrivesForSite', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphCreateDrive = async (client: Client, siteId: string, name: string): Promise<DriveItem> => {
  try {
    const newList: IList = {
      displayName: name,
      list: {
        template: 'documentLibrary',
      },
    };

    logger.debug('graphCreateDrive', newList);
    const createdList: IList = await client.api(`sites/${siteId}/lists`).post(newList);
    const drive: Drive = await client.api(`sites/${siteId}/lists/${createdList.id}/drive`).get();

    if (drive) {
      return drive;
    } else {
      throw new AppError('Could not get drive root from newly created document library');
    }
  } catch (err) {
    logger.debug('graphCreateDrive', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetDriveRoot = async (client: Client, driveId: string): Promise<DriveItem> => {
  try {
    const request = `/drives/${driveId}/root`;
    logger.debug('graphGetDriveRoot', request);
    const res = await client.api(request).get();

    return res;
  } catch (err) {
    logger.debug('graphGetDriveRoot', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetChildrenForDrive = async (client: Client, driveId: string): Promise<DriveItem[]> => {
  try {
    const request = `/drives/${driveId}/root/children`;
    logger.debug('graphGetChildrenForDrive', request);
    let res = await client.api(request).get();
    let items: IDriveItem[] = [];

    if (res && res.value) {
      let nextLink = res['@odata.nextLink'];
      items = [...res.value];

      while (nextLink) {
        logger.debug('graphGetChildrenForDrive', nextLink);
        res = await client.api(nextLink).get();
        if (!res) break;
        if (res.value) {
          items.push(...res.value);
        }
        nextLink = res['@odata.nextLink'];
        if (items.length > globalMaxSharePointListSize) break;
      }
    }

    return items;
  } catch (err) {
    logger.debug('graphGetChildrenForDriveItem', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetChildrenForDriveItem = async (
  client: Client,
  driveId: string,
  itemId: string,
): Promise<DriveItem[]> => {
  try {
    const request = `/drives/${driveId}/items/${itemId}/children`;
    logger.debug('graphGetChildrenForDriveItem', request);
    let res = await client.api(request).get();
    let items: IDriveItem[] = [];

    if (res && res.value) {
      let nextLink = res['@odata.nextLink'];
      items = [...res.value];

      while (nextLink) {
        logger.debug('graphGetChildrenForDriveItem', nextLink);
        res = await client.api(nextLink).get();
        if (!res) break;
        if (res.value) {
          items.push(...res.value);
        }
        nextLink = res['@odata.nextLink'];
        if (items.length > globalMaxSharePointListSize) break;
      }
    }

    return items;
  } catch (err) {
    logger.debug('graphGetChildrenForDriveItem', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphCreateNewFolderInDrive = async (
  client: Client,
  driveId: string,
  driveItemId: string,
  folderName: string,
): Promise<DriveItem> => {
  try {
    const driveItem = {
      name: folderName.trim(),
      folder: {},
      '@microsoft.graph.conflictBehavior': 'rename',
    };

    logger.debug('graphCreateNewFolderInDrive', driveItem);
    const res = await client.api(`/drives/${driveId}/items/${driveItemId}/children`).post(driveItem);

    return res;
  } catch (err) {
    logger.debug('graphCreateNewFolderInDrive', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetDrive = async (client: Client, driveId: string): Promise<Drive | undefined> => {
  try {
    const request = `/drives/${driveId}`;
    logger.debug('graphGetDrive', request);
    const drive: Drive = await client.api(request).get();

    return drive;
  } catch (err) {
    logger.debug('graphGetDrive', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetDriveItemForPath = async (
  client: Client,
  driveId: string,
  path: string,
  fileName: string,
): Promise<DriveItem | undefined> => {
  try {
    if (path.startsWith('/')) path = path.substring(1);
    if (path.endsWith('/')) path = path.slice(0, -1);

    let request = `/drives/${driveId}/root:/${path}/${fileName}`;
    request = encodeURI(request);
    const item: DriveItem = await client.api(request).get();

    return item;
  } catch (err) {
    logger.debug('graphGetDriveItemForPath', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetDriveItemPath = async (
  client: Client,
  item: DriveItem,
  driveId: string,
  driveItemId: string | undefined,
  skipFirstItem: boolean,
): Promise<string | undefined> => {
  try {
    let path = '';

    if (item) {
      if (!skipFirstItem && item.folder && !item.root) {
        path = '/' + item.name;
      }

      while (item.parentReference?.id) {
        const parentRequest = `/drives/${driveId}/items/${item.parentReference?.id}`;
        const parent: DriveItem = await client.api(parentRequest).get();

        //stop at the level of the list, indicated with driveItemId
        if (parent.id === driveItemId) break;

        //or stop at the root of the site
        if (parent.folder && !parent.root) {
          path = '/' + parent.name + path;
          item = parent;
        } else {
          break;
        }
      }
    }

    return path;
  } catch (err) {
    logger.debug('graphGetDriveItemPath', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetDriveItem = async (
  client: Client,
  driveId: string,
  driveItemId: string,
): Promise<DriveItem | undefined> => {
  try {
    const request = `/drives/${driveId}/items/${driveItemId}`;
    logger.debug('graphGetDriveItem', request);
    const item: DriveItem = await client.api(request).get();

    return item;
  } catch (err) {
    logger.debug('graphGetDriveItem', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetDriveItemVersions = async (
  client: Client,
  driveId: string,
  driveItemId: string,
): Promise<DriveItemVersion[]> => {
  try {
    const request = `/drives/${driveId}/items/${driveItemId}/versions`;
    const item = await client.api(request).get();
    if (item && item.value && item.value.length > 0) {
      return item.value;
    }

    return [];
  } catch (err) {
    logger.debug('graphGetDriveItemVersion', err);

    return [];
  }
};

export const graphGetDocumentLibraryItemThumbnails = async (
  client: Client,
  driveId: string,
  driveItemId: string,
): Promise<string | undefined> => {
  try {
    let url = undefined;
    const request = `/drives/${driveId}/items/${driveItemId}/thumbnails?select=small`;
    logger.debug('graphGetDocumentLibraryItemThumbnails', request);
    const item = await client.api(request).get();

    if (item && item.value && item.value.length > 0) {
      url = item.value[0].small.url;
    } else {
      logger.debug('graphGetDocumentLibraryItemThumbnails. Could not get valid response.', item);
    }

    return url;
  } catch (err) {
    logger.debug('graphGetDocumentLibraryItemThumbnails', err);

    return undefined;
  }
};

export const graphGetDocumentLibraryItemContent = async (
  client: Client,
  driveId: string,
  driveItemId: string,
): Promise<Blob> => {
  try {
    const request = `/drives/${driveId}/items/${driveItemId}`;
    logger.debug('graphGetDocumentLibraryItemContent', request);
    const item = await client.api(request).select('content.downloadUrl').get();
    const stream = await fetch(item['@microsoft.graph.downloadUrl']);
    const blob = await stream.blob();

    return blob;
  } catch (err) {
    logger.debug('graphGetDocumentLibraryItemContent', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetDocumentLibraryItemPreview = async (
  client: Client,
  driveId: string,
  driveItemId: string,
): Promise<string | undefined> => {
  try {
    const options = {
      //   page: '1',
      //   zoom: 100
    };

    let url = undefined;
    const request = `/drives/${driveId}/items/${driveItemId}/preview`;
    logger.debug('graphGetDocumentLibraryItemPreview', request);
    const item = await client.api(request).post(options);

    if (item && item.getUrl) {
      url = item.getUrl;
    } else {
      logger.debug('graphGetDocumentLibraryItemPreview. Could not get valid response.', item);
    }
    logger.debug('graphGetDocumentLibraryItemPreview', url);

    return url;
  } catch (err) {
    logger.debug('graphGetDocumentLibraryItemPreview', err);

    return undefined;
  }
};

export const graphGetDocumentLibraryItemEmbedLink = async (
  client: Client,
  driveId: string,
  driveItemId: string,
): Promise<string | undefined> => {
  try {
    const permission = {
      type: 'view',
      scope: 'organization',
    };

    const request = `/drives/${driveId}/items/${driveItemId}/createLink`;
    logger.debug('graphGetDocumentLibraryItemEmbedLink', request);
    const item = await client.api(request).post(permission);

    if (item && item.link && item.link.webUrl) {
      return item.link.webUrl;
    } else {
      logger.debug('graphGetDocumentLibraryItemEmbedLink. Could not get valid response.', item);
    }

    return undefined;
  } catch (err) {
    logger.debug('graphGetDocumentLibraryItemEmbedLink', err);

    return undefined;
  }
};

//
// Sharepoint upload files
//
export const graphUpdateFile = async (
  client: Client,
  file: File,
  driveId: string,
  driveItemId: string,
): Promise<DriveItem | null> => {
  try {
    const res: DriveItem = await client.api(`/drives/${driveId}/items/${driveItemId}/content`).put(file);

    return res;
  } catch (err) {
    logger.debug('graphUpdateFile', err);

    throw AppError.fromGraphError(err);
  }
};

export type onUploadProgress = (totalBytes: number, sendBytes: number) => void;

export const graphUploadFileToFolder = async (
  client: Client,
  file: File,
  driveId: string,
  parentFolderId: string,
  fileName: string,
  description?: string,
  onProgress?: onUploadProgress,
  conflictBehavior?: string,
): Promise<DriveItem | null> => {
  try {
    fileName = fileName.trim();

    const res: ICreateUploadSessionResponse = await client
      .api(`/drives/${driveId}/items/${parentFolderId}:/${fileName}:/createUploadSession`)
      .post({
        name: fileName,
        description: description,
        item: {
          '@microsoft.graph.conflictBehavior': conflictBehavior ?? 'rename',
        },
      });

    const numLoops = Math.ceil(file.size / fileUploadMultipleOf);
    if (onProgress) onProgress(file.size, 0);
    cancelAsyncProcesState = false;

    for (let i = 0; i < numLoops; i++) {
      const chunkStart = i * fileUploadMultipleOf;
      const chunkEnd = Math.min((i + 1) * fileUploadMultipleOf, file.size);
      const tmpSlice = file.slice(chunkStart, chunkEnd);
      if (onProgress) onProgress(file.size, chunkEnd);
      const response = await uploadSinglePart(res.uploadUrl, tmpSlice, file.size, i * fileUploadMultipleOf, chunkEnd);
      if (cancelAsyncProcesState) {
        await axios.delete(res.uploadUrl);
        cancelAsyncProcesState = false;
        break;
      }
      if (response) return response;
    }

    return null;
  } catch (err) {
    logger.debug('graphUploadFileToFolder', err);

    throw AppError.fromGraphError(err);
  }
};

const uploadSinglePart = async (
  url: string,
  bytes: number[] | Blob,
  filesize: number,
  chunkStart: number,
  chunkEnd: number,
): Promise<DriveItem | null> => {
  try {
    const newtworkCall = await axios.put(url, bytes, {
      headers: {
        'Content-Range': 'bytes ' + chunkStart + '-' + (chunkEnd - 1) + '/' + filesize,
      },
    });

    if (newtworkCall.status === 202) return null;

    return newtworkCall.data as DriveItem;
  } catch (err) {
    logger.debug('uploadSinglePart', err);

    throw AppError.fromGraphError(err);
  }
};

//
// Helpers
//
export const graphCheckWriteAccessInDocumentLibrary = async (client: Client, driveId: string): Promise<boolean> => {
  try {
    const driveRoot = await graphGetDriveRoot(client, driveId);
    if (driveRoot && driveRoot.id) {
      const data: BlobPart[] = ['dummyData'];
      const file = new File(data, 'dummyFile.txt');

      const newDriveItem = await graphUploadFileToFolder(client, file, driveId, driveRoot.id, file.name);
      if (newDriveItem && newDriveItem.id) {
        try {
          await graphDeleteFile(client, driveId, newDriveItem.id);
        } catch {
          //ignore
        }
      }
    }

    return true;
  } catch (err) {
    logger.debug('graphCheckWriteAccessInDocumentLibrary', err);

    return false;
  }
};

export const graphDeleteFile = async (client: Client, driveId: string, driveItemId: string): Promise<void> => {
  try {
    const path = `/drives/${driveId}/items/${driveItemId}`;
    logger.debug('graphValidateDocumentLibraryItem', path);
    await client.api(path).header('Prefer', 'bypass-shared-lock').delete();
  } catch (err) {
    logger.debug('graphDeleteFile', err);
    const appErr = AppError.fromGraphError(err);
    if (appErr.code?.toLowerCase() === 'itemNotFound') {
      return; //oke
    } if (appErr.code?.toLowerCase() === 'notAllowed') {
      
    } else {
      throw appErr;
    }
  }
};

export const graphValidateDocumentLibraryItem = async (
  client: Client,
  driveId: string,
  driveItemId: string,
): Promise<boolean> => {
  try {
    const path = `/drives/${driveId}/items/${driveItemId}`;
    logger.debug('graphValidateDocumentLibraryItem', path);
    await client.api(path).get();

    return true;
  } catch (err) {
    logger.debug('graphValidateDocumentLibraryItem', err);

    return false;
  }
};
