import {
  IRenderFunction,
  IDetailsHeaderProps,
  IDetailsColumnRenderTooltipProps,
  TooltipHost,
  Sticky,
  StickyPositionType,
  Label,
  FontIcon,
  Stack,
  Text,
  ITextStyles,
  IFontStyles,
  IDropdownOption,
  IPanelProps,
  IPanelHeaderRenderer,
} from '@fluentui/react';
import { globalStackTokensGapSmall, globalTextStylesBold, infoIcon } from './globalStyles';
import Control from 'models/control';
import Theme from 'models/theme';
import Risk from 'models/risk';
import { IAppContext } from 'App/AppContext';
import { TFunction } from 'i18next';
import Norm from 'models/norm';
import Objective from 'models/objective/objective';
import { PDCAState } from 'models/pdca';
import Process from 'models/process/process';
import Task from 'models/tasks/task';
import Entity, { EntityTypes } from 'models/entity';
import { appRoles } from 'services/Auth/appRoles';
import Asset from 'models/asset/asset';
import KPI from 'models/kpi/kpi';
import Tag from 'models/tag';
import ResourceLink from 'models/resourceLink';
import CopyId from 'components/Utils/CopyId';

//
// Global render functions
//
export const onRenderDetailsHeaderGlobal: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
  if (!props) {
    return null;
  }
  const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
    <TooltipHost {...tooltipHostProps} />
  );

  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );
};

export const onRenderDetailsHeaderNoPaddingTopGlobal: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
  if (!props) {
    return null;
  }
  const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
    <TooltipHost {...tooltipHostProps} />
  );

  props.styles = { root: { paddingTop: 0 } };

  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );
};

export const onRenderPanelHeaderGlobal = (
  title: string,
  id: string | number,
  hasCloseButton: boolean,
): IRenderFunction<IPanelProps> => {
  return (
    props?: IPanelProps,
    defaultRender?: IPanelHeaderRenderer,
    headerTextId?: string | undefined,
  ): JSX.Element | null => {
    return (
      <Stack
        grow={hasCloseButton ? 1 : undefined}
        horizontal
        tokens={globalStackTokensGapSmall}
        verticalAlign="end"
        styles={{ root: { paddingLeft: 24 } }}
      >
        <Text variant="xLarge" styles={globalTextStylesBold}>
          {title}
        </Text>
        <CopyId id={id} />
      </Stack>
    );
  };
};

export const onRenderTextWithInfo = (
  text: string,
  info: string,
  variant?: keyof IFontStyles,
  styles?: Partial<ITextStyles>,
) => {
  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
      <Text variant={variant} styles={styles}>
        {text}
      </Text>
      <TooltipHost content={info}>
        <FontIcon {...infoIcon}></FontIcon>
      </TooltipHost>
    </Stack>
  );
};

export const onRenderLabelWithInfo = (label: string, info: string) => {
  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
      <Label>{label}</Label>
      <TooltipHost content={info}>
        <FontIcon {...infoIcon}></FontIcon>
      </TooltipHost>
    </Stack>
  );
};

//
// Treelist hierarchy functions
//
const getId = (item: Control | Theme | Risk) => {
  if (item instanceof Control) return item.controlId;
  else if (item instanceof Theme) return item.themeId;
  else if (item instanceof Risk) return item.riskId;

  return -1;
};

const getParentId = (item: Control | Theme) => {
  if (item instanceof Control) return item.parentControlId;
  else if (item instanceof Theme) return item.parentThemeId;

  return -1;
};

export function isValidToRemoveFromList(
  itemToRemove: Control | Theme,
  itemsToRemove: (Control | Theme)[],
  allItems: (Control | Theme)[],
): boolean {
  if (getParentId(itemToRemove) === undefined || getParentId(itemToRemove) === null) return true;
  if (itemsToRemove.find((item: Control | Theme) => getId(item) === getParentId(itemToRemove)) !== undefined)
    return false;

  const parentControl = allItems.find((item) => getId(item) === getParentId(itemToRemove));
  if (!parentControl) return true;

  return isValidToRemoveFromList(parentControl, itemsToRemove, allItems);
}

//
// Freshdesk widget API
//

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetOpen = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('open');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetClose = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('close');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetOpenTicket = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('open', 'ticketForm');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetSetSubject = (subject: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('identify', 'ticketForm', {
    subject: subject,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetSetContactInfo = (name: string, email: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('identify', 'ticketForm', {
    name: name,
    email: email,
  });
};

export const freshdeskWidgetSetTranslations = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('setLabels', {
    nl: {
      contact_form: {
        subject: 'Onderwerp',
      },
    },
  });
};

// global functions for rendering string from models
export const getPDCAStateTextForItem = (
  item: Control | Process | Objective | Theme | undefined,
  t: TFunction<string[]>,
): string => {
  if (item) {
    switch (item.state) {
      case PDCAState.Plan:
        return t('translation:PDCAState.Plan');
      case PDCAState.Do:
        return t('translation:PDCAState.Do');
      case PDCAState.Check:
        return t('translation:PDCAState.Check');
      case PDCAState.Act:
        return t('translation:PDCAState.Act');
    }
  }

  return '';
};

// global functions for rendering string from models
export const getPDCAStateText = (state: PDCAState, t: TFunction<string[]>): string => {
  switch (state) {
    case PDCAState.Plan:
      return t('translation:PDCAState.Plan');
    case PDCAState.Do:
      return t('translation:PDCAState.Do');
    case PDCAState.Check:
      return t('translation:PDCAState.Check');
    case PDCAState.Act:
      return t('translation:PDCAState.Act');
  }

  return '';
};

export const getPDCAStateTextList = (t: TFunction<string[]>): IDropdownOption<PDCAState>[] => {
  return [
    {
      key: PDCAState.Plan,
      text: t('translation:PDCAState.Plan'),
    },
    {
      key: PDCAState.Do,
      text: t('translation:PDCAState.Do'),
    },
    {
      key: PDCAState.Check,
      text: t('translation:PDCAState.Check'),
    },
    {
      key: PDCAState.Act,
      text: t('translation:PDCAState.Act'),
    },
  ];
};

export const getEntityName = (entity: Entity, t: TFunction<string[]>) => {
  let type: string = '';
  switch (entity.typeOfEntity) {
    case EntityTypes.Control:
      type = t('translation:General.EntityType.Control');
      break;
    case EntityTypes.Task:
      type = t('translation:General.EntityType.Task');
      break;
    case EntityTypes.Risk:
      type = t('translation:General.EntityType.Risk');
      break;
    case EntityTypes.Theme:
      type = t('translation:General.EntityType.Requirement');
      break;
    case EntityTypes.Dashboard:
      type = t('translation:General.EntityType.Dashboard');
      break;
    case EntityTypes.KPILink:
      type = t('translation:General.EntityType.KPILink');
      break;
    case EntityTypes.Link:
      type = t('translation:General.EntityType.Link');
      break;
    case EntityTypes.List:
      type = t('translation:General.EntityType.List');
      break;
    case EntityTypes.Objective:
      type = t('translation:General.EntityType.Objective');
      break;
    case EntityTypes.Process:
      type = t('translation:General.EntityType.Process');
      break;
    case EntityTypes.Package:
      type = t('translation:General.EntityType.Package');
      break;
    case EntityTypes.Standard:
      type = t('translation:General.EntityType.Standard');
      break;
    case EntityTypes.Tag:
      type = t('translation:General.EntityType.Tag');
      break;
    case EntityTypes.Widget:
      type = t('translation:General.EntityType.Widget');
      break;
    case EntityTypes.Asset:
      type = t('translation:General.EntityType.Asset');
      break;
    case EntityTypes.Classification:
      type = t('translation:General.EntityType.Classification');
      break;
    case EntityTypes.KPI:
      type = t('translation:General.EntityType.KPI');
      break;
    case EntityTypes.TaskType:
      type = t('translation:General.EntityType.TaskType');
      break;
    default:
      break;
  }

  return type;
};

export const getEntity = (
  input: Control | Theme | Risk | Process | Objective | Task | Asset | KPI | Tag | ResourceLink,
): Entity => {
  if (input instanceof Control) {
    return new Entity(input.controlId, EntityTypes.Control, input.name, input.code);
  } else if (input instanceof Theme) {
    return new Entity(input.themeId, EntityTypes.Theme, input.name, input.code);
  } else if (input instanceof Risk) {
    return new Entity(input.riskId, EntityTypes.Risk, input.name, input.code);
  } else if (input instanceof Process) {
    return new Entity(input.processId, EntityTypes.Process, input.name, input.code);
  } else if (input instanceof Objective) {
    return new Entity(input.objectiveId, EntityTypes.Objective, input.name, input.code);
  } else if (input instanceof Task) {
    return new Entity(input.taskId, EntityTypes.Task, input.name);
  } else if (input instanceof Asset) {
    return new Entity(input.assetId, EntityTypes.Asset, input.name, input.code);
  } else if (input instanceof KPI) {
    return new Entity(input.kpiId, EntityTypes.KPI, input.name);
  } else if (input instanceof Tag) {
    return new Entity(input.tagId, EntityTypes.Tag, input.value());
  } else if (input instanceof ResourceLink) {
    return new Entity(input.linkId, EntityTypes.Link, input.linkName, input.linkURL);
  }

  return new Entity();
};

export const getOwnerCellName = (
  item: Control | Process | Objective | Theme | Risk | undefined,
  appContext: IAppContext,
): string => {
  if (!item || !item.ownerId) return '';

  return appContext.globalDataCache.users.get(item.ownerId).name;
};

export const getNormCellName = (item: Theme | Control | undefined, norms: Norm[]) => {
  if (!item || (!item.isoNormIds && !item.customNormIds)) {
    return '';
  }

  const normNames: string[] = [];

  if (item.isoNormIds) {
    for (let id of item.isoNormIds) {
      const norm: Norm | undefined = norms.find((n) => {
        return n.isoNormId === id;
      });
      if (norm) {
        normNames.push(norm.name);
      }
    }
  }

  if (item.customNormIds) {
    for (let id of item.customNormIds) {
      const norm: Norm | undefined = norms.find((n) => {
        return n.normId === id;
      });
      if (norm) {
        normNames.push(norm.name);
      }
    }
  }

  let nameString: string = '';

  for (let idx = 0; idx < normNames.length; idx++) {
    nameString += normNames[idx];
    if (idx < normNames.length - 1) {
      nameString += ', ';
    }
  }

  return nameString;
};

export const GetRoleTranslation = (role: string, t: TFunction<string[]>): string => {
  switch (role) {
    case appRoles.User:
      return t(`translation:Roles.User`);
    case appRoles.Manager:
      return t(`translation:Roles.Manager`);
    case appRoles.Admin:
      return t(`translation:Roles.Admin`);
    case appRoles.Consultant:
      return t(`translation:Roles.Consultant`);
    case appRoles.LicenseManager:
      return t(`translation:Roles.LicenseManager`);
    case appRoles.OrgAdmin:
      return t(`translation:Roles.OrgAdmin`);
    case appRoles.TenantISOAdmin:
      return t(`translation:Roles.TenantISOAdmin`);
    case appRoles.TenantGlobalAdmin:
      return t(`translation:Roles.TenantGlobalAdmin`);
  }

  return t(`translation:Roles.Empty`);
};

export const getRolesDisplayString = (roles: string[], t: TFunction<string[]>): string => {
  let rolestring = '';

  if (roles) {
    for (let role of roles) {
      if (rolestring.length > 0) {
        rolestring += ', ';
      }
      rolestring += GetRoleTranslation(role, t);
    }
  }

  if (rolestring.length === 0) {
    rolestring = GetRoleTranslation('Empty', t);
  }

  return rolestring;
};
