import Language from 'models/language';
import { mapFromLanguage, mapToLanguage } from './langMapping';
import TaskTypeDTO from 'models/dto/tasks/taskTypeDTO';
import TaskType from 'models/tasks/taskType';
import { TaskType_TranslationDTO } from 'models/dto/tasks/taskType_TranslationDTO';
import { TaskType_Translation } from 'models/tasks/taskType_Translation';
import TaskTypeFormElementDTO from 'models/dto/tasks/taskTypeFormElementDTO';
import TaskTypeFormElement from 'models/tasks/taskTypeFormElement';
import { mapFromKPI } from './kpiMapping';
import { TaskTypeFormElement_TranslationDTO } from 'models/dto/tasks/taskTypeFormElement_TranslationDTO';
import { TaskTypeFormElement_Translation } from 'models/tasks/taskTypeFormElement_Translation';

export function mapFromTaskTypes(tasktypeDTOs: TaskTypeDTO[] | undefined): TaskType[] {
  if (!tasktypeDTOs) return [];

  const output = tasktypeDTOs.map((item) => {
    return mapFromTaskType(item);
  });

  return output;
}

export function mapFromTaskType(tasktypeDTO: TaskTypeDTO): TaskType {
  const output = new TaskType();

  output.taskTypeId = tasktypeDTO.taskTypeId;
  output.systemTaskType = tasktypeDTO.systemTaskType;
  output.isSystem = tasktypeDTO.isSystem;
  output.trans = mapFromTaskType_Translation(tasktypeDTO.trans);
  output.form = mapFromTaskTypeForm(tasktypeDTO.form);

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
    output.description = output.trans[0].description;
  }

  return output;
}

export const mapFromTaskType_Translation = (transDTOs: TaskType_TranslationDTO[]): TaskType_Translation[] => {
  const output: TaskType_Translation[] = [];

  for (let transDTO of transDTOs) {
    const newObject_Translation = new TaskType_Translation();

    newObject_Translation.taskTypeId = transDTO.taskTypeId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapFromLanguage(transDTO.lang) ?? new Language();

    output.push(newObject_Translation);
  }

  return output;
};

export function mapToTaskType(taskType: TaskType): TaskTypeDTO {
  const output = new TaskTypeDTO();

  output.taskTypeId = taskType.taskTypeId;
  output.systemTaskType = taskType.systemTaskType;
  output.trans = mapToTaskType_Translation(taskType.trans);
  output.form = mapToTaskTypeForm(taskType.form);

  if (taskType.trans && taskType.transIdx >= 0) {
    output.trans[taskType.transIdx].name = taskType.name;
    output.trans[taskType.transIdx].description = taskType.description;
  }

  return output;
}

export const mapToTaskType_Translation = (transDTOs: TaskType_Translation[]): TaskType_TranslationDTO[] => {
  const output: TaskType_TranslationDTO[] = [];

  for (let transDTO of transDTOs) {
    const newObject_Translation = new TaskType_TranslationDTO();

    newObject_Translation.taskTypeId = transDTO.taskTypeId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapToLanguage(transDTO.lang);

    output.push(newObject_Translation);
  }

  return output;
};

export const mapFromTaskTypeForm = (elements: TaskTypeFormElementDTO[] | undefined): TaskTypeFormElement[] => {
  const output: TaskTypeFormElement[] = [];
  if (!elements) return output;

  for (let elm of elements) {
    const newElm = new TaskTypeFormElement();

    newElm.kpiId = elm.kpiId;
    newElm.taskTypeId = elm.taskTypeId;
    newElm.fieldId = elm.fieldId;
    newElm.fieldType = elm.fieldType;
    newElm.fieldMode = elm.fieldMode;
    newElm.kpi = elm.kpi ? mapFromKPI(elm.kpi, undefined) : undefined;
    newElm.sortOrder = elm.sortOrder;
    newElm.listId = elm.listId;
    newElm.trans = mapFromTaskTypeFormElement_Translation(elm.trans);

    if (newElm.trans && newElm.trans.length > 0) {
      newElm.transIdx = 0;
      newElm.name = elm.trans[0].name;
      newElm.description = elm.trans[0].description;
      newElm.expectedResult = elm.trans[0].expectedResult;
      newElm.instruction = elm.trans[0].instruction;
    }

    output.push(newElm);
  }

  return output;
};

export const mapToTaskTypeForm = (elements: TaskTypeFormElement[] | undefined): TaskTypeFormElementDTO[] => {
  const output: TaskTypeFormElementDTO[] = [];
  if (!elements) return output;

  for (let elm of elements) {
    const newElm = new TaskTypeFormElementDTO();

    newElm.kpiId = elm.kpiId;
    newElm.taskTypeId = elm.taskTypeId;
    newElm.fieldId = elm.fieldId;
    newElm.fieldType = elm.fieldType;
    newElm.fieldMode = elm.fieldMode;
    newElm.sortOrder = elm.sortOrder;
    newElm.listId = elm.listId;
    newElm.trans = mapToTaskTypeFormElement_Translation(elm.trans);

    if (elm.trans && elm.transIdx >= 0) {
      newElm.trans[elm.transIdx].name = elm.name;
      newElm.trans[elm.transIdx].description = elm.description;
      newElm.trans[elm.transIdx].expectedResult = elm.expectedResult;
      newElm.trans[elm.transIdx].instruction = elm.instruction;
    }

    output.push(newElm);
  }

  return output;
};

export const mapFromTaskTypeFormElement_Translation = (
  transDTOs: TaskTypeFormElement_TranslationDTO[],
): TaskTypeFormElement_Translation[] => {
  const output: TaskTypeFormElement_Translation[] = [];

  for (let transDTO of transDTOs) {
    const newObject_Translation = new TaskTypeFormElement_Translation();

    newObject_Translation.taskTypeId = transDTO.taskTypeId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.expectedResult = transDTO.expectedResult;
    newObject_Translation.instruction = transDTO.instruction;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapFromLanguage(transDTO.lang) ?? new Language();

    output.push(newObject_Translation);
  }

  return output;
};

export const mapToTaskTypeFormElement_Translation = (
  transDTOs: TaskTypeFormElement_Translation[],
): TaskTypeFormElement_TranslationDTO[] => {
  const output: TaskTypeFormElement_TranslationDTO[] = [];

  for (let transDTO of transDTOs) {
    const newObject_Translation = new TaskTypeFormElement_TranslationDTO();

    newObject_Translation.taskTypeId = transDTO.taskTypeId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.expectedResult = transDTO.expectedResult;
    newObject_Translation.instruction = transDTO.instruction;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapToLanguage(transDTO.lang);

    output.push(newObject_Translation);
  }

  return output;
};
