import { globalAppVersion } from 'globalConstants';
import UAParser from 'ua-parser-js';
import AppError from 'utils/appError';

class EnvInfo {
  appVersion: string;

  appEnv: string;

  screenHeight?: number;

  screenWidth?: number;

  envInfo?: UAParser.IResult;

  constructor() {
    this.appVersion = '';
    this.appEnv = '';
  }
}

class Config {
  static appURL: string | undefined;

  getVersion(): string {
    return globalAppVersion;
  }

  // the REACT_APP_STAGE environment variable is injected into the build in package.json in the scripts section

  isLocal(): boolean {
    return process.env.REACT_APP_STAGE?.trimEnd() === 'local'; // local front-end and local API
  }

  isDev(): boolean {
    return process.env.REACT_APP_STAGE?.trimEnd() === 'development'; // production build in development mode in Azure
  }

  isTest(): boolean {
    return process.env.REACT_APP_STAGE?.trimEnd() === 'test'; // run testing
  }

  isProd(): boolean {
    return process.env.REACT_APP_STAGE?.trimEnd() === 'production'; // production build in production mode in Azure
  }

  isFront(): boolean {
    return process.env.REACT_APP_STAGE?.trimEnd() === 'front'; // local front-end and development back-end in Azure
  }

  getAppEnv(): string {
    return process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE?.trimEnd() : 'unknown';
  }

  getAppURL(): string {
    if (Config.appURL === undefined) {
      Config.appURL = document.location.protocol + '//' + document.location.host;
      if (Config.appURL.endsWith('/')) {
        Config.appURL = Config.appURL.slice(0, -1);
      }
      console.log(`App URL set: ${Config.appURL}`);
    }

    return Config.appURL;
  }

  getImageURL(): string {
    return this.getAppURL() + '/images';
  }

  get(name: string): string {
    if (this.isProd()) {
      return this.getProd(name);
    }

    if (this.isTest()) {
      return this.getTest(name);
    }

    if (this.isDev() || this.isFront()) {
      return this.getTest(name); //dev experiences a CORS issue
    }

    if (this.isLocal()) {
      return this.getLocal(name);
    }

    throw new AppError(`Unknown environment: ${this.getAppEnv()}`);
  }

  getLocal(name: string): string {
    switch (name) {
      case 'Log.Level':
        return 'debug';
      case 'App.BaseURL':
        return 'http://localhost:3000';
      case 'App.ClientId':
        return 'd67b2608-bb92-4605-9c74-52fdcda660e5';
      case 'Api.BaseURL':
        return 'http://localhost:61308';
      case 'Api.ClientId':
        return 'c03d205e-3a66-4a42-a9d6-cc9319b44858';
      case 'Svc.ClientId':
        return '2d32c615-74e9-4f4c-98ef-b493a2f62c42';
      default:
        throw new AppError(`Value of dev appsetting ${name} not defined.`);
    }
  }

  getDev(name: string): string {
    switch (name) {
      case 'Log.Level':
        return 'debug';
      case 'App.BaseURL':
        return 'https://isoplanner-dev.azurewebsites.net';
      case 'App.ClientId':
        return 'd67b2608-bb92-4605-9c74-52fdcda660e5';
      case 'Api.BaseURL':
        return 'https://isoplanner-dev-api.azurewebsites.net';
      case 'Api.ClientId':
        return 'c03d205e-3a66-4a42-a9d6-cc9319b44858';
      case 'Svc.ClientId':
        return '2d32c615-74e9-4f4c-98ef-b493a2f62c42';
      default:
        throw new AppError(`Value of dev appsetting ${name} not defined.`);
    }
  }

  getTest(name: string): string {
    switch (name) {
      case 'Log.Level':
        return 'debug';
      case 'App.BaseURL':
        return 'https://test.isoplanner.app';
      case 'App.ClientId':
        return 'd819e0c9-4591-4444-a397-584e9c029e9e';
      case 'Api.BaseURL':
        return 'https://test-api.isoplanner.app';
      case 'Api.ClientId':
        return '60c2ef0e-b6cc-4d49-8ada-bacc7a6fd277';
      case 'Svc.ClientId':
        return '9f1673e2-07b2-47b2-915f-15f66b93bbcb';
      default:
        throw new AppError(`Value of test appsetting ${name} not defined.`);
    }
  }

  getProd(name: string): string {
    switch (name) {
      case 'Log.Level':
        return 'error';
      case 'App.BaseURL':
        return 'https://portal.isoplanner.app';
      case 'App.ClientId':
        return '2a2fc0d2-c9ab-4e14-9d0b-79618085e85e';
      case 'Api.BaseURL':
        return 'https://api.isoplanner.app/';
      case 'Api.ClientId':
        return 'ea7e8086-f302-4147-aae3-779149fb15aa';
      case 'Svc.ClientId':
        return '9a056f37-32fc-48b0-8075-e032dec8e3ac';
      default:
        throw new AppError(`Value of prod appsetting ${name} not defined.`);
    }
  }

  getEnvInfo(): string {
    try {
      const envInfo = new EnvInfo();
      envInfo.appVersion = this.getVersion();
      envInfo.appEnv = this.getAppEnv();
      envInfo.screenHeight = window.screen.height;
      envInfo.screenWidth = window.screen.width;

      const parser = new UAParser();
      envInfo.envInfo = parser.getResult();

      return JSON.stringify(envInfo);
    } catch (err) {
      return JSON.stringify(err);
    }
  }
}

const config = new Config();
export default config;
