import { TFunction } from 'i18next';

export enum SubscriptionTypes {
  Basic = 0,
  Business = 1,
  Premium = 2,
  Custom = 9,
  Container = 19,
  Partner = 99,
  Internal = 100,
}

export const getSubscriptionTypeText = (type: SubscriptionTypes, t: TFunction<string[]>): string => {
  switch (type) {
    case SubscriptionTypes.Basic:
      return t('adminSubscription:Basic.Title');
    case SubscriptionTypes.Business:
      return t('adminSubscription:Business.Title');
    case SubscriptionTypes.Premium:
      return t('adminSubscription:Premium.Title');
    case SubscriptionTypes.Partner:
      return t('adminSubscription:Partner.Title');
    case SubscriptionTypes.Internal:
      return t('adminSubscription:Internal.Title');
    case SubscriptionTypes.Custom:
      return t('adminSubscription:Custom.Title');
    case SubscriptionTypes.Container:
      return t('adminSubscription:Container.Title');
  }
};
