import { TeamsChannel } from 'models/teamsChannel';
import { newGuidNil } from 'utils/guid';
export default class Group {
  id: string;

  name: string;

  email: string;

  channels: TeamsChannel[];

  constructor(_id?: string, _name?: string, _email?: string) {
    this.id = _id ? _id : '';
    this.name = _name ? _name : '';
    this.email = _email ? _email : '';
    this.channels = [];
  }

  static getEmptyGroup() {
    return new Group(newGuidNil(), '', '');
  }

  clone() {
    const newGroup = new Group(this.id, this.name, this.email);
    newGroup.channels = [...this.channels];

    return newGroup;
  }
}
