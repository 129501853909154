import { IAppContext } from 'App/AppContext';
import AppError from 'utils/appError';

export enum ResourceListType {
  DocumentLibrary = 0,
  CustomList = 1,
  WebURL = 2,
  SitePageLibrary = 3,
}

export enum ResourceListSystemType {
  EMailAttachments = 1,
  Evidence = 2,
  Assets = 3,
  Threats = 4,
  BaseEvidence = 5
}

export default class ResourceList {
  listId: number;

  listType: ResourceListType;

  systemListId?: ResourceListSystemType;

  spSiteId?: string;

  spListId?: string;

  spDriveId?: string;

  spDriveItemId?: string;

  name: string;

  webURL?: string;

  altTenantId?: string;

  altTenantName?: string;

  columnProps?: string;

  enableMetaData?: boolean;
  
  isVirtual?: boolean;

  constructor() {
    this.listId = -1;
    this.listType = ResourceListType.DocumentLibrary;
    this.spSiteId = '';
    this.spListId = '';
    this.spDriveId = '';
    this.spDriveItemId = '';
    this.name = '';
    this.webURL = '';
  }

  clone = (): ResourceList => {
    const output = new ResourceList();

    output.listId = this.listId;
    output.listType = this.listType;
    output.spSiteId = this.spSiteId;
    output.spDriveId = this.spDriveId;
    output.spListId = this.spListId;
    output.spDriveItemId = this.spDriveItemId;
    output.name = this.name;
    output.webURL = this.webURL;
    output.altTenantId = this.altTenantId;
    output.altTenantName = this.altTenantName;
    output.columnProps = this.columnProps;
    output.enableMetaData = this.enableMetaData;
    output.isVirtual = this.isVirtual;
    
    return output;
  };

  static getUniqueName(orgName: string, appContext: IAppContext): string {
    let listWithSameName: ResourceList | undefined = undefined;
    let suffix: number = 1;
    let newName: string = orgName;

    listWithSameName = appContext.globalDataCache.lists.items.find(
      (l) => l.name.toLowerCase() === orgName?.toLowerCase(),
    );

    while (listWithSameName) {
      const name = newName + ' ' + suffix.toString();
      listWithSameName = appContext.globalDataCache.lists.items.find(
        (l) => l.name.toLowerCase() === name.toLowerCase(),
      );
      if (suffix > 100) throw new AppError('Create new list name failed: 100 iterations reached');
      suffix++;
      newName = name;
    }

    return newName;
  }
}
