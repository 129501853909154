import DashboardSceneDTO, { NormCoverageDTO, UserStatDTO } from 'models/dto/dashboardSceneDTO';
import DashboardScene, { NormCoverage, UserTaskStat } from 'models/dashboardScene';
import { mapFromActivities } from 'mappings/activityMapping';
import Dashboard, { Dashboard_Translation } from 'models/dashboard';
import { fromApiDateTimeOptional } from 'utils/datetime';
import DashboardDTO, { DashboardGroupDTO, DashboardUserDTO, Dashboard_TranslationDTO } from 'models/dto/dashboardDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { mapFromLanguage, mapToLanguage } from './langMapping';
import WidgetDTO, { Widget_TranslationDTO } from 'models/dto/widgetDTO';
import Widget, { Widget_Translation } from 'models/widget';
import Group from 'models/group';
import User from 'models/user';

export function mapFromDashboards(dashboardDTOs: DashboardDTO[], cache: GlobalDataCache): Dashboard[] {
  return dashboardDTOs.map((d) => mapFromDashboard(d, cache));
}

export function mapFromDashboard(dashboardDTO: DashboardDTO, cache: GlobalDataCache): Dashboard {
  const output: Dashboard = new Dashboard(dashboardDTO.dashboardType);
  output.dashboardId = dashboardDTO.dashboardId;
  output.created = fromApiDateTimeOptional(dashboardDTO.created) ?? new Date();
  output.createdById = dashboardDTO.createdById ?? '';
  output.createdBy = cache.users.get(dashboardDTO.createdById);
  output.sortOrder = dashboardDTO.sortOrder;
  output.trans = mapFromDashboard_Translation(dashboardDTO.trans);
  output.groups = mapFromDashboardGroups(dashboardDTO.groups, cache);
  output.users = mapFromDashboardUsers(dashboardDTO.users, cache);
  output.widgets = dashboardDTO.widgets ? mapFromWidgets(dashboardDTO.widgets, cache) : [];

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
    output.description = output.trans[0].description;
  }

  return output;
}

export function mapFromDashboardGroups(
  groups: DashboardGroupDTO[] | undefined,
  cache: GlobalDataCache,
): Group[] | undefined {
  return groups?.map((g) => cache.groups.get(g.groupId));
}

export function mapFromDashboardUsers(
  users: DashboardUserDTO[] | undefined,
  cache: GlobalDataCache,
): User[] | undefined {
  return users?.map((u) => cache.users.get(u.userId));
}

export function mapFromDashboard_Translation(tranDTOs: Dashboard_TranslationDTO[]): Dashboard_Translation[] {
  let output: Dashboard_Translation[] = [];

  for (let tranDTO of tranDTOs) {
    const newTran = new Dashboard_Translation();
    newTran.dashboardId = tranDTO.dashboardId;
    newTran.languageId = tranDTO.languageId;
    newTran.name = tranDTO.name;
    newTran.description = tranDTO.description;
    newTran.lang = mapFromLanguage(tranDTO.lang);
    output.push(newTran);
  }

  return output;
}

export function mapToDashboard(dashboard: Dashboard): DashboardDTO {
  const output = new DashboardDTO();
  output.dashboardId = dashboard.dashboardId;
  output.dashboardType = dashboard.dashboardType;
  output.sortOrder = dashboard.sortOrder;
  output.trans = mapToDashboard_Translation(dashboard.trans);
  output.groups = mapToDashboardGroups(dashboard.groups);
  output.users = mapToDashboardUsers(dashboard.users);

  if (output.trans && dashboard.transIdx >= 0) {
    output.trans[dashboard.transIdx].name = dashboard.name;
    output.trans[dashboard.transIdx].description = dashboard.description;
  }

  return output;
}

export function mapToDashboardGroups(groups?: Group[]): DashboardGroupDTO[] | undefined {
  return groups?.map((g) => new DashboardGroupDTO(g.id));
}

export function mapToDashboardUsers(users?: User[]): DashboardUserDTO[] | undefined {
  return users?.map((g) => new DashboardUserDTO(g.id));
}

export function mapToDashboard_Translation(
  dashboard_Translations: Dashboard_Translation[],
): Dashboard_TranslationDTO[] {
  let dashboard_TranslationList: Dashboard_TranslationDTO[] = [];

  for (let dashboard_Translation of dashboard_Translations) {
    const newIControl_TranslationDTO = new Dashboard_TranslationDTO();
    newIControl_TranslationDTO.dashboardId = dashboard_Translation.dashboardId;
    newIControl_TranslationDTO.languageId = dashboard_Translation.languageId;
    newIControl_TranslationDTO.name = dashboard_Translation.name;
    newIControl_TranslationDTO.description = dashboard_Translation.description;
    newIControl_TranslationDTO.lang = mapToLanguage(dashboard_Translation.lang);
    dashboard_TranslationList.push(newIControl_TranslationDTO);
  }

  return dashboard_TranslationList;
}

export function mapFromWidgets(widgetDTOs: WidgetDTO[], cache: GlobalDataCache): Widget[] {
  return widgetDTOs.map((d) => mapFromWidget(d, cache));
}

export function mapFromWidget(widgetDTO: WidgetDTO, cache: GlobalDataCache): Widget {
  const output: Widget = new Widget();
  output.widgetId = widgetDTO.widgetId;
  output.dashboardId = widgetDTO.dashboardId;
  output.widgetType = widgetDTO.widgetType;
  output.widgetConfig = widgetDTO.widgetConfig;
  output.sortOrder = widgetDTO.sortOrder;
  output.trans = mapFromWidget_Translation(widgetDTO.trans);

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
  }

  return output;
}

export function mapFromWidget_Translation(tranDTOs: Widget_TranslationDTO[]): Widget_Translation[] {
  let output: Widget_Translation[] = [];

  for (let tranDTO of tranDTOs) {
    const newTran = new Widget_Translation();
    newTran.widgetId = tranDTO.widgetId;
    newTran.languageId = tranDTO.languageId;
    newTran.name = tranDTO.name;
    newTran.lang = mapFromLanguage(tranDTO.lang);
    output.push(newTran);
  }

  return output;
}

export function mapToWidget(widget: Widget): WidgetDTO {
  const output = new WidgetDTO();
  output.widgetId = widget.widgetId;
  output.dashboardId = widget.dashboardId;
  output.widgetType = widget.widgetType;
  output.sortOrder = widget.sortOrder;
  output.widgetConfig = widget.widgetConfig;
  output.trans = mapToWidget_Translation(widget.trans);

  if (output.trans && widget.transIdx >= 0) {
    output.trans[widget.transIdx].name = widget.name;
  }

  return output;
}

export function mapToWidget_Translation(widget_Translations: Widget_Translation[]): Widget_TranslationDTO[] {
  let widget_TranslationList: Widget_TranslationDTO[] = [];

  for (let widget_Translation of widget_Translations) {
    const newIControl_TranslationDTO = new Widget_TranslationDTO();
    newIControl_TranslationDTO.widgetId = widget_Translation.widgetId;
    newIControl_TranslationDTO.languageId = widget_Translation.languageId;
    newIControl_TranslationDTO.name = widget_Translation.name;
    newIControl_TranslationDTO.lang = mapToLanguage(widget_Translation.lang);
    widget_TranslationList.push(newIControl_TranslationDTO);
  }

  return widget_TranslationList;
}

export function mapFromDashboardScene(dashboardSceneDTO: DashboardSceneDTO): DashboardScene {
  const output = new DashboardScene();

  output.activities = mapFromActivities(dashboardSceneDTO.activities);

  return output;
}

export function mapFromNormCoverage(normCoverageDTO: NormCoverageDTO): NormCoverage {
  const normCoverage = new NormCoverage();

  normCoverage.normId = normCoverageDTO.normId;
  normCoverage.isoNormId = normCoverageDTO.isoNormId;
  normCoverage.normName = normCoverageDTO.normName;
  normCoverage.notImplemented = normCoverageDTO.notImplemented;
  normCoverage.controlCount = normCoverageDTO.controlCount;
  normCoverage.coveredCount = normCoverageDTO.coveredCount;
  normCoverage.notPlannedCount = normCoverageDTO.notPlannedCount;
  normCoverage.outOfScopeCount = normCoverageDTO.outOfScopeCount;

  return normCoverage;
}

export function mapFromUserTaskStats(userStatsDTO: UserStatDTO[]): UserTaskStat[] {
  const userStats: UserTaskStat[] = [];
  for (let userStatDTO of userStatsDTO) {
    const userStat = new UserTaskStat();

    userStat.userId = userStatDTO.userId;
    userStat.taskCount = userStatDTO.taskCount;

    userStats.push(userStat);
  }

  return userStats;
}
