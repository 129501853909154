import { GraphRequest } from '@microsoft/microsoft-graph-client';
import logger from 'services/Logging/logService';
import AppError from 'utils/appError';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const execGet = async (request: GraphRequest): Promise<any> => {
  try {
    return await request.get();
  } catch (err1) {
    const graphErr = AppError.fromGraphError(err1);

    if (graphErr?.message && graphErr.message === 'Failed to fetch') {
      //retry
      try {
        logger.debug('execGet retry', err1);
        await new Promise(resolve => setTimeout(resolve, 1000));

        return await request.get();
      } catch (err2) {
        logger.debug('execGet retry error', err2);
        throw err2;
      }
    } else {
      throw err1;
    }
  }
};
