import { IAppContext } from 'App/AppContext';
import GlobalDataCache from './globalDataCache';

export interface IEntityCache<T, U> {
  has(id: string | number | undefined): boolean;
  get(id: string | number | undefined): T;
  getItems: (refresh: boolean) => Promise<T[]>;
  clone(): U;
  add(item: T): void;
  update(item: T): void;
  remove(item: T): void;
}

export class EntityCache<T> {
  misses: number;

  items: T[];

  appContext?: IAppContext;

  cacheRef?: GlobalDataCache;

  constructor() {
    this.misses = 0;
    this.items = [];
  }

  addMiss() {
    this.misses++;
    if (this.appContext) {
      this.appContext.cacheMiss(this.misses);
    }
  }
}
