import Asset from 'models/asset/asset';
import { Asset_Translation } from 'models/asset/asset_Translation';
import AssetDTO from 'models/dto/asset/assetDTO';
import { Asset_TranslationDTO } from 'models/dto/asset/asset_TranslationDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import Language from 'models/language';
import { mapFromClassifications, mapToClassifications } from './classificationMapping';
import { mapFromLanguage, mapToLanguage } from './langMapping';
import { mapFromResourceLinks, mapToResourceLinks } from './linkMapping';
import IdListDTO from 'models/dto/IdListDTO';
import { fromApiDateTimeOptional, toApiDateTimeOptional } from 'utils/datetime';
import { AssetContextDTO } from 'models/dto/asset/assetContextDTO';
import { AssetContext } from 'models/asset/assetContext';
import { mapFromRisksArray } from './riskMapping';
import { mapFromProcesses } from './processMapping';

export function mapFromAssets(assetDTOs: AssetDTO[] | undefined, cache: GlobalDataCache): Asset[] {
  if (!assetDTOs) return [];

  const output = assetDTOs.map((item) => {
    return mapFromAsset(item, cache);
  });

  return output;
}

export function mapFromAsset(assetDTO: AssetDTO, cache: GlobalDataCache): Asset {
  const output = new Asset();

  output.assetId = assetDTO.assetId;
  output.auditTrailId = assetDTO.auditTrailId;
  output.code = assetDTO.code;
  output.externalId = assetDTO.externalId;
  output.ownerId = assetDTO.ownerId;
  output.isPhysical = assetDTO.isPhysical;
  output.state = assetDTO.state;
  output.trans = mapFromAsset_Translation(assetDTO.trans);
  output.listId = assetDTO.listId;
  output.classifications = mapFromClassifications(assetDTO.classifications ?? [], cache);
  output.containers = mapFromAssets(assetDTO.containers, cache);
  output.links = mapFromResourceLinks(assetDTO.links ?? [], cache);
  output.normIds = assetDTO.normIds?.idList?.map((n) => Number.parseInt(n));
  output.tagIds = assetDTO.tagIds?.idList?.map((n) => Number.parseInt(n));
  output.classificationGroups = assetDTO.classificationGroups?.idList?.map((n) => Number.parseInt(n));
  output.lifeCycleStart = fromApiDateTimeOptional(assetDTO.lifeCycleStart);
  output.lifeCycleEnd = fromApiDateTimeOptional(assetDTO.lifeCycleEnd);
  output.primaryContainerId = assetDTO.primaryContainerId;

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
    output.description = output.trans[0].description;
  }

  return output;
}

export const mapFromAsset_Translation = (transDTOs: Asset_TranslationDTO[] | undefined): Asset_Translation[] => {
  if (!transDTOs) return [];

  const output: Asset_Translation[] = [];

  for (let transDTO of transDTOs) {
    const newObject_Translation = new Asset_Translation();

    newObject_Translation.assetId = transDTO.assetId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapFromLanguage(transDTO.lang) ?? new Language();

    output.push(newObject_Translation);
  }

  return output;
};

export function mapToAssets(assets: Asset[] | undefined): AssetDTO[] {
  if (!assets) return [];

  const output = assets.map((item) => {
    return mapToAsset(item);
  });

  return output;
}

export function mapToAsset(asset: Asset): AssetDTO {
  const output = new AssetDTO();

  output.assetId = asset.assetId;
  output.auditTrailId = asset.auditTrailId;
  output.code = asset.code;
  output.externalId = asset.externalId;
  output.ownerId = asset.ownerId;
  output.isPhysical = asset.isPhysical;
  output.state = asset.state;
  output.trans = asset.trans.map((t) => mapToAsset_Translation(t));
  output.listId = asset.listId;
  output.classifications = mapToClassifications(asset.classifications);
  output.containers = asset.containers?.map((c) => mapToAsset(c));
  output.links = mapToResourceLinks(asset.links, false);
  output.normIds = asset.normIds ? new IdListDTO(asset.normIds.map((id) => id.toString())) : undefined;
  output.tagIds = asset.tagIds ? new IdListDTO(asset.tagIds.map((id) => id.toString())) : undefined;
  output.classificationGroups = asset.classificationGroups
    ? new IdListDTO(asset.classificationGroups.map((id) => id.toString()))
    : undefined;
  output.lifeCycleStart = toApiDateTimeOptional(asset.lifeCycleStart);
  output.lifeCycleEnd = toApiDateTimeOptional(asset.lifeCycleEnd);
  output.primaryContainerId = asset.primaryContainerId;

  if (asset.trans && asset.transIdx >= 0) {
    output.trans[asset.transIdx].name = asset.name;
    output.trans[asset.transIdx].description = asset.description;
  }

  return output;
}

export function mapToAsset_Translation(asset_Translation: Asset_Translation): Asset_TranslationDTO {
  const output = new Asset_TranslationDTO();
  output.assetId = asset_Translation.assetId;
  output.languageId = asset_Translation.languageId;
  output.name = asset_Translation.name;
  output.description = asset_Translation.description;
  output.lang = mapToLanguage(asset_Translation.lang);

  return output;
}

//asset context
export const mapFromAssetContext = (context: AssetContextDTO, globalCache: GlobalDataCache): AssetContext => {
  const output = new AssetContext();
  output.risks = mapFromRisksArray(context.risks, globalCache);
  output.processes = mapFromProcesses(context.processes, globalCache);

  return output;
};
