import Joi from 'joi';
import Classification from 'models/classification/classification';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import ResourceLink from '../resourceLink';
import { Asset_Translation } from './asset_Translation';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { TFunction } from 'i18next';
import { IAppContext } from 'App/AppContext';
import { getDateTimeDiffMinute } from 'utils/datetime';
import { areDifferent } from 'utils/array';
import Tag from 'models/tag';
import StringValue from 'models/stringValue/stringValue';

export enum AssetState {
  Registered = 0,
  Active = 1,
  Archived = 2,
}

export default class Asset {
  assetId: number;

  id: string;

  code: string;

  externalId?: string;

  auditTrailId: number;

  ownerId?: string;

  state: AssetState;

  listId?: number;

  isPhysical: boolean;

  trans: Asset_Translation[];

  links?: ResourceLink[];

  containers?: Asset[];

  normIds?: number[];

  tagIds?: number[];

  newTags: Tag[];

  classifications?: Classification[];

  lifeCycleStart?: Date;

  lifeCycleEnd?: Date;

  primaryContainerId?: number;

  classificationGroups?: number[];

  level: number;

  transIdx: number;

  name: string;

  description?: string;

  constructor() {
    this.id = '';
    this.assetId = 0;
    this.code = '';
    this.auditTrailId = 0;
    this.state = AssetState.Active;
    this.isPhysical = false;
    this.trans = [];
    this.name = '';
    this.transIdx = -1;
    this.newTags = [];
    this.level = -1;
  }

  static getStateText(state: AssetState, t: TFunction<string[]>): string {
    switch (state) {
      case AssetState.Registered:
        return t('asset:States.Registered');
      case AssetState.Active:
        return t('asset:States.Active');
      case AssetState.Archived:
        return t('asset:States.Archived');
    }
  }

  static getChildren(all: Asset[], asset: Asset): Asset[] {
    const output: Asset[] = [];
    const children = all.filter((a) => a.containers?.some((c) => c.assetId === asset.assetId));
    output.push(...children);
    children.forEach((child) => {
      output.push(...this.getChildren(all, child).filter((c) => !output.some((o) => o.assetId === c.assetId)));
    });

    return output;
  }

  static getInheritedClassifications(all: Asset[], asset: Asset): Classification[] {
    let output: Classification[] = [];
    const children = Asset.getChildren(all, asset);

    for (let idx = 0; idx < children.length; idx++) {
      const child = children[idx];
      if (!child.classifications) continue;
      for (let cidx = 0; cidx < child.classifications.length; cidx++) {
        const classification = child.classifications[cidx];
        //check if this classification is inherited from the child to this one
        if (!Asset.isInherited(all, child, asset, classification.group)) continue;
        //check if there is already a classification in the output with the same group
        const existingClassification = output.find((c) => c.group.stringValueId === classification.group.stringValueId);
        if (existingClassification) {
          if (existingClassification.value < classification.value) {
            //replace with classification of higher value
            output = output.filter((c) => c.group.stringValueId !== existingClassification.group.stringValueId);
          } else {
            continue;
          }
        }
        //add
        const newClassification = classification.clone();
        newClassification.inherited = true;
        output.push(newClassification);
      }
    }

    return output;
  }

  static isInherited(all: Asset[], child: Asset, asset: Asset, classificationGroup: StringValue): boolean {
    const assets = Asset.getInherited(all, child, classificationGroup);

    return assets.some((a) => a.assetId === asset.assetId);
  }

  static getInherited(all: Asset[], child: Asset, classificationGroup: StringValue): Asset[] {
    const output: Asset[] = [];
    child.containers?.forEach((container) => {
      if (container.classificationGroups?.includes(classificationGroup.stringValueId)) {
        output.push(container);
        const newChild = all.find((a) => a.assetId === container.assetId);
        if (newChild) {
          const newOutput = Asset.getInherited(all, newChild, classificationGroup);
          output.push(...newOutput);
        }
      }
    });

    return output;
  }

  static getAssetsToAddAsContainer(
    all: Asset[],
    existing: Asset[] | undefined,
    parent: Asset | undefined,
    disallow: boolean,
  ): Asset[] {
    if (!parent) return [];
    //0. filter the asset to relate the containers to
    //1. filter all containers that are already related
    //2. filter all children
    const children = Asset.getChildren(all, parent);
    const filteredAssets = all.filter((e) => {
      if (e.assetId === parent.assetId) return disallow;

      if (
        existing?.some((r) => {
          return r.assetId === e.assetId;
        })
      ) {
        return disallow;
      }

      //also filter children
      if (children.some((c) => c.assetId === e.assetId)) {
        return disallow;
      }

      return !disallow;
    });

    return filteredAssets;
  }

  getStateText(t: TFunction<string[]>): string {
    return Asset.getStateText(this.state, t);
  }

  getListTypeText(showListName: boolean, appContext: IAppContext, t: TFunction<string[]>): string {
    if (this.listId && this.listId > 0) {
      const list = appContext.globalDataCache.lists.get(this.listId);
      if (list && list.listId >= 0) {
        if (showListName) {
          return list.name;
        } else {
          return t('asset:Types.ListLink');
        }
      } else {
        return t('asset:Types.ListRemoved');
      }
    } else {
      return t('asset:Types.NoList');
    }
  }

  isEqual(item: Asset) {
    if (item.assetId !== this.assetId) return false;
    if (item.code !== this.code) return false;
    if (item.externalId !== this.externalId) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (item.auditTrailId !== this.auditTrailId) return false;
    if (item.state !== this.state) return false;
    if (!item.lifeCycleStart !== !this.lifeCycleStart) return false;
    if (
      item.lifeCycleStart &&
      this.lifeCycleStart &&
      getDateTimeDiffMinute(item.lifeCycleStart, this.lifeCycleStart) !== 0
    ) {
      return false;
    }
    if (!item.lifeCycleEnd !== !this.lifeCycleEnd) return false;
    if (item.lifeCycleEnd && this.lifeCycleEnd && getDateTimeDiffMinute(item.lifeCycleEnd, this.lifeCycleEnd) !== 0) {
      return false;
    }
    if (item.isPhysical !== this.isPhysical) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.listId !== this.listId) return false;
    if (item.primaryContainerId !== this.primaryContainerId) return false;

    if (
      areDifferent(item.tagIds, this.tagIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.normIds, this.normIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.links, this.links, (a: ResourceLink, b: ResourceLink) => {
        return a.linkId === b.linkId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.containers, this.containers, (a: Asset, b: Asset) => {
        if (a.assetId !== b.assetId) return false;

        if (
          areDifferent(a.classificationGroups, b.classificationGroups, (a: number, b: number) => {
            return a === b;
          }) === true
        ) {
          return false;
        }

        return true;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.classifications, this.classifications, (a: Classification, b: Classification) => {
        return a.classificationId === b.classificationId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.classificationGroups, this.classificationGroups, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.newTags, this.newTags, (a: Tag, b: Tag) => {
        return a.tagId === b.tagId;
      }) === true
    ) {
      return false;
    }

    return true;
  }

  clone(): Asset {
    const output = new Asset();
    output.assetId = this.assetId;
    output.id = this.id;
    output.code = this.code;
    output.externalId = this.externalId;
    output.ownerId = this.ownerId;
    output.auditTrailId = this.auditTrailId;
    output.state = this.state;
    output.lifeCycleStart = this.lifeCycleStart;
    output.lifeCycleEnd = this.lifeCycleEnd;
    output.isPhysical = this.isPhysical;
    output.name = this.name;
    output.description = this.description;
    output.listId = this.listId;
    output.transIdx = this.transIdx;
    output.trans = [...this.trans];
    output.links = this.links ? [...this.links] : undefined;
    output.containers = this.containers?.map((c) => c.clone());
    output.classifications = this.classifications ? [...this.classifications] : undefined;
    output.classificationGroups = this.classificationGroups ? [...this.classificationGroups] : undefined;
    output.normIds = this.normIds ? [...this.normIds] : undefined;
    output.tagIds = this.tagIds ? [...this.tagIds] : undefined;
    output.newTags = [...this.newTags];
    output.primaryContainerId = this.primaryContainerId;

    return output;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }

  static getEmptyAsset(code: string, cache: GlobalDataCache): Asset {
    const output = new Asset();
    output.code = code;
    output.state = AssetState.Registered;
    let trans = new Asset_Translation();
    output.trans.push(trans);
    output.transIdx = 0;

    return output;
  }
}
