import Language from '../language';
import User from '../user';
import RecurringPattern from '../recurringPattern';
import Control from '../control';
import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import {
  addDateTimeHours,
  addDateTimeDays,
  setNewDateKeepTime,
  getDateTimeDiffDays,
  getDateTimeDiffMinute,
  addDateTimeMinutes,
  setNewTimeKeepDate,
} from 'utils/datetime';
import { newGuid, newGuidNil } from 'utils/guid';
import { globalTaskDefaultDeadline, globalTaskDefaultDuration } from 'globalConstants';
import Risk from '../risk';
import WorkingHoursPattern from '../WorkingHoursPattern';
import UserLanguage from '../userLanguage';
import { areDifferent } from 'utils/array';
import Theme from '../theme';
import { TFunction } from 'i18next';
import Objective from '../objective/objective';
import { isEmpty } from 'utils/string';
import AppError from 'utils/appError';
import Process from '../process/process';
import AuditTrail from '../auditTrail';
import { TaskTask } from './taskTask';
import KPIData from 'models/kpi/kpiData';
import { SystemTaskTypes } from './taskType';
import Asset from 'models/asset/asset';
import { ApprovalState } from 'models/approval';
import { IAppContext } from 'App/AppContext';

//
// Task
//
export enum TaskTypes {
  Normal = 0,
  Monitoring = 1,
  Template = 2,
  Event = 3,
  EventTemplate = 4,
}

export enum TaskWorkflowStatus {
  NotSet = 0,
  Success = 1,
  Failure = 2,
}

export default class Task {
  taskId: number;

  taskMasterId: number | null;

  name: string;

  description?: string;

  taskStateId: number;

  taskType: TaskTypes;

  taskTypeId?: number;

  systemTaskType: SystemTaskTypes;

  sortOrder: number;

  creatorId: string;

  created: Date;

  creator?: User;

  completed?: Date;

  userId?: string;

  user?: User;

  commentTrailId: number;

  auditTrailId: number;

  duration: number;

  startDateTime: Date;

  endDateTime: Date;

  checkList: TaskCheckList;

  eventId?: string;

  followUp: boolean;

  recurringPattern: RecurringPattern;

  recurringPatternSummary?: string;

  deadline: Date;

  taskStates: TaskState[];

  controls: Control[];

  processes: Process[];

  objectives: Objective[];

  assets: Asset[];

  themes: Theme[];

  instances?: Task[];

  instancesEnabled?: boolean;

  approved?: ApprovalState;

  hidden?: boolean;

  webhookStatus?: TaskWorkflowStatus;

  templateId?: number;

  relations?: TaskTask[];

  risks: Risk[];

  workingHours: WorkingHoursPattern;

  tagIds?: number[];

  resourceLinkIds?: number[];

  auditTrail?: AuditTrail;

  kpiData?: KPIData[];

  //internal props
  id?: string;

  //static props
  static completedState: number | undefined = undefined;

  constructor() {
    this.taskId = -1; //this must be -1 for new tasks (check in UI)
    this.taskMasterId = null;
    this.name = '';
    this.taskStateId = 0;
    this.sortOrder = 0;
    this.creatorId = '';
    this.created = new Date();
    this.commentTrailId = 0; //this must be 0 for new tasks. -1 means the tenant general log
    this.auditTrailId = 0; //this must be 0 for new tasks. -1 means the tenant general log
    this.startDateTime = setNewTimeKeepDate(new Date(), 9, 0);
    this.endDateTime = addDateTimeHours(this.startDateTime, 1);
    this.duration = globalTaskDefaultDuration;
    this.deadline = addDateTimeDays(this.endDateTime, globalTaskDefaultDeadline);
    this.checkList = new TaskCheckList();
    this.recurringPattern = new RecurringPattern();
    this.followUp = false;
    this.taskType = TaskTypes.Normal;
    this.workingHours = new WorkingHoursPattern();
    this.taskStates = [];
    this.tagIds = [];
    this.controls = [];
    this.themes = [];
    this.objectives = [];
    this.processes = [];
    this.risks = [];
    this.assets = [];
    this.userId = undefined;
    this.systemTaskType = SystemTaskTypes.None;
    this.instancesEnabled = true;
  }

  static getEmptyTaskWithStates = (states: TaskState[]) => {
    const output = new Task();
    output.taskStates = states.map((_) => _.clone());
    if (output.taskStates.length > 0) output.taskStateId = output.taskStates[0].taskStateId;

    return output;
  };

  getTypeText(t: TFunction<string[]>): string {
    switch (this.taskType) {
      case TaskTypes.Normal:
        return t('task:Types.Normal');
      case TaskTypes.Template:
        return t('task:Types.Template');
      case TaskTypes.Monitoring:
        return t('task:Types.Monitoring');
      case TaskTypes.Event:
        return t('task:Types.Event');
      case TaskTypes.EventTemplate:
        return t('task:Types.EventTemplate');
    }
  }

  getTypeString(): string {
    return this.taskType.toString();
  }

  isCompleted(): boolean {
    return this.taskStateId === this.getCompletedState();
  }

  isAssigned(): boolean {
    return this.userId !== undefined && this.userId !== null && this.userId !== newGuidNil();
  }

  isScheduled(): boolean {
    return this.eventId !== undefined && this.eventId != null;
  }

  //an instance of a task is a task that is an instance of a series task or template task
  //it has a taskMasterId set to the taskId of the series task
  isInstance(): boolean {
    return this.taskMasterId === null ? false : this.taskMasterId !== 0;
  }

  //a series task acts like the template for new instances and controls the scheduling and assignment
  //it has the taskMasterId set to zero
  isSeries(): boolean {
    return this.taskMasterId === null ? false : this.taskMasterId === 0;
  }

  //a non recurring task is a 'normal' task that is not related to any other task
  //it has the taskMasterId set to null
  isNonRecurring(): boolean {
    return this.taskMasterId === null;
  }

  getDeadline(): Date {
    return this.taskType === TaskTypes.Normal && !this.isNonRecurring()
      ? this.workingHours.getDeadline(this.endDateTime, this.duration)
      : this.deadline;
  }

  isOverDeadline(deadline: Date, refDate: Date): boolean {
    const hours = this.workingHours.getHoursBetween(refDate, deadline);

    return hours > 0;
  }

  getHoursOverDeadline(refDate: Date): number {
    const deadline: Date = this.getDeadline();
    const hours = this.workingHours.getHoursBetween(refDate, deadline);

    return hours;
  }

  hasInstances(): boolean {
    return this.instances !== undefined && this.instances.length > 0;
  }

  getShowinstances(): boolean {
    return (this.instances && this.instances.length > 0) || this.instancesEnabled === true;
  }

  getScore(): number {
    const completedState = this.getCompletedState();

    if (this.taskStateId !== completedState || this.completed === undefined) {
      return 0;
    }

    let score: number = 100;

    let hoursOverDeadLine = this.getHoursOverDeadline(this.completed);

    if (hoursOverDeadLine > 30) hoursOverDeadLine = 30;
    if (hoursOverDeadLine < 0) hoursOverDeadLine = 0;

    score -= (hoursOverDeadLine / 30) * 30;

    const totalItems = this.checkList.items.length;
    if (totalItems > 0) {
      const unSuccesFullItems = this.checkList.getTodoOrFailedCount();
      score -= (unSuccesFullItems / totalItems) * 30;
    }

    return score;
  }

  getCompletedState(): number {
    if (Task.completedState === undefined) {
      if (this.taskStates) {
        Task.completedState = this.taskStates.find((t) => t.completed === true)?.taskStateId;
        if (!Task.completedState) {
          return -1;
        }
      } else {
        return -1;
      }
    }

    return Task.completedState;
  }

  getFirstState(): number {
    if (!this.taskStates || this.taskStates.length === 0) return 0;
    this.taskStates?.sort((a, b) => a.sortOrder - b.sortOrder);

    return this.taskStates[0].taskStateId;
  }

  setComplete() {
    this.taskStateId = this.getCompletedState();
    this.completed = new Date();
  }

  setNewStartDate(newDate: Date) {
    //this applies a new start date to the task
    //- starttime and end times are kept
    //- startdate is applied to the recurring pattern
    //- deadline is moved relative to the new start date (time is kept)
    const deadlineDays = getDateTimeDiffDays(this.deadline, this.startDateTime);
    this.startDateTime = setNewDateKeepTime(newDate, this.startDateTime);
    this.endDateTime = setNewDateKeepTime(newDate, this.endDateTime);
    this.recurringPattern.startDate = this.startDateTime;
    const newDeadlineDate = addDateTimeDays(this.startDateTime, deadlineDays);
    this.deadline = setNewDateKeepTime(newDeadlineDate, this.deadline);
  }

  clone(): Task {
    const newTask: Task = new Task();

    newTask.taskId = this.taskId;
    newTask.taskStateId = this.taskStateId;
    newTask.sortOrder = this.sortOrder;
    newTask.taskMasterId = this.taskMasterId;
    newTask.taskType = this.taskType;
    newTask.taskTypeId = this.taskTypeId;
    newTask.systemTaskType = this.systemTaskType;
    newTask.auditTrailId = this.auditTrailId;
    newTask.commentTrailId = this.commentTrailId;
    newTask.checkList = this.checkList.clone();
    newTask.name = this.name;
    newTask.description = this.description;
    newTask.startDateTime = this.startDateTime;
    newTask.endDateTime = this.endDateTime;
    newTask.deadline = this.deadline;
    newTask.duration = this.duration;
    newTask.followUp = this.followUp;
    newTask.userId = this.userId;
    newTask.user = this.user ? this.user.clone() : undefined;
    newTask.recurringPattern = this.recurringPattern.clone();
    newTask.eventId = this.eventId;
    newTask.controls = this.controls?.map((c) => c.clone());
    newTask.themes = this.themes?.map((t) => t.clone());
    newTask.processes = this.processes?.map((t) => t.clone());
    newTask.objectives = this.objectives?.map((t) => t.clone());
    newTask.relations = this.relations ? [...this.relations] : undefined;
    newTask.risks = this.risks?.map((t) => t.clone());
    newTask.assets = this.assets?.map((t) => t.clone());
    newTask.taskStates = [...this.taskStates];
    newTask.created = this.created;
    newTask.creatorId = this.creatorId;
    newTask.creator = this.creator ? this.creator.clone() : undefined;
    newTask.completed = this.completed;
    newTask.workingHours = this.workingHours.clone();
    newTask.tagIds = this.tagIds ? [...this.tagIds] : undefined;
    newTask.instances = this.instances?.map((c) => c.clone());
    newTask.instancesEnabled = this.instancesEnabled;
    newTask.hidden = this.hidden;
    newTask.approved = this.approved;
    newTask.resourceLinkIds = this.resourceLinkIds ? [...this.resourceLinkIds] : undefined;
    newTask.kpiData = this.kpiData?.map((k) => k.clone());
    newTask.templateId = this.templateId;
    newTask.webhookStatus = this.webhookStatus;
    newTask.id = this.id;

    return newTask;
  }

  isEqual(item: Task) {
    if (item.taskId !== this.taskId) return false;
    if (item.id !== this.id) return false;
    if (item.taskStateId !== this.taskStateId) return false;
    if (item.sortOrder !== this.sortOrder) return false;
    if (item.taskMasterId !== this.taskMasterId) return false;
    if (item.taskType !== this.taskType) return false;
    if (item.taskTypeId !== this.taskTypeId) return false;
    if (item.auditTrailId !== this.auditTrailId) return false;
    if (item.commentTrailId !== this.commentTrailId) return false;
    if (item.checkList.isEqual(this.checkList) === false) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (getDateTimeDiffMinute(item.startDateTime, this.startDateTime) !== 0) return false;
    if (getDateTimeDiffMinute(item.endDateTime, this.endDateTime) !== 0) return false;
    if (getDateTimeDiffMinute(item.deadline, this.deadline) !== 0) return false;
    if (item.duration !== this.duration) return false;
    if (item.followUp !== this.followUp) return false;
    if (item.userId !== this.userId) return false;
    if (item.recurringPattern.isEqual(this.recurringPattern) === false) return false;
    if (item.eventId !== this.eventId) return false;
    if (item.created !== this.created) return false;
    if (item.creatorId !== this.creatorId) return false;
    if (item.instancesEnabled !== this.instancesEnabled) return false;
    if (item.hidden !== this.hidden) return false;
    if (item.approved !== this.approved) return false;

    // 1. do not compare the completed property: this can only be changed by an admin and is saved directly
    // this would lead to a change here that is already saved.
    // 2. do not compare resource links because they are saved directly in the TaskLinkList

    if (
      areDifferent(item.tagIds, this.tagIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.resourceLinkIds, this.resourceLinkIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.controls, this.controls, (a: Control, b: Control) => {
        return a.controlId === b.controlId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.themes, this.themes, (a: Theme, b: Theme) => {
        return a.themeId === b.themeId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.objectives, this.objectives, (a: Objective, b: Objective) => {
        return a.objectiveId === b.objectiveId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.processes, this.processes, (a: Process, b: Process) => {
        return a.processId === b.processId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.risks, this.risks, (a: Risk, b: Risk) => {
        return a.riskId === b.riskId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.assets, this.assets, (a: Asset, b: Asset) => {
        return a.assetId === b.assetId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.kpiData, this.kpiData, (a: KPIData, b: KPIData) => {
        return a.isEqual(b);
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.instances, this.instances, (a: Task, b: Task) => {
        return a.isEqual(b);
      }) === true
    ) {
      return false;
    }

    return true;
  }

  updateWillRecreateInstances(oldTask: Task) {
    //date changes will, time changes not
    const oldTaskStartDate = new Date(oldTask.startDateTime);
    oldTaskStartDate.setHours(0, 0, 0, 0);
    const newTaskStartDate = new Date(this.startDateTime);
    newTaskStartDate.setHours(0, 0, 0, 0);

    if (getDateTimeDiffDays(oldTaskStartDate, newTaskStartDate) !== 0) {
      return true;
    }

    //recurring pattern changes will
    if (oldTask.recurringPattern.isEqual(this.recurringPattern) === false) {
      return true;
    }

    return false;
  }

  isEqualToSeries(item: Task) {
    //the goal here is to determine whether the user has made manual changes to an instance
    //when true, we should ask the user whether he/she wants to overwrite these changes when updateWillRecreateInstances == true
    if (item.taskStateId !== this.taskStateId) return false;
    if (item.taskType !== this.taskType) return false;
    if (item.checkList.isEqual(this.checkList) === false) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.duration !== this.duration) return false;
    if (item.followUp !== this.followUp) return false;

    if (
      areDifferent(item.tagIds, this.tagIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.resourceLinkIds, this.resourceLinkIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.controls, this.controls, (a: Control, b: Control) => {
        return a.controlId === b.controlId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.themes, this.themes, (a: Theme, b: Theme) => {
        return a.themeId === b.themeId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.objectives, this.objectives, (a: Objective, b: Objective) => {
        return a.objectiveId === b.objectiveId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.processes, this.processes, (a: Process, b: Process) => {
        return a.processId === b.processId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.risks, this.risks, (a: Risk, b: Risk) => {
        return a.riskId === b.riskId;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.assets, this.assets, (a: Asset, b: Asset) => {
        return a.assetId === b.assetId;
      }) === true
    ) {
      return false;
    }

    return true;
  }

  setNewStartTime = (hours: number, mins: number) => {
    const duration = getDateTimeDiffMinute(this.endDateTime, this.startDateTime);

    let newDate = new Date(this.startDateTime);
    newDate.setHours(hours);
    newDate.setMinutes(mins);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    this.startDateTime = newDate;
    this.endDateTime = addDateTimeMinutes(this.startDateTime, duration);
    this.recurringPattern.startDate = this.startDateTime;

    //when this is a new task, calculate a new default deadline
    if (this.taskId <= 0) {
      this.deadline = addDateTimeDays(this.startDateTime, globalTaskDefaultDeadline);
    }
  };

  setNewEndTime = (hours: number, mins: number) => {
    const newDate = new Date(this.startDateTime);
    newDate.setHours(hours);
    newDate.setMinutes(mins);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    this.endDateTime = newDate;

    //when this is a new task, calculate a new default deadline
    if (this.taskId <= 0) {
      this.deadline = addDateTimeDays(this.startDateTime, globalTaskDefaultDeadline);
    }
  };

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema = Joi.object({
      assignment: Joi.string().guid().optional().label(localizedFields['assignment']),
      startDateTime: Joi.date().required().label(localizedFields['startDateTime']),
      endDateTime: Joi.date().required().min(Joi.ref('startDateTime')).label(localizedFields['endDateTime']),
      deadline: Joi.date().min(Joi.ref('endDateTime')).label(localizedFields['deadline']),
      duration: Joi.number().required().min(0),
      taskStateId: Joi.number().required(),
      name: Joi.string().required().max(512).label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    const objToValidate = {
      assignment: this.userId || undefined,
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      deadline: this.taskType === TaskTypes.Normal && !this.isNonRecurring() ? undefined : this.deadline,
      duration: this.duration,
      taskStateId: this.taskStateId,
      name: this.name,
    };

    return schema.validate(objToValidate, { abortEarly: false });
  }

  applyTemplate(template: Task, appContext: IAppContext): Task {
    try {
      //some prechecks
      if (template.taskType !== TaskTypes.Template && template.taskType !== TaskTypes.EventTemplate) {
        throw new AppError('Cannot apply a task that is not a template');
      }

      //start with cloning the complete task
      const output = this.clone();
      output.taskStates = appContext.globalDataCache.taskStates.items;

      //set the task id of the template on the created task
      output.templateId = template.taskId;

      //apply each property in the template when it's not empty
      if (!isEmpty(template.name)) output.name = template.name;
      if (!isEmpty(template.description)) output.description = template.description;
      if (template.recurringPattern.isActive) output.recurringPattern = template.recurringPattern.clone();
      if (template.followUp === true) output.followUp = true;
      if (template.isAssigned()) output.userId = template.userId;
      if (template.checkList.items.length > 0) output.checkList.items.push(...template.checkList.items);
      if (template.taskTypeId) output.taskTypeId = template.taskTypeId;
      if (template.instancesEnabled !== undefined) output.instancesEnabled = template.instancesEnabled;

      //add the related entities
      output.controls = template.controls.map((c) => c.clone());
      output.themes = template.themes.map((c) => c.clone());
      output.objectives = template.objectives.map((c) => c.clone());
      output.processes = template.processes.map((c) => c.clone());
      output.risks = template.risks.map((c) => c.clone());
      output.assets = template.assets.map((c) => c.clone());

      //add tags
      if (template.tagIds) {
        if (!output.tagIds) output.tagIds = [];
        for (let tagId of template.tagIds) {
          if (!output.tagIds.includes(tagId)) {
            output.tagIds.push(tagId);
          }
        }
      }

      //add links
      if (template.resourceLinkIds) {
        if (!output.resourceLinkIds) output.resourceLinkIds = [];
        for (let linkId of template.resourceLinkIds) {
          if (!output.resourceLinkIds.includes(linkId)) {
            output.resourceLinkIds.push(linkId);
          }
        }
      }

      if (this.taskType === TaskTypes.Monitoring) {
        //when this is a managementtask, don't set the task type to template because a monitoring task is a sort of template itself
        //and keep the recurring pattern because its mandatory for monitoring tasks
      } else if (this.taskType === TaskTypes.Event && this.taskMasterId !== null) {
        //when this is a subtask of an event, do not apply the task template
      } else {
        if (template.taskType === TaskTypes.EventTemplate) {
          output.taskType = TaskTypes.Event;
          output.taskMasterId = 0;
          //when creating an event form a template, the created event is a series
          //that can contain instances so set taskMasterId to 0
        } else if (template.taskType === TaskTypes.Template) {
          output.taskType = TaskTypes.Normal;
          output.taskMasterId = null;
          output.instances = [];
          //when creating a task form a template, the created task does not start as a series
          //the temmplate cannot contain a recurring pattern so set taskMasterId to null
        }
      }

      //set instances from the event template
      if (template.taskType === TaskTypes.EventTemplate && output.taskType === TaskTypes.Event) {
        const templateInstances = template.instances ?? [];
        output.instances = [];

        for (let idx = 0; idx < templateInstances.length; idx++) {
          const templateInstance = templateInstances[idx];
          const instance = new Task().applyTemplate(templateInstance, appContext);
          instance.id = newGuid();
          instance.taskMasterId = output.taskId;
          instance.taskStateId = templateInstance.taskStateId;
          output.instances.push(instance);
        }
      }

      //links and context are not applied here

      return output;
    } catch (err) {
      appContext.setError(err);

      return this;
    }
  }
}

export enum TaskCheckListItemState {
  ToDo = 0,
  Success = 1,
  Failed = 2,
}

export class TaskCheckListItem {
  id: string;

  state: TaskCheckListItemState;

  description: string;

  constructor() {
    this.id = newGuid();
    this.state = TaskCheckListItemState.ToDo;
    this.description = 'New item';
  }

  isEqual(item: TaskCheckListItem) {
    if (item.description !== this.description) return false;
    if (item.state !== this.state) return false;

    return true;
  }

  getStateText(t: TFunction<string[]>) {
    if (this.state === TaskCheckListItemState.ToDo) return t('task:CheckList.Todo');
    if (this.state === TaskCheckListItemState.Failed) return t('task:CheckList.Failed');
    if (this.state === TaskCheckListItemState.Success) return t('task:CheckList.Success');

    return '';
  }
}

export class TaskCheckList {
  items: TaskCheckListItem[];

  constructor() {
    this.items = [];
  }

  toJSON(): string | undefined {
    return JSON.stringify(this.items);
  }

  fromJSON(patternString: string | undefined) {
    try {
      if (patternString && patternString !== '') {
        this.items = JSON.parse(patternString);
        let i = 0;
        while (i < this.items.length) {
          let _taskCheckListItem = new TaskCheckListItem();
          _taskCheckListItem.id = this.items[i].id ? this.items[i].id : newGuid();
          _taskCheckListItem.description = this.items[i].description;
          _taskCheckListItem.state = this.items[i].state;
          this.items[i] = _taskCheckListItem;
          i += 1;
        }
      }
    } catch (err) {
      this.items = [];
    }
  }

  clone(): TaskCheckList {
    const newTaskCheckList = new TaskCheckList();

    for (let i = 0; i < this.items.length; i++) {
      let newItem = new TaskCheckListItem();
      newItem.id = this.items[i].id;
      newItem.description = this.items[i].description;
      newItem.state = this.items[i].state;
      newTaskCheckList.items.push(newItem);
    }

    return newTaskCheckList;
  }

  isEqual(checklist: TaskCheckList) {
    if (checklist.items.length !== this.items.length) return false;
    for (let idx = 0; idx < checklist.items.length; idx++) {
      const item1 = checklist.items[idx];
      const item2 = this.items[idx];
      if (!item1.isEqual(item2)) return false;
    }

    return true;
  }

  getFailedCount() {
    return this.items.filter((_itm) => _itm.state === TaskCheckListItemState.Failed).length;
  }

  getCompletedCheckCount() {
    return this.items.filter(
      (_itm) => _itm.state === TaskCheckListItemState.Failed || _itm.state === TaskCheckListItemState.Success,
    ).length;
  }

  getTodoOrFailedCount() {
    return this.items.filter(
      (_itm) => _itm.state === TaskCheckListItemState.Failed || _itm.state === TaskCheckListItemState.ToDo,
    ).length;
  }

  getSuccesfulCount() {
    return this.items.filter((_itm) => _itm.state === TaskCheckListItemState.Success).length;
  }

  getBulletCheckList(t: TFunction<string[]>) {
    let output = '';
    this.items.forEach((item: TaskCheckListItem, i: number) => {
      output += `${i + 1}. ${item.getStateText(t)} ${item.description} \n`;
    });

    return output;
  }
}

export class TaskState {
  taskStateId: number;

  completed: boolean;

  sortOrder: number;

  taskCount?: number;

  trans: TaskState_Translation[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  state: string;

  constructor() {
    this.taskStateId = 0;
    this.completed = false;
    this.sortOrder = 1;
    this.trans = [];
    this.transIdx = -1;
    this.state = '';
  }

  getTransIdx(langCode: string, defLangCode: string): number {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        let t: TaskState_Translation = this.trans[i];
        if (t.lang?.code === langCode) {
          return i;
        }
      }

      //if not found, find the default language
      for (let i = 0; i < this.trans.length; i++) {
        let t: TaskState_Translation = this.trans[i];
        if (t.lang?.code === defLangCode) {
          return i;
        }
      }
    }

    return -1;
  }

  getLanguages(): string[] {
    const languages: string[] = [];

    if (this.trans) {
      for (let i = 0; i < this.trans.length; i++) {
        let t: TaskState_Translation = this.trans[i];
        if (t.lang) {
          languages.push(t.lang.code);
        }
      }
    }

    return languages;
  }

  getTrans(langCode: string, defLangCode: string = UserLanguage.getFallBack()): TaskState_Translation | undefined {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        let t: TaskState_Translation = this.trans[i];
        if (t.lang?.code === langCode) {
          return this.trans[i];
        }
      }

      //if not found, find the default language
      for (let i = 0; i < this.trans.length; i++) {
        let t: TaskState_Translation = this.trans[i];
        if (t.lang?.code === defLangCode) {
          return this.trans[i];
        }
      }
    }

    return undefined;
  }

  clone = (): TaskState => {
    const output = new TaskState();
    output.taskStateId = this.taskStateId;
    output.completed = this.completed;
    output.sortOrder = this.sortOrder;
    output.transIdx = this.transIdx;
    output.state = this.state;
    output.trans = this.trans.map((_) => _.clone());

    return output;
  };
}

export class TaskState_Translation {
  state: string;

  lang?: Language;

  constructor() {
    this.state = '';
  }

  clone = (): TaskState_Translation => {
    const output = new TaskState_Translation();
    output.state = this.state;
    output.lang = this.lang?.clone();

    return output;
  };
}

export class ControlTask {
  controlId: number;

  taskId: number;

  constructor() {
    this.controlId = 0;
    this.taskId = 0;
  }
}
