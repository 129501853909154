import ResourceList, { ResourceListSystemType } from 'models/resourceList';
import { apiGetLists } from 'services/Api/listService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, IEntityCache } from './entityCache';

export class ResourceListCache
  extends EntityCache<ResourceList>
  implements IEntityCache<ResourceList, ResourceListCache>
{
  private isLoading: boolean = false;

  private listsPromise: Promise<ResourceList[]> | undefined;

  itemSet: Map<number, ResourceList> = new Map<number, ResourceList>();

  has(id: number | undefined): boolean {
    let list = this.items?.find((i) => i.listId === id);
    if (!list) return false;

    return true;
  }

  get(id: number | undefined): ResourceList {
    let list = this.items?.find((i) => i.listId === id);
    if (list) {
      return list;
    }
    if (id) {
      this.addMiss();
      list = new ResourceList();
    } else {
      list = new ResourceList();
    }

    return list;
  }

  getSystem(systemType: ResourceListSystemType): ResourceList[] {
    let lists = this.items?.filter((i) => i.systemListId === systemType);
    if (lists) {
      return lists;
    } else {
      return [];
    }
  }

  add(item: ResourceList) {
    this.items.push(item);
    this.itemSet.set(item.listId, item);
  }

  update(item: ResourceList) {
    const idx = this.items.findIndex((i) => i.listId === item.listId);
    if (idx >= 0) {
      this.items[idx] = item;
      this.itemSet.set(item.listId, item);
    }
  }

  remove(item: ResourceList) {
    const idx = this.items.findIndex((i) => i.listId === item.listId);
    if (idx >= 0) {
      this.items.splice(idx, 1);
      this.itemSet.delete(item.listId);
    }
  }

  createItemSet() {
    this.items.forEach((i) => {
      this.itemSet.set(i.listId, i);
    });
  }

  getItemsForId(ids: number[] | undefined): ResourceList[] {
    if (!ids) return [];

    if (this.itemSet.size === 0) {
      this.createItemSet();
    }

    const result: ResourceList[] = [];
    ids.forEach((i) => {
      const list = this.itemSet.get(i);
      if (list) result.push(list);
    });

    return result;
  }

  async getItems(refresh: boolean = false): Promise<ResourceList[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          this.isLoading = true;
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.listsPromise = apiGetLists(accessToken);
          this.items = await this.listsPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.listsPromise = undefined;
          this.isLoading = false;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.listsPromise) {
      return await this.listsPromise;
    } else {
      return this.items;
    }
  }

  clone(): ResourceListCache {
    const listCache: ResourceListCache = new ResourceListCache();
    listCache.items = this.items?.map((u) => u.clone());

    return listCache;
  }
}
