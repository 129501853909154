import CurrentUser from 'models/currentuser';
import { TaskState } from 'models/tasks/task';
import UserLanguage from 'models/userLanguage';
import { EntityCache, IEntityCache } from './entityCache';

export class TaskStateCache extends EntityCache<TaskState> implements IEntityCache<TaskState, TaskStateCache> {
  itemSet: Map<number, TaskState> = new Map<number, TaskState>();

  has(id: number | undefined): boolean {
    let state = this.items?.find((i) => i.taskStateId === id);
    if (!state) return false;

    return true;
  }

  get(id: number | undefined): TaskState {
    let state = this.items?.find((i) => i.taskStateId === id);
    if (state) {
      return state;
    }
    if (id) {
      this.addMiss();
      state = new TaskState();
    } else {
      state = new TaskState();
    }

    return state;
  }

  add(item: TaskState) {
    this.items.push(item);
    this.itemSet.set(item.taskStateId, item);
    this.sort();
  }

  sort() {
    this.items.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  update(item: TaskState) {
    const idx = this.items.findIndex((i) => i.taskStateId === item.taskStateId);
    if (idx >= 0) {
      this.items[idx] = item;
      this.itemSet.set(item.taskStateId, item);
    }
  }

  remove(item: TaskState) {
    const idx = this.items.findIndex((i) => i.taskStateId === item.taskStateId);
    if (idx >= 0) {
      this.items.splice(idx, 1);
      this.itemSet.delete(item.taskStateId);
    }
  }

  createItemSet() {
    this.items.forEach((i) => {
      this.itemSet.set(i.taskStateId, i);
    });
  }

  getItemsForId(ids: number[] | undefined): TaskState[] {
    if (!ids) return [];

    if (this.itemSet.size === 0) {
      this.createItemSet();
    }

    const result: TaskState[] = [];
    ids.forEach((i) => {
      const list = this.itemSet.get(i);
      if (list) result.push(list);
    });

    return result;
  }

  setCurrentUserLanguage(user: CurrentUser) {
    //set the translations for the current user
    this.items.forEach((i) => {
      if (user.language.code) {
        i.transIdx = i.getTransIdx(user.language.code, UserLanguage.getFallBack());
        if (i.transIdx === -1 && i.trans.length > 0) {
          i.transIdx = 0;
        }
        if (i.transIdx >= 0) {
          i.state = i.trans[i.transIdx].state;
        }
      }
    });
    this.sort();
  }

  async getItems(refresh: boolean = false): Promise<TaskState[]> {
    //not implemented

    return this.items;
  }

  clone(): TaskStateCache {
    const taskStateCache: TaskStateCache = new TaskStateCache();
    taskStateCache.items = this.items?.map((u) => u.clone());

    return taskStateCache;
  }
}
