import Tenant from './tenant';
import Presence from './presence';
import UserLanguage from './userLanguage';
import User from './user';
import Login from './login';
import AzureTenant from './azureTenant';

export default class CurrentUser extends User {
  timeZone: string; //timeZone of the mailbox

  timeFormat: string; //timeFormat of the mailbox

  language: UserLanguage; //the current language of the user in the application

  presence: Presence;

  photo: Blob | undefined;

  photoURL: string | undefined;

  tenant: Tenant; //home tenant of the user (no org. unit)

  login: Login;

  tenants: AzureTenant[];

  accountLanguageCode: string; // the original language of the Microsoft account

  constructor(id: string, tenant: Tenant, displayName: string, email: string, language: string) {
    super(id, email, displayName); //The Id of this CurrentUser is the Azure Tenant Id

    this.tenant = tenant;
    this.name = displayName;
    this.email = email;
    this.presence = new Presence('');
    this.language = new UserLanguage(language);
    this.tenants = [];
    this.timeZone = 'UTC';
    this.timeFormat = 'HH:mm';
    this.accountLanguageCode = '';
    this.login = new Login();
  }

  static getEmptyUser() {
    return new CurrentUser('', new Tenant('', ''), '', '', '');
  }

  clone() {
    const newUser = new CurrentUser(this.id, this.tenant, this.name, this.email, this.language.code);
    newUser.created = this.created;
    newUser.lastLogin = this.lastLogin;
    newUser.hasLicense = this.hasLicense;
    newUser.timeZone = this.timeZone;
    newUser.timeFormat = this.timeFormat;
    newUser.accountLanguageCode = this.accountLanguageCode;
    newUser.photo = this.photo;
    newUser.photoURL = this.photoURL;
    newUser.presence = this.presence;
    newUser.login = this.login;
    newUser.tenants = this.tenants ? [...this.tenants] : [];
    newUser.language = this.language.clone();
    newUser.tenant = this.tenant.clone();

    return newUser;
  }

  getAvatarURL(): string | undefined {
    if (this.photo) {
      if (!this.photoURL) {
        this.photoURL = URL.createObjectURL(this.photo);
      }

      return this.photoURL;
    }

    return undefined;
  }

  getMyAccountURL() {
    return `https://myaccount.microsoft.com/`;
  }

  getMyAccountLanguageURL() {
    return `https://myaccount.microsoft.com/settingsandprivacy/language/`;
  }
}
