import { getDateTimeDiffMinute } from 'utils/datetime';
import AuthResultDTO from 'models/dto/authResultDTO';
import CurrentUser from 'models/currentuser';
import Tenant from 'models/tenant';
import UserLanguage from 'models/userLanguage';
import { apiLoginUser } from '../Api/userService';
import { i18nBase } from '../Localization/i18n';
import { AuthStateUpdate, SetGlobalDataCache } from 'App/App';
import Logger from 'services/Logging/logService';
import { apiOutlookTaskExchangeAccessTokenForGraphToken } from 'services/Api/officeAddInService';
import { Client } from '@microsoft/microsoft-graph-client';
import { overflow } from 'utils/string';

let retryGetAccessToken: number = 0;

//cache the last requested tokens and dates because Outlook Web cannot process request in parallel (error 13008)
let lastApiTokenRequestDate: Date | undefined = undefined;
let lastApiToken: string | undefined = undefined;

const ssoSilent = async (authStateUpdate: AuthStateUpdate, setGlobalDataCache: SetGlobalDataCache) => {
  try {
    authStateUpdate(undefined, undefined, true, undefined);
    Logger.debug('auth started');

    var token = await OfficeRuntime.auth.getAccessToken({ allowConsentPrompt: true, allowSignInPrompt: true });
    Logger.debug('token', token);

    if (token === undefined || token === '') {
      authStateUpdate(false, CurrentUser.getEmptyUser(), false, 'Could not get an access token from Office');
    }

    const email = Office.context.mailbox.userProfile.emailAddress;
    const name = Office.context.mailbox.userProfile.displayName;
    const language = Office.context.displayLanguage;
    const login = await apiLoginUser(token);

    if (login && login.userId && login.tenantId) {
      const currentTenant = login.orgUnits?.find((ou) => ou.id === login.tenantId);

      if (!currentTenant) {
        throw new Error(`The current tenant could not be found for the user: ${login.tenantId}`);
      }

      var usr = new CurrentUser(login.userId, new Tenant(currentTenant.id, currentTenant.name), name, email, language);
      usr.login = login;

      if (currentTenant.parentId && currentTenant.topLevelParentTenantId !== currentTenant.id) {
        usr.login.isOrgUnit = true;
        const topLevelParent = login.orgUnits?.find((ou) => ou.id === currentTenant.topLevelParentTenantId);
        if (topLevelParent) {
          usr.tenant.name = overflow(topLevelParent.name, 32) + ' | ' + overflow(usr.tenant.name, 32);
        }
      }

      //set the global data cache we got from the login api call and clear the temp cache on the login object
      setGlobalDataCache(usr.login.globalDataCache);
      usr.login.globalDataCache.clear();

      // Language setting from database overrides the Microsoft account setting
      if (usr.login.userLanguageCode) {
        usr.language = new UserLanguage(usr.login.userLanguageCode);
      }
      i18nBase.changeLanguage(usr.language.code);

      if (usr.id !== usr.login.userId) {
        // somehow, the back-end reports another userId than the frond-end. Hacked?
        throw new Error(`The user Id is not valid`);
      }

      Logger.debug('user', usr.id + ': ' + usr.name);

      if (login.userLicensed) {
        authStateUpdate(true, usr, false, undefined);
      } else {
        authStateUpdate(false, usr, false, undefined);
      }
    } else {
      Logger.debug('login', 'tenant: ' + (login?.tenantId ?? '-') + '; user: ' + (login?.userId ?? '-'));
      throw new Error(`The user details cannot be retrieved`);
    }
  } catch (err) {
    Logger.debug('Error in sso silent', err);
    authStateUpdate(false, CurrentUser.getEmptyUser(), false, err);
  }
};

const login = (authStateUpdate: AuthStateUpdate, setGlobalDataCache: SetGlobalDataCache) => {
  ssoSilent(authStateUpdate, setGlobalDataCache);
};

const logout = (authStateUpdate: AuthStateUpdate) => {
  authStateUpdate(false, CurrentUser.getEmptyUser(), false, undefined);
};

const getAccessToken = async (): Promise<string> => {
  try {
    Logger.debug('Start getAccessToken');

    //when a token was requested less then 2 minutes ago, return that token
    if (lastApiToken && lastApiTokenRequestDate && getDateTimeDiffMinute(lastApiTokenRequestDate, new Date()) >= -2) {
      Logger.debug('Returning from cache');

      return lastApiToken;
    } else {
      Logger.debug('Requesting new token');
      const token = await OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: false });
      retryGetAccessToken = 0;
      lastApiTokenRequestDate = new Date();
      lastApiToken = token;

      return token;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    Logger.debug('Error in getAccessToken', err);
    if (err.code) {
      return await handleClientSideErrors(err);
    } else {
      throw new Error(err);
    }
  }
};

const hasScopes = async (scopes: string[]): Promise<boolean> => {
  return true;
};

const getGraphToken = async (): Promise<string> => {
  try {
    const accesstoken = await getAccessToken();
    if (accesstoken) {
      Logger.debug('Exchanging token', accesstoken);
      let exchangeResponse = await exchangeAccessTokenForGraphToken(accesstoken);
      Logger.debug('Exchange response', exchangeResponse);

      //Handle MFA
      if (
        exchangeResponse.message &&
        exchangeResponse.message?.indexOf('AADSTS50076') >= -1 &&
        exchangeResponse.claims
      ) {
        Logger.debug('MFA required', exchangeResponse.claims);
        let mfaBootstrapToken = await OfficeRuntime.auth.getAccessToken({ authChallenge: exchangeResponse.claims });
        Logger.debug('MFA token', mfaBootstrapToken);
        exchangeResponse = await exchangeAccessTokenForGraphToken(mfaBootstrapToken);
      }

      if (!exchangeResponse.accessToken) {
        return await handleAADErrors(exchangeResponse);
      } else {
        return exchangeResponse.accessToken;
      }
    }

    throw new Error('AccessToken is empty');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    Logger.debug('Error in getGraphToken', err);
    throw new Error(err);
  }
};

function getGraphClient(accessToken: string) {
  const client = Client.init({
    // Use the provided access token to authenticate requests
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });

  return client;
}

const exchangeAccessTokenForGraphToken = async (token: string): Promise<AuthResultDTO> => {
  const result = await apiOutlookTaskExchangeAccessTokenForGraphToken(token);

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleClientSideErrors = async (error: any): Promise<string> => {
  switch (error.code) {
    case 13001:
      // No one is signed into Office. If the add-in cannot be effectively used when no one
      // is logged into Office, then the first call of getAccessToken should pass the
      // `allowSignInPrompt: true` option. Since this add-in does that, you should not see
      // this error.
      throw new Error('No one is signed into Office');
    case 13002:
      // Office.auth.getAccessToken was called with the allowConsentPrompt
      // option set to true. But, the user aborted the consent prompt.
      throw new Error('User aborted consent');
    case 13006:
      // Only seen in Office on the web.
      throw new Error(
        'Office on the web is experiencing a problem. Please sign out of Office, close the browser, and then start again.'
      );
    case 13008:
      // The Office.auth.getAccessToken method has already been called and
      // that call has not completed yet. Only seen in Office on the web.
      if (retryGetAccessToken <= 0) {
        retryGetAccessToken++;
        await delay(2000);

        return getAccessToken();
      } else {
        throw new Error('Office is still working on the last operation. When it completes, try this operation again.');
      }
    case 13010:
      // Only seen in Office on the web.
      throw new Error("Follow the instructions to change your browser's zone configuration.");
    default:
      // For all other errors, including 13000, 13003, 13005, 13007, 13012,
      // and 50001, fall back to non-SSO sign-in.
      return await dialogFallback();
  }
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const handleAADErrors = async (exchangeResponse: AuthResultDTO): Promise<string> => {
  if (exchangeResponse.message) {
    if (exchangeResponse.message?.indexOf('AADSTS500133') >= -1 && retryGetAccessToken <= 0) {
      //Token expired between calls from Office to Azure. Try again once.
      retryGetAccessToken++;

      return await getGraphToken();
    }
  }

  return await dialogFallback();
};

//
// Fallback Dialog
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var loginDialog: any = undefined;
//var loginDialogAccessToken: string | undefined = undefined;

const dialogFallback = async (): Promise<string> => {
  if (!loginDialog) {
    //dialog is already shown
    var url = '/fallbackAuthDialog.html';
    showLoginPopup(url);
  }

  return '';
};

// This handler responds to the success or failure message that the pop-up dialog receives from the identity provider
// and access token provider.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processMessage = (arg: any) => {
  Logger.debug('Message received in processMessage', JSON.stringify(arg));
  let message = JSON.parse(arg.message);

  if (loginDialog && message.status && message.status === 'success') {
    loginDialog.close();
    loginDialog = undefined;
    // We now have a valid access token.
    //loginDialogAccessToken = message.accessToken;
  } else {
    // Something went wrong with authentication or the authorization of the web application.
    if (loginDialog) {
      loginDialog.close();
      loginDialog = undefined;
    }
    throw new Error(
      'Unable to successfully authenticate user or authorize application. Error is: ' + message
        ? message.error
        : 'unknown'
    );
  }
};

// Use the Office dialog API to open a pop-up and display the sign-in page for the identity provider.
const showLoginPopup = (url: string) => {
  Logger.debug('Starting fallback auth');
  var fullUrl =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '') +
    url;

  // height and width are percentages of the size of the parent Office application, e.g., PowerPoint, Excel, Word, etc.
  Office.context.ui.displayDialogAsync(fullUrl, { height: 60, width: 30, promptBeforeOpen: false }, function (result) {
    Logger.debug('Dialog has initialized. Wiring up events');
    loginDialog = result.value;
    loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
  });
};

const outlookService = {
  ssoSilent: ssoSilent,
  getAccessToken: getAccessToken,
  hasScopes: hasScopes,
  getGraphToken: getGraphToken,
  logout: logout,
  login: login,
  getGraphClient: getGraphClient,
};

export default outlookService;
