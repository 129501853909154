import { SystemTaskTypes } from 'models/tasks/taskType';
import {
  mapFromLanguage,
  mapFromControls,
  mapFromThemes,
  mapToControls,
  mapToThemes,
  mapToProcesses,
  mapFromProcesses,
  mapFromObjectives,
  mapToObjectives,
  mapFromRisksArray,
  mapFromAuditTrail,
  mapToRisks,
} from './dataMapping';
import TaskDTO, { TaskStateDTO, TaskState_TranslationDTO } from 'models/dto/tasks/taskDTO';
import TasksDTO from 'models/dto/tasks/tasksDTO';
import RecurringPattern from 'models/recurringPattern';
import Task, { TaskCheckList, TaskState, TaskState_Translation, TaskTypes } from 'models/tasks/task';
import Tasks from 'models/tasks/tasks';
import {
  toApiDateTimeRequired,
  toApiDateTimeOptional,
  fromApiDateTimeOptional,
  fromApiDateTimeRequired,
  addDateTimeDays,
  fromApiDateOptional,
} from 'utils/datetime';
import { newGuidNil } from 'utils/guid';
import TaskTemplateScene, { TemplateStats } from 'models/tasks/taskTemplateScene';
import TaskTemplateSceneDTO from 'models/dto/tasks/taskTemplateSceneDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import DateRange from 'models/dateRange';
import DateRangeDTO from 'models/dto/dateRangeDTO';
import IdListDTO from 'models/dto/IdListDTO';
import { TaskTask } from 'models/tasks/taskTask';
import { TaskTaskDTO } from 'models/dto/tasks/taskTaskDTO';
import { mapToKPIData } from './kpiMapping';
import { mapFromAssets, mapToAssets } from './assetMapping';
import { ApprovalState } from 'models/approval';

export function mapToTasks(tasks: Task[]): TaskDTO[] {
  return tasks.map((task) => mapToTask(task));
}

export function mapToTask(task: Task): TaskDTO {
  const newTaskDTO: TaskDTO = new TaskDTO();

  newTaskDTO.taskId = task.taskId;
  newTaskDTO.taskStateId = task.taskStateId;
  newTaskDTO.sortOrder = task.sortOrder;
  newTaskDTO.creatorId = newGuidNil(); //creator and created are set by the back-end
  newTaskDTO.created = toApiDateTimeRequired(new Date());
  newTaskDTO.completed = toApiDateTimeOptional(task.completed);
  newTaskDTO.taskMasterId = task.taskMasterId;
  newTaskDTO.taskType = task.taskType;
  newTaskDTO.taskTypeId = task.taskTypeId;
  newTaskDTO.auditTrailId = task.auditTrailId;
  newTaskDTO.commentTrailId = task.commentTrailId;
  newTaskDTO.checkList = task.checkList.toJSON();
  newTaskDTO.name = task.name;
  newTaskDTO.description = task.description;
  newTaskDTO.startDateTime = toApiDateTimeRequired(task.startDateTime);
  newTaskDTO.endDateTime = toApiDateTimeRequired(task.endDateTime);
  newTaskDTO.duration = task.duration;
  newTaskDTO.followUp = task.followUp;
  newTaskDTO.userId = task.userId;
  newTaskDTO.recurringPattern = task.recurringPattern?.toJSON();
  newTaskDTO.recurringPatternSummary = task.recurringPatternSummary;
  newTaskDTO.deadline = toApiDateTimeOptional(task.deadline);
  newTaskDTO.eventId = task.eventId;
  newTaskDTO.controls = task.controls ? mapToControls(task.controls) : undefined;
  newTaskDTO.themes = task.themes ? mapToThemes(task.themes) : undefined;
  newTaskDTO.processes = task.processes ? mapToProcesses(task.processes) : undefined;
  newTaskDTO.objectives = task.objectives ? mapToObjectives(task.objectives) : undefined;
  newTaskDTO.assets = task.assets ? mapToAssets(task.assets) : undefined;
  newTaskDTO.risks = task.risks ? mapToRisks(task.risks) : undefined;
  newTaskDTO.tagIds = task.tagIds ? new IdListDTO(task.tagIds.map((id) => id.toString())) : undefined;
  newTaskDTO.resourceLinkIds = task.resourceLinkIds
    ? new IdListDTO(task.resourceLinkIds.map((id) => id.toString()))
    : undefined;
  newTaskDTO.relations = task.relations ? mapToTaskTasks(task.relations) : undefined;
  newTaskDTO.kpiData = mapToKPIData(task.kpiData);
  newTaskDTO.instances = uploadInstances(task);
  newTaskDTO.instancesEnabled = task.instancesEnabled;
  newTaskDTO.approved =
    task.approved === ApprovalState.Approved ? true : task.approved === ApprovalState.Rejected ? false : null;
  newTaskDTO.hidden = task.hidden;
  newTaskDTO.webhookStatus = task.webhookStatus;
  newTaskDTO.templateId = task.templateId;

  return newTaskDTO;
}

function uploadInstances(task: Task): TaskDTO[] {
  if (task.taskType === TaskTypes.Event || task.taskType === TaskTypes.EventTemplate) {
    //only upload for event types that can have actual subtasks
    //other types can have a recurring series but these instances can never be processed by the back-end
    //because they are automatically generated by the back-end
    return mapToTasksArray(task.instances);
  } else {
    return [];
  }
}

export function mapFromTasksArray(taskDTOs: TaskDTO[] | undefined, globalDataCache: GlobalDataCache): Task[] {
  if (taskDTOs === undefined || taskDTOs === null) {
    return [];
  }

  let taskList: Task[] = [];

  for (let taskDTO of taskDTOs) {
    const newTask = mapFromTask(taskDTO, globalDataCache);
    taskList.push(newTask);
  }

  return taskList;
}

export function mapToTasksArray(tasks: Task[] | undefined): TaskDTO[] {
  if (tasks === undefined || tasks === null) {
    return [];
  }

  let taskList: TaskDTO[] = [];

  for (let task of tasks) {
    const newTask = mapToTask(task);
    taskList.push(newTask);
  }

  return taskList;
}

export function mapFromTasks(tasksDTO: TasksDTO, globalDataCache: GlobalDataCache): Tasks {
  let taskCol = new Tasks();

  taskCol.tasks = mapFromTasksArray(tasksDTO.tasks, globalDataCache);
  taskCol.taskStates = globalDataCache.taskStates.items;
  taskCol.tasks.forEach((task) => (task.taskStates = taskCol.taskStates));

  return taskCol;
}

export function mapFromTask(taskDTO: TaskDTO, globalDataCache: GlobalDataCache): Task {
  const newTask: Task = new Task();

  newTask.taskId = taskDTO.taskId;
  newTask.taskStateId = taskDTO.taskStateId;
  newTask.taskStates = [...globalDataCache.taskStates.items];
  newTask.sortOrder = taskDTO.sortOrder;
  newTask.creatorId = taskDTO.creatorId;
  newTask.created = fromApiDateTimeRequired(taskDTO.created);
  newTask.completed = fromApiDateTimeOptional(taskDTO.completed);
  newTask.creator = globalDataCache.users.get(taskDTO.creatorId);
  newTask.taskMasterId = taskDTO.taskMasterId;
  newTask.taskType = taskDTO.taskType;
  newTask.taskTypeId = taskDTO.taskTypeId;
  newTask.auditTrailId = taskDTO.auditTrailId;
  newTask.commentTrailId = taskDTO.commentTrailId;
  newTask.checkList = new TaskCheckList();
  newTask.name = taskDTO.name;
  newTask.description = taskDTO.description;
  newTask.startDateTime = fromApiDateTimeRequired(taskDTO.startDateTime);
  newTask.endDateTime = fromApiDateTimeRequired(taskDTO.endDateTime);
  newTask.duration = taskDTO.duration;
  newTask.followUp = taskDTO.followUp;

  if (taskDTO.userId) {
    const assignee = globalDataCache.users.get(taskDTO.userId);
    if (assignee.hasLicense) {
      newTask.userId = taskDTO.userId;
      newTask.user = assignee;
    }
  }

  newTask.recurringPattern = new RecurringPattern();
  newTask.recurringPatternSummary = taskDTO.recurringPatternSummary;
  //deadline on the DTO is optional because it depends on whether the Recurrence pattern is active. if this is a recurring task, set the deadline start + 14 days
  newTask.deadline = fromApiDateTimeOptional(taskDTO.deadline) || addDateTimeDays(newTask.startDateTime, 14);
  newTask.eventId = taskDTO.eventId;
  newTask.controls = taskDTO.controls ? mapFromControls(taskDTO.controls, globalDataCache) : [];
  newTask.themes = taskDTO.themes ? mapFromThemes(taskDTO.themes, globalDataCache) : [];
  newTask.processes = taskDTO.processes ? mapFromProcesses(taskDTO.processes, globalDataCache) : [];
  newTask.objectives = taskDTO.objectives ? mapFromObjectives(taskDTO.objectives, globalDataCache) : [];
  newTask.assets = taskDTO.assets ? mapFromAssets(taskDTO.assets, globalDataCache) : [];
  newTask.instances = mapFromTasksArray(taskDTO.instances, globalDataCache);
  newTask.instancesEnabled = taskDTO.instancesEnabled;
  newTask.approved =
    taskDTO.approved === true
      ? ApprovalState.Approved
      : taskDTO.approved === false
      ? ApprovalState.Rejected
      : ApprovalState.Pending;
  newTask.hidden = taskDTO.hidden;
  newTask.webhookStatus = taskDTO.webhookStatus;
  newTask.templateId = taskDTO.templateId;
  newTask.risks = taskDTO.risks ? mapFromRisksArray(taskDTO.risks, globalDataCache) : [];
  newTask.tagIds = taskDTO.tagIds?.idList?.map<number>((i) => Number(i));
  newTask.resourceLinkIds = taskDTO.resourceLinkIds?.idList?.map<number>((i) => Number(i));
  newTask.auditTrail = taskDTO.auditTrail ? mapFromAuditTrail(taskDTO.auditTrail) : undefined;
  newTask.relations = taskDTO.relations ? mapFromTaskTasks(taskDTO.relations, globalDataCache) : undefined;
  newTask.systemTaskType = globalDataCache.taskTypes.getSystemTaskType(taskDTO.taskTypeId) || SystemTaskTypes.None;

  //parse the Recurrence pattern and checklist
  newTask.recurringPattern.fromJSON(taskDTO.recurringPattern);
  newTask.checkList.fromJSON(taskDTO.checkList);

  return newTask;
}

export function mapFromTaskState(taskStateDTO: TaskStateDTO): TaskState {
  const taskState = new TaskState();
  taskState.taskStateId = taskStateDTO.taskStateId;
  taskState.completed = taskStateDTO.completed;
  taskState.sortOrder = taskStateDTO.sortOrder;
  taskState.taskCount = taskStateDTO.taskCount;
  taskState.trans = taskStateDTO.trans.map((taskState_TranslationDTO: TaskState_TranslationDTO) => {
    return mapFromTaskState_Translation(taskState_TranslationDTO);
  });

  if (taskState.trans && taskState.trans.length > 0) {
    taskState.transIdx = 0;
    taskState.state = taskState.trans[0].state;
  }

  return taskState;
}

export function mapFromTaskStates(taskStateDTO: TaskStateDTO[]): TaskState[] {
  return taskStateDTO.map((_stateDTO) => {
    return mapFromTaskState(_stateDTO);
  });
}

export function mapFromTaskState_Translation(
  taskState_TranslationDTO: TaskState_TranslationDTO,
): TaskState_Translation {
  const taskState_Translation = new TaskState_Translation();
  taskState_Translation.state = taskState_TranslationDTO.state;
  taskState_Translation.lang = mapFromLanguage(taskState_TranslationDTO.lang);

  return taskState_Translation;
}

export function mapFromTaskTemplateScene(
  taskTemplateSceneDTO: TaskTemplateSceneDTO,
  globalDataCache: GlobalDataCache,
): TaskTemplateScene {
  let taskTemplateScene = new TaskTemplateScene();

  let taskList: Task[] = [];

  if (taskTemplateSceneDTO.tasks) {
    for (let taskDTO of taskTemplateSceneDTO.tasks) {
      const newTask = mapFromTask(taskDTO, globalDataCache);
      taskList.push(newTask);
    }
  }

  taskTemplateScene.tasks = taskList;

  for (let task of taskTemplateScene.tasks) {
    const stat = taskTemplateSceneDTO.templateStats[task.taskId];
    if (stat) {
      const newStat = new TemplateStats();
      newStat.count = stat.count;
      newStat.lastUsed = fromApiDateTimeRequired(stat.lastUsed);
      taskTemplateScene.templateStats[task.taskId] = newStat;
    }
  }

  return taskTemplateScene;
}

export function mapFromDateRange(dateRangeDTO: DateRangeDTO): DateRange {
  let output = new DateRange();

  output.start = fromApiDateOptional(dateRangeDTO.start);
  output.end = fromApiDateOptional(dateRangeDTO.end);

  return output;
}

//task to task relations
export const mapFromTaskTasks = (taskTasks: TaskTaskDTO[], globalCache: GlobalDataCache): TaskTask[] => {
  return taskTasks.map((t) => mapFromTaskTask(t, globalCache));
};

export const mapFromTaskTask = (taskTask: TaskTaskDTO, globalCache: GlobalDataCache): TaskTask => {
  const output = new TaskTask(taskTask.taskIdFrom, taskTask.taskIdTo, taskTask.relationship);
  output.taskFrom = taskTask.taskFrom ? mapFromTask(taskTask.taskFrom, globalCache) : undefined;
  output.taskFrom = taskTask.taskTo ? mapFromTask(taskTask.taskTo, globalCache) : undefined;

  return output;
};

export const mapToTaskTasks = (taskTasks: TaskTask[]): TaskTaskDTO[] => {
  return taskTasks.map((t) => mapToTaskTask(t));
};

export const mapToTaskTask = (taskTask: TaskTask): TaskTaskDTO => {
  const output = new TaskTaskDTO();
  output.taskIdFrom = taskTask.taskIdFrom;
  output.taskIdTo = taskTask.taskIdTo;
  output.relationship = taskTask.relationship;

  return output;
};
