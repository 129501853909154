import LoginDTO from 'models/dto/loginDTO';
import Login from 'models/login';
import { DefLanguageCode } from 'models/setting';
import { fromApiDateTimeOptional } from 'utils/datetime';
import { mapFromGlobalDataCache } from 'mappings/globalDataCacheMapping';
import { mapFromTenants } from './tenantMapping';

export function mapFromLogin(loginDTO: LoginDTO): Login {
  const newLogin = new Login();

  newLogin.tenantId = loginDTO.tenantId ?? ''; //can be undefined when tenant is not registered
  newLogin.userId = loginDTO.userId;
  newLogin.roles = loginDTO.roles;
  newLogin.permissions = loginDTO.permissions;
  newLogin.lastLogin = fromApiDateTimeOptional(loginDTO.lastLogin);
  newLogin.tenantLicensed = loginDTO.tenantLicensed;
  newLogin.userLicensed = loginDTO.userLicensed;
  newLogin.userLanguageCode = loginDTO.userLanguageCode;
  newLogin.trialStart = fromApiDateTimeOptional(loginDTO.trialStart);
  newLogin.globalDataCache = mapFromGlobalDataCache(loginDTO.globalDataCache);
  newLogin.defLanguageCode = newLogin.globalDataCache.settings.get(DefLanguageCode);
  newLogin.storeMgmtState = loginDTO.storeMgmtState;
  newLogin.subscriptionType = loginDTO.subscriptionType;
  newLogin.leadSource = loginDTO.leadSource;
  newLogin.partnerId = loginDTO.partnerId;
  newLogin.orgUnits = mapFromTenants(loginDTO.orgUnits);

  return newLogin;
}
