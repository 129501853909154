import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Image, IImageProps, ImageFit, Link } from '@fluentui/react';
import { Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import Config from 'services/Config/configService';
import { globalStackStylesNoHeightPaddingSmall, globalStackTokensGapLarge } from '../../globalStyles';
import { MsLoginButton } from 'components/Buttons/MsButtons';
import { globalWebsiteURL } from 'globalConstants';
import { navigateToExternalUrl } from 'utils/url';

interface IWelcomeProps {
  login: () => void;
}

interface IWelcomeState {}

const LicenceInfo = () => {
  const { t } = useTranslation(['outlook']);

  const onClickLearnMore = () => {
    navigateToExternalUrl(globalWebsiteURL, '', '');
  };

  return (
    <Stack>
      <Text variant="medium">{t('outlook:Welcome.LicenceText')}</Text>
      <Link onClick={() => onClickLearnMore()}>{t('outlook:Welcome.LicenceLinkText')}</Link>
    </Stack>
  );
};

const WelcomeContent: React.FunctionComponent<IWelcomeProps> = (props: IWelcomeProps) => {
  const { t } = useTranslation(['outlook']);
  const appContext = useContext(AppContext);

  const imageProps: IImageProps = {
    src: `${Config.getImageURL()}/logo_64x64.png`,
    imageFit: ImageFit.contain,
    width: 64,
    height: 64,
  };

  if (appContext.user.login.tenantId && appContext.user.login.tenantLicensed === false) {
    //user has tried to login but the tenant is not registered
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        styles={globalStackStylesNoHeightPaddingSmall}
        tokens={globalStackTokensGapLarge}
      >
        <Image {...imageProps} />
        <Text variant="large">{t('outlook:Welcome.Main')}</Text>
        <Text block variant="large">
          {t('outlook:Welcome.TenantNotRegistered')}
        </Text>
        <LicenceInfo />
      </Stack>
    );
  }

  if (appContext.user.login.userId && appContext.user.login.userLicensed === false) {
    // user has tried to login but the he/she has no license
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        styles={globalStackStylesNoHeightPaddingSmall}
        tokens={globalStackTokensGapLarge}
      >
        <Image {...imageProps} />
        <Text variant="large">{t('outlook:Welcome.Main')}</Text>
        <Text variant="mediumPlus">{t('outlook:Welcome.UserHasNoLicense1', { email: appContext.user.email })}</Text>
        <Text variant="mediumPlus">{t('outlook:Welcome.UserHasNoLicense2')}</Text>
        <LicenceInfo />
      </Stack>
    );
  }

  // Not signed in yet
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      styles={globalStackStylesNoHeightPaddingSmall}
      tokens={globalStackTokensGapLarge}
    >
      <Image {...imageProps} />
      <Text variant="large">{t('outlook:Welcome.Main')}</Text>
      <LicenceInfo />
      <MsLoginButton onClick={props.login}></MsLoginButton>
    </Stack>
  );
};

export default class Welcome extends React.Component<IWelcomeProps, IWelcomeState> {
  render() {
    return <WelcomeContent {...this.props} />;
  }
}
