import Group from 'models/group';
import { apiGetGroups } from 'services/Api/groupService';
import { apiRequest } from 'services/Auth/authConfig';
import { newGuidNil } from 'utils/guid';
import { EntityCache, IEntityCache } from './entityCache';

export class GroupCache extends EntityCache<Group> implements IEntityCache<Group, GroupCache> {
  private isLoading: boolean = false;

  private groupsPromise: Promise<Group[]> | undefined;

  has(id: string | undefined): boolean {
    let group: Group | undefined;
    if (this.items) {
      group = this.items.find((i) => i.id === id);
    }
    if (!group) return false;

    return true;
  }

  get(id: string | undefined): Group {
    if (id === newGuidNil()) {
      return Group.getEmptyGroup();
    }

    let group: Group | undefined;
    if (this.items) {
      group = this.items.find((i) => i.id === id);
    }
    if (group) {
      return group;
    }
    if (id) {
      this.addMiss();
      group = new Group(id, 'New group', '');
    } else {
      group = Group.getEmptyGroup();
    }

    return group;
  }

  async getItems(refresh: boolean = false): Promise<Group[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          this.isLoading = true;
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.groupsPromise = apiGetGroups(false, accessToken);
          this.items = await this.groupsPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.groupsPromise = undefined;
          this.isLoading = false;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.groupsPromise) {
      return await this.groupsPromise;
    } else {
      return this.items;
    }
  }

  add(item: Group) {
    this.items.push(item);
  }

  update(item: Group) {
    const idx = this.items.findIndex((i) => i.id === item.id);
    if (idx >= 0) {
      this.items[idx] = item;
    }
  }

  remove(item: Group) {
    const idx = this.items.findIndex((i) => i.id === item.id);
    if (idx >= 0) {
      this.items.splice(idx, 1);
    }
  }

  clone(): GroupCache {
    const groupCache: GroupCache = new GroupCache();
    groupCache.items = this.items?.map((g) => g.clone());

    return groupCache;
  }
}
