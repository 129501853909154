import React from 'react';
import { Stack, Label, Text, TextField, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import {
  globalStackItemStylesPaddingSceneScroll,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalTextFieldBig,
} from 'globalStyles';
import Task, { TaskState } from 'models/tasks/task';
import User from 'models/user';
import UserPicker from 'components/Pickers/UserPicker';
import TimePicker from 'components/Pickers/TimePicker';
import { useTranslation } from 'react-i18next';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import { addDateTimeDays, addDateTimeMinutes, getDateTimeDiffMinute } from 'utils/datetime';
import { globalTaskDefaultDeadline } from 'globalConstants';
import AppContext from 'App/AppContext';
import { TaskTagList } from 'components/Tasks/TaskTagList';
import Tag from 'models/tag';

interface IOutlookNormalTaskDetail {
  task: Task;
  orgTask: Task;
  getErrorMessage: (field: string) => string | undefined;
  onUpdate: (newTask: Task) => void;
  taskStates: TaskState[];
  isActionPending: boolean;
  loadRescheduleRange: () => void;
  tags: Tag[];
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
}

export const OutlookNormalTaskDetail = (props: IOutlookNormalTaskDetail) => {
  const appContext = React.useContext(AppContext);
  const { t } = useTranslation(['task', 'control', 'translation', 'dateTimeComponent']);

  const setNewDateOnTask = (task: Task, newDate: Date) => {
    //trigger the load of the valid date range from the API so validation can be done
    props.loadRescheduleRange();
    task.setNewStartDate(newDate);
  };

  const setNewStartTimeOnTask = (task: Task, hours: number, mins: number) => {
    const duration = getDateTimeDiffMinute(props.task.endDateTime, props.task.startDateTime);

    //trigger the load of the valid date range from the API so validation can be done
    props.loadRescheduleRange();

    let newDate = new Date(props.task.startDateTime);
    newDate.setHours(hours);
    newDate.setMinutes(mins);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    task.startDateTime = newDate;
    task.endDateTime = addDateTimeMinutes(task.startDateTime, duration);
    task.recurringPattern.startDate = task.startDateTime;

    //when this is a new task, calculate a new default deadline
    if (task.taskId <= 0) {
      task.deadline = addDateTimeDays(task.startDateTime, globalTaskDefaultDeadline);
    }
  };

  const setNewEndTimeOnTask = (task: Task, hours: number, mins: number) => {
    //trigger the load of the valid date range from the API so validation can be done
    props.loadRescheduleRange();

    let newDate = new Date(props.task.startDateTime);
    newDate.setHours(hours);
    newDate.setMinutes(mins);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    task.endDateTime = newDate;

    //when this is a new task, calculate a new default deadline
    if (task.taskId <= 0) {
      task.deadline = addDateTimeDays(task.startDateTime, globalTaskDefaultDeadline);
    }
  };

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Stack verticalFill tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <TextField
                underlined
                autoFocus={!appContext.isMobileView}
                value={props.task.name ? props.task.name : ''}
                styles={globalTextFieldBig}
                onGetErrorMessage={(value) => {
                  if (value === '') return '';

                  return props.getErrorMessage('name');
                }}
                placeholder={t('task:Title.Placeholder')}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  let copyTask = props.task.clone();
                  copyTask.name = newValue ? newValue : '';
                  props.onUpdate(copyTask);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <TaskTagList
                tags={props.tags}
                isLoading={props.isActionPending}
                addTagToTaskState={props.addTagToTaskState}
                removeTagFromTaskState={props.removeTagFromTaskState}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                styles={{ root: { minHeight: 100, maxHeight: '100%' } }}
                resizable={true}
                label={t('task:Description.Label')}
                placeholder={t('task:Description.Placeholder')}
                multiline
                rows={5}
                value={props.task.description ? props.task.description : ''}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  let copyTask = props.task.clone();
                  copyTask.description = newValue;
                  props.onUpdate(copyTask);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack verticalFill>
                <Label>{t('task:Assignment.Label')}</Label>
                <UserPicker
                  users={[]}
                  itemLimit={1}
                  selectedItemId={props.task.userId}
                  onSelect={(item: User | undefined) => {
                    let copyTask = props.task.clone();

                    copyTask.userId = undefined;
                    copyTask.user = undefined;

                    if (item) {
                      const user = item;
                      copyTask.userId = user.id;
                      copyTask.user = user;
                    }

                    props.onUpdate(copyTask);
                  }}
                />
                <Text variant="small" styles={{ root: { color: 'red' } }}>
                  {props.getErrorMessage('assignment')}
                </Text>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <LocalizedDatePicker
                  label={t('task:Start.Label')}
                  showGoToToday
                  minDate={props.task.taskId < 0 ? new Date() : undefined}
                  value={props.task.startDateTime}
                  onDateChange={(newDate: Date | undefined) => {
                    if (newDate) {
                      let copyTask = props.task.clone();
                      setNewDateOnTask(copyTask, newDate);
                      props.onUpdate(copyTask);
                    }
                  }}
                />
                <Text variant="small" styles={{ root: { color: 'red' } }}>
                  {props.getErrorMessage('endDateTime')}
                </Text>
                <Text variant="small" styles={{ root: { color: 'red' } }}>
                  {props.getErrorMessage('startDateTime')}
                </Text>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal tokens={globalStackTokensGapMedium}>
                <Stack.Item>
                  <TimePicker
                    selectedTime={props.task.startDateTime}
                    label={t('task:Time.Label')}
                    updateTime={(hours: number, mins: number) => {
                      let copyTask = props.task.clone();
                      setNewStartTimeOnTask(copyTask, hours, mins);
                      props.onUpdate(copyTask);
                    }}
                  />
                </Stack.Item>
                <Stack.Item>
                  <TimePicker
                    selectedTime={props.task.endDateTime}
                    label={t('task:End.Label')}
                    updateTime={(hours: number, mins: number) => {
                      let copyTask = props.task.clone();
                      setNewEndTimeOnTask(copyTask, hours, mins);
                      props.onUpdate(copyTask);
                    }}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};
