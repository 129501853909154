import { IAppContext } from 'App/AppContext';
import { SubscriptionTypes } from 'utils/subscription';
import { appRoles, appRoleSets, appRoleSetTypes } from './appRoles';
import { PermissionTypes } from 'models/rolePermission';

export enum FeatureTypes {
  OrgUnits = 1,
  Objectives = 2,
  KBTeams = 3,
  PAWorkflows = 4,
  Dashboard = 5,
  DeleteStandardElement = 6,
  TaskChangeCompletionDate = 7,
  TaskDeleteMonitoringHistory = 8,
  CustomLogo = 9,
  Assets = 10,
  AssetContainers = 11,
  Events = 12,
  KPIsAndForms = 13,
  Approvals = 14,
  TaskForm = 15,
  Organization = 16,
  Requirements = 17,
  Risks = 18,
  Controls = 19,
  AnnualPlan = 20,
  Tasks = 21,
  Library = 22,
  Standards = 23,
  Admin = 50,
  AdminUsers = 51,
  AdminSubscription = 52,
  AdminOrgUnits = 53,
  PartnerAdmin = 30,
  TenantAdmin = 300,
  TenantManagement = 301,
  TenantISOAdmin = 302,
  TenantDeleteISOStandardElement = 303,
  GenericManager = 500,
  PreviewLanguages = 1001,
  PreviewFeatures = 1002,
}

// Mapping to determine which features are available for subscription types
// Empty array means available for all
const subscriptionTypeFeatureMapping: Record<Partial<FeatureTypes>, SubscriptionTypes[]> = {
  [FeatureTypes.OrgUnits]: [SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.KBTeams]: [SubscriptionTypes.Business, SubscriptionTypes.Premium],
  [FeatureTypes.DeleteStandardElement]: [],
  [FeatureTypes.PreviewLanguages]: [SubscriptionTypes.Internal, SubscriptionTypes.Partner],
  [FeatureTypes.PreviewFeatures]: [SubscriptionTypes.Internal],
  [FeatureTypes.Dashboard]: [],
  [FeatureTypes.AdminUsers]: [],
  [FeatureTypes.AdminSubscription]: [],
  [FeatureTypes.AdminOrgUnits]: [SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.TenantAdmin]: [],
  [FeatureTypes.TenantManagement]: [],
  [FeatureTypes.TenantISOAdmin]: [],
  [FeatureTypes.GenericManager]: [],
  [FeatureTypes.TaskChangeCompletionDate]: [],
  [FeatureTypes.TaskDeleteMonitoringHistory]: [],
  [FeatureTypes.TenantDeleteISOStandardElement]: [],
  [FeatureTypes.CustomLogo]: [SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.Assets]: [SubscriptionTypes.Business, SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.AssetContainers]: [SubscriptionTypes.Premium, SubscriptionTypes.Partner],
  [FeatureTypes.PartnerAdmin]: [SubscriptionTypes.Internal, SubscriptionTypes.Partner],
  [FeatureTypes.Objectives]: [], //special action 6 months
  [FeatureTypes.PAWorkflows]: [], //special action 6 months
  [FeatureTypes.Events]: [], //special action 6 months
  [FeatureTypes.KPIsAndForms]: [], //special action 6 months
  [FeatureTypes.TaskForm]: [], //special action 6 months
  [FeatureTypes.Approvals]: [], //special action 6 months
  [FeatureTypes.Organization]: [],
  [FeatureTypes.Requirements]: [],
  [FeatureTypes.Risks]: [],
  [FeatureTypes.Controls]: [],
  [FeatureTypes.AnnualPlan]: [],
  [FeatureTypes.Tasks]: [],
  [FeatureTypes.Library]: [],
  [FeatureTypes.Standards]: [],
  [FeatureTypes.Admin]: [],
};

// Mapping of which features are available for each role
// Empty array means available for all
const roleFeatureMapping: Record<FeatureTypes, appRoles[]> = {
  [FeatureTypes.OrgUnits]: [],
  [FeatureTypes.Objectives]: [],
  [FeatureTypes.KBTeams]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.DeleteStandardElement]: appRoleSets[appRoleSetTypes.Admins],
  [FeatureTypes.PreviewLanguages]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.PreviewFeatures]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.Dashboard]: appRoleSets[appRoleSetTypes.Users],
  [FeatureTypes.AdminUsers]: [...appRoleSets[appRoleSetTypes.Admins], appRoles.LicenseManager],
  [FeatureTypes.AdminSubscription]: [...appRoleSets[appRoleSetTypes.Admins], appRoles.LicenseManager],
  [FeatureTypes.AdminOrgUnits]: appRoleSets[appRoleSetTypes.Admins],
  [FeatureTypes.TenantAdmin]: appRoleSets[appRoleSetTypes.TenantAdmins],
  [FeatureTypes.TenantManagement]: [appRoles.TenantGlobalAdmin],
  [FeatureTypes.TenantISOAdmin]: appRoleSets[appRoleSetTypes.TenantAdmins],
  [FeatureTypes.GenericManager]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.TaskChangeCompletionDate]: appRoleSets[appRoleSetTypes.Admins],
  [FeatureTypes.TaskDeleteMonitoringHistory]: appRoleSets[appRoleSetTypes.Admins],
  [FeatureTypes.TenantDeleteISOStandardElement]: [appRoles.TenantGlobalAdmin],
  [FeatureTypes.CustomLogo]: [],
  [FeatureTypes.Assets]: [],
  [FeatureTypes.AssetContainers]: [],
  [FeatureTypes.PartnerAdmin]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.PAWorkflows]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.KPIsAndForms]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.TaskForm]: [],
  [FeatureTypes.Events]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.Approvals]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.Organization]: [],
  [FeatureTypes.Requirements]: [],
  [FeatureTypes.Risks]: [],
  [FeatureTypes.Controls]: [],
  [FeatureTypes.AnnualPlan]: appRoleSets[appRoleSetTypes.Managers],
  [FeatureTypes.Tasks]: [],
  [FeatureTypes.Library]: [],
  [FeatureTypes.Standards]: [],
  [FeatureTypes.Admin]: appRoleSets[appRoleSetTypes.Managers],
};

// Mapping to determine which features are available for subscription types
// Empty array means available for all
const permissionFeatureMapping: Record<Partial<FeatureTypes>, PermissionTypes[]> = {
  [FeatureTypes.OrgUnits]: [],
  [FeatureTypes.KBTeams]: [PermissionTypes.ModuleLibrary],
  [FeatureTypes.DeleteStandardElement]: [],
  [FeatureTypes.PreviewLanguages]: [],
  [FeatureTypes.PreviewFeatures]: [],
  [FeatureTypes.Dashboard]: [],
  [FeatureTypes.AdminUsers]: [PermissionTypes.ModuleAdmin],
  [FeatureTypes.AdminSubscription]: [PermissionTypes.ModuleAdmin],
  [FeatureTypes.AdminOrgUnits]: [PermissionTypes.ModuleAdmin],
  [FeatureTypes.TenantAdmin]: [],
  [FeatureTypes.TenantManagement]: [],
  [FeatureTypes.TenantISOAdmin]: [],
  [FeatureTypes.GenericManager]: [],
  [FeatureTypes.TaskChangeCompletionDate]: [],
  [FeatureTypes.TaskDeleteMonitoringHistory]: [],
  [FeatureTypes.TenantDeleteISOStandardElement]: [],
  [FeatureTypes.CustomLogo]: [],
  [FeatureTypes.Assets]: [PermissionTypes.ModuleAssets],
  [FeatureTypes.AssetContainers]: [PermissionTypes.ModuleAssets],
  [FeatureTypes.PartnerAdmin]: [PermissionTypes.ModulePartner],
  [FeatureTypes.Objectives]: [PermissionTypes.ModuleOrg],
  [FeatureTypes.PAWorkflows]: [],
  [FeatureTypes.Events]: [],
  [FeatureTypes.KPIsAndForms]: [],
  [FeatureTypes.TaskForm]: [],
  [FeatureTypes.Approvals]: [],
  [FeatureTypes.Organization]: [PermissionTypes.ModuleOrg],
  [FeatureTypes.Requirements]: [PermissionTypes.ModuleRequirements],
  [FeatureTypes.Risks]: [PermissionTypes.ModuleRisks],
  [FeatureTypes.Controls]: [PermissionTypes.ModuleControls],
  [FeatureTypes.AnnualPlan]: [PermissionTypes.ModuleAnnualPlan],
  [FeatureTypes.Tasks]: [PermissionTypes.ModuleTasks],
  [FeatureTypes.Library]: [PermissionTypes.ModuleLibrary],
  [FeatureTypes.Standards]: [PermissionTypes.ModuleStandards],
  [FeatureTypes.Admin]: [PermissionTypes.ModuleAdmin],
};

export const hasSubscriptionFeature = (type: SubscriptionTypes, feature: FeatureTypes): boolean => {
  if (type === SubscriptionTypes.Internal) return true;
  if (type === SubscriptionTypes.Custom) return false;
  if (type === SubscriptionTypes.Container) return false;

  const supportedTypes = subscriptionTypeFeatureMapping[feature];
  if (supportedTypes.length === 0 || supportedTypes.indexOf(type) >= 0) {
    return true;
  }

  return false;
};

export const hasRoleFeature = (roles: string[] | undefined, feature: FeatureTypes): boolean => {
  const requiredRoles = roleFeatureMapping[feature];
  if (requiredRoles.length === 0) return true;

  if (!roles || roles.length === 0) {
    if (requiredRoles.includes(appRoles.User)) {
      return true;
    }
  } else {
    for (let idx = 0; idx < requiredRoles.length; idx++) {
      if (roles.includes(requiredRoles[idx])) {
        return true;
      }
    }
  }

  return false;
};

export const hasPermissionFeature = (
  roles: string[] | undefined,
  permissions: number[] | undefined,
  feature: FeatureTypes,
): boolean => {
  const requiredPermissions = permissionFeatureMapping[feature];
  if (requiredPermissions.length === 0) return true;

  //permissions currently only applies to Consultant and User roles
  //if the user has any other higher priveledges role, skip permissions checks
  if (
    roles && roles.length > 0 &&
    (roles.includes(appRoles.Manager) ||
      roles.includes(appRoles.Admin) ||
      roles.includes(appRoles.OrgAdmin) ||
      roles.includes(appRoles.TenantGlobalAdmin) ||
      roles.includes(appRoles.TenantISOAdmin))
  ) {
    return true;
  }

  //check permissions
  if (!permissions || permissions.length === 0) {
    return false;
  } else {
    for (let idx = 0; idx < requiredPermissions.length; idx++) {
      if (permissions.includes(requiredPermissions[idx])) {
        return true;
      }
    }
  }

  return false;
};

export const hasUserFeatureGenericManager = (appContext: IAppContext): boolean => {
  return hasUserFeature(appContext, FeatureTypes.GenericManager);
};

export const hasUserFeature = (appContext: IAppContext, feature: FeatureTypes): boolean => {
  if (!hasSubscriptionFeature(appContext.user.login.subscriptionType, feature)) return false;
  if (!hasRoleFeature(appContext.user.login.roles, feature)) return false;
  if (!hasPermissionFeature(appContext.user.login.roles, appContext.user.login.permissions, feature)) return false;

  return true;
};
