export enum appRoles {
  User = '0',
  Admin = '1',
  Manager = '2',
  LicenseManager = '3',
  OrgAdmin = '98',
  Consultant = '99',
  TenantISOAdmin = '998',
  TenantGlobalAdmin = '999',
}

// Handy sets of used app role combinations
export enum appRoleSetTypes {
  Users = 0,
  Managers = 1,
  Admins = 2,
  TenantAdmins = 3,
}

export const appRoleSets: Record<appRoleSetTypes, appRoles[]> = {
  [appRoleSetTypes.Users]: [
    appRoles.User,
    appRoles.Manager,
    appRoles.Consultant,
    appRoles.Admin,
    appRoles.OrgAdmin,
    appRoles.TenantGlobalAdmin,
    appRoles.TenantISOAdmin,
  ],
  [appRoleSetTypes.Managers]: [
    appRoles.Manager,
    appRoles.Consultant,
    appRoles.Admin,
    appRoles.OrgAdmin,
    appRoles.TenantGlobalAdmin,
    appRoles.TenantISOAdmin,
  ],
  [appRoleSetTypes.Admins]: [appRoles.Admin, appRoles.OrgAdmin, appRoles.TenantGlobalAdmin, appRoles.TenantISOAdmin],
  [appRoleSetTypes.TenantAdmins]: [appRoles.TenantGlobalAdmin, appRoles.TenantISOAdmin],
};
