import CurrentUser from 'models/currentuser';
import PartnerDTO from 'models/dto/partnerDTO';
import UserDTO from 'models/dto/userDTO';
import UserRoleDTO from 'models/dto/userRoleDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import Partner from 'models/partner';
import User, { Role } from 'models/user';
import { fromApiDateTimeOptional } from 'utils/datetime';

export function mapFromUsers(userDTOs: UserDTO[]): User[] {
  let userList: User[] = [];

  for (let userDTO of userDTOs) {
    const newUser = mapFromUser(userDTO);
    if (newUser) userList.push(newUser);
  }

  return userList;
}

export function mapFromUser(userDTO: UserDTO | undefined): User | undefined {
  if (userDTO) {
    const newUser = new User(userDTO.userId, userDTO.email, userDTO.name);
    newUser.created = fromApiDateTimeOptional(userDTO.created);
    newUser.hasLicense = userDTO.hasLicense;
    newUser.lastLogin = fromApiDateTimeOptional(userDTO.lastLogin);
    newUser.roles = mapFromRoles(userDTO.roles);

    return newUser;
  }

  return undefined;
}

export function mapToUser(user: User): UserDTO {
  let userDTO = new UserDTO();

  userDTO.userId = user.id;
  userDTO.name = user.name;
  userDTO.email = user.email;
  userDTO.hasLicense = user.hasLicense;

  if (user instanceof CurrentUser) {
    userDTO.userLanguageCode = (user as CurrentUser).login.userLanguageCode;
  }

  return userDTO;
}

export function mapFromUserRoles(userRoleDTOs: UserRoleDTO[], cache: GlobalDataCache): User[] {
  let userList: User[] = [];

  for (let userRoleDTO of userRoleDTOs) {
    const newUser = cache.users.get(userRoleDTO.userId);
    newUser.roles?.push(new Role(Number.parseInt(userRoleDTO.roleId)));
    userList.push(newUser);
  }

  return userList;
}

export function mapFromPartners(partnerDTOs: PartnerDTO[]): Partner[] {
  let partnerList: Partner[] = [];

  for (let partnerDTO of partnerDTOs) {
    const newPartner = mapFromPartner(partnerDTO);
    partnerList.push(newPartner);
  }

  return partnerList;
}

export function mapFromPartner(partnerDTO: PartnerDTO): Partner {
  const newPartner = new Partner();
  newPartner.tenantId = partnerDTO.tenantId;
  newPartner.tenantName = partnerDTO.tenantName;
  newPartner.userId = partnerDTO.userId;
  newPartner.name = partnerDTO.name;
  newPartner.email = partnerDTO.email;

  return newPartner;
}

export function mapFromRoles(roles: number[] | undefined): Role[] {
  let roleList: Role[] = [];
  if (roles) {
    roles.forEach((role) => {
      const newRole = new Role(role);
      roleList.push(newRole);
    });
  }

  return roleList;
}
