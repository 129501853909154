import Joi from 'joi';
import { PDCAState } from 'models/pdca';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import Task from '../tasks/task';
import { Objective_Translation } from './objective_Translation';
import { isObjectEqual } from 'utils/object';
import Group from 'models/group';
import User from 'models/user';
import Process from 'models/process/process';
import { TFunction } from 'i18next';
import { getPDCAStateText } from 'globalFunctions';
import Dashboard from 'models/dashboard';
import KPI from 'models/kpi/kpi';

export default class Objective {
  objectiveId: number;

  code: string;

  parentObjectiveId?: number;

  commentTrailId: number;

  auditTrailId: number;

  groupId?: string;

  group?: Group;

  ownerId?: string;

  owner?: User;

  state: PDCAState;

  dashboard?: Dashboard;

  tasks: Task[];

  tagIds: number[];

  processes: Process[];

  kpis: KPI[];

  trans?: Objective_Translation[];

  transIdx: number;

  name: string;

  description: string;

  constructor() {
    this.objectiveId = -1;
    this.code = '';
    this.name = '';
    this.description = '';
    this.parentObjectiveId = -1;
    this.commentTrailId = -1;
    this.auditTrailId = -1;
    this.state = PDCAState.Plan;
    this.tasks = [];
    this.tagIds = [];
    this.transIdx = -1;
    this.processes = [];
    this.kpis = [];
  }

  clone(): Objective {
    const output = new Objective();
    output.objectiveId = this.objectiveId;
    output.parentObjectiveId = this.parentObjectiveId;
    output.code = this.code;
    output.name = this.name;
    output.description = this.description;
    output.state = this.state;
    output.groupId = this.groupId;
    output.ownerId = this.ownerId;
    output.commentTrailId = this.commentTrailId;
    output.auditTrailId = this.auditTrailId;
    output.transIdx = this.transIdx;
    output.trans = this.trans ? this.trans.map((tran) => tran.clone()) : undefined;
    output.tasks = this.tasks.map((task) => task.clone());
    output.tagIds = [...this.tagIds];
    output.processes = this.processes.map((p) => p.clone());
    output.kpis = this.kpis.map((k) => k.clone());
    output.dashboard = this.dashboard?.clone();

    return output;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }

  static getObjectiveStateText = (state: PDCAState, t: TFunction<string[]>): string => {
    return getPDCAStateText(state, t);
  };

  isEqual(item: Objective) {
    if (item.objectiveId !== this.objectiveId) return false;
    if (item.parentObjectiveId !== this.parentObjectiveId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.code !== this.code) return false;
    if (item.state !== this.state) return false;
    if (item.groupId !== this.groupId) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (!isObjectEqual(item.trans, this.trans)) return false;

    return true;
  }
}
