import LoginDTO from 'models/dto/loginDTO';
import Login from 'models/login';
import UserDTO from 'models/dto/userDTO';
import IdListDTO from 'models/dto/IdListDTO';
import UserRoleDTO from 'models/dto/userRoleDTO';
import User from 'models/user';
import http from './httpService';
import {
  mapFromLogin,
  mapFromUsers,
  mapToUser,
  mapFromUserRoles,
  mapFromGroupMember,
  mapFromEntities,
  mapFromPartners,
} from 'mappings/dataMapping';
import AppError from 'utils/appError';
import { appRoles } from 'services/Auth/appRoles';
import FeedbackDTO from 'models/dto/feedbackDTO';
import { GroupMembersDTO } from 'models/dto/groupMembersDTO';
import { GroupMember } from 'models/groupMembers';
import EntityDTO from 'models/dto/entityDTO';
import Entity from 'models/entity';
import { LogDTO } from 'models/dto/logDTO';
import PartnerDTO from 'models/dto/partnerDTO';
import Partner from 'models/partner';
import Tenant from 'models/tenant';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { PermissionTypes } from 'models/rolePermission';

export async function apiSendFeedback(subject: string, msg: string, accessToken: string): Promise<void> {
  try {
    const feedback = new FeedbackDTO();
    feedback.message = msg;
    feedback.subject = subject;

    await http.post<FeedbackDTO>('/users/feedback', feedback, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiLoginUser(accessToken: string): Promise<Login | undefined> {
  try {
    const ar = await http.get<LoginDTO>('/users/login', http.getRequestConfig(accessToken));

    return mapFromLogin(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetUsers(accessToken: string): Promise<User[]> {
  try {
    const ar = await http.get<UserDTO[]>('/users', http.getRequestConfig(accessToken));

    return mapFromUsers(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetPartners(accessToken: string): Promise<Partner[]> {
  try {
    const ar = await http.get<PartnerDTO[]>('/users/partners', http.getRequestConfig(accessToken));

    return mapFromPartners(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveUsers(selectedUsers: string[], accessToken: string): Promise<void> {
  try {
    const userListDTO: IdListDTO = new IdListDTO(selectedUsers);
    await http.post<IdListDTO>('/users/delete', userListDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetLicense(
  selectedUsers: string[],
  enableLicense: boolean,
  accessToken: string,
): Promise<void> {
  try {
    const userListDTO: IdListDTO = new IdListDTO(selectedUsers);
    await http.post<IdListDTO>(
      `/users/license/?enable=${enableLicense}`,
      userListDTO,
      http.getRequestConfig(accessToken),
    );
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddUser(user: User, accessToken: string): Promise<void> {
  try {
    const userDTO = mapToUser(user);
    await http.post<UserDTO>('/users', userDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiReplaceUser(oldUser: User, newUser: User, accessToken: string): Promise<void> {
  try {
    await http.post<UserDTO>(`/users/${oldUser.id}/replace/${newUser.id}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetUsersByRole(
  roleId: appRoles,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<User[]> {
  try {
    const ar = await http.get<UserRoleDTO[]>(`/users/roles/${roleId}`, http.getRequestConfig(accessToken));

    return mapFromUserRoles(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetUserRole(
  userId: string,
  roleId: appRoles,
  add: boolean,
  accessToken: string,
): Promise<void> {
  try {
    const userRoleDTO = new UserRoleDTO();
    userRoleDTO.userId = userId;
    userRoleDTO.roleId = roleId;
    await http.post<IdListDTO>(`/users/roles/?add=${add}`, userRoleDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetRolePermission(
  roleId: appRoles,
  permissionId: PermissionTypes,
  add: boolean,
  accessToken: string,
): Promise<void> {
  try {
    await http.post<IdListDTO>(
      `/users/roles/${roleId}/permission/${permissionId}?add=${add}`,
      null,
      http.getRequestConfig(accessToken),
    );
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRolePermissions(roleId: appRoles, accessToken: string): Promise<number[]> {
  try {
    const ar = await http.get<number[]>(`/users/roles/${roleId}/permissions`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiSetRolePermissions(
  roleId: appRoles,
  permissions: number[],
  accessToken: string,
): Promise<void> {
  try {
    const idList = new IdListDTO(permissions.map((p) => p.toString()));
    await http.post(`/users/roles/${roleId}/permissions`, idList, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateUser(user: User, accessToken: string): Promise<void> {
  try {
    const userDTO = mapToUser(user);
    await http.put<UserDTO>(`/users`, userDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetGroupMembership(
  accessToken: string,
  includeMembers: boolean = true,
): Promise<GroupMember[]> {
  try {
    const res = await http.get<GroupMembersDTO[]>(
      `/users/groups?includeMembers=${includeMembers}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromGroupMember(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetUserEntityRelationInfo(userId: string, accessToken: string): Promise<Entity[]> {
  try {
    const ar = await http.get<EntityDTO[]>(`/users/${userId}/entities`, http.getRequestConfig(accessToken));

    return mapFromEntities(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddProcessLog(logDto: LogDTO, accessToken: string): Promise<void> {
  try {
    await http.post<EntityDTO[]>(`/users/processlog`, logDto, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetOrgUnitUsers(orgUnit: Tenant, accessToken: string): Promise<User[]> {
  try {
    const ar = await http.get<UserDTO[]>(`/users/orgunits/${orgUnit.id}`, http.getRequestConfig(accessToken));

    return mapFromUsers(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiReplaceAdmin(newAdminId: string, accessToken: string): Promise<void> {
  try {
    const newAdminRoleDTO: UserRoleDTO = {
      userId: newAdminId,
      roleId: appRoles.Admin,
    };

    await http.post(`/users/roles/replaceadmin`, newAdminRoleDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
