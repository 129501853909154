import User from 'models/user';
import { apiGetUsers } from 'services/Api/userService';
import { apiRequest } from 'services/Auth/authConfig';
import { newGuidNil } from 'utils/guid';
import { EntityCache, IEntityCache } from './entityCache';

export class UserCache extends EntityCache<User> implements IEntityCache<User, UserCache> {
  private isLoading: boolean = false;

  private usersPromise: Promise<User[]> | undefined;

  has(id: string | undefined): boolean {
    if (id === newGuidNil()) return false;
    let user: User | undefined;
    if (this.items) {
      user = this.items.find((i) => i.id === id);
    }
    if (!user) return false;

    return true;
  }

  get(id: string | undefined): User {
    if (id === newGuidNil()) {
      return User.getEmptyUser();
    }

    let user: User | undefined;
    if (this.items) {
      user = this.items.find((i) => i.id === id);
    }
    if (user) {
      return user;
    }
    if (id) {
      this.addMiss();
      user = new User(id, '', '?');
    } else {
      user = User.getEmptyUser();
    }

    return user;
  }

  async getItems(refresh: boolean = false): Promise<User[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          this.isLoading = true;
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.usersPromise = apiGetUsers(accessToken);
          this.items = await this.usersPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.usersPromise = undefined;
          this.isLoading = false;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.usersPromise) {
      return await this.usersPromise;
    } else {
      return this.items;
    }
  }

  getItemsForId(ids: string[] | undefined): User[] {
    if (!ids) return [];

    return this.items.filter((i) => ids.includes(i.id));
  }

  add(item: User) {
    this.items.push(item);
  }

  update(item: User) {
    const idx = this.items.findIndex((i) => i.id === item.id);
    if (idx >= 0) {
      this.items[idx] = item;
    }
  }

  remove(item: User) {
    const idx = this.items.findIndex((i) => i.id === item.id);
    if (idx >= 0) {
      this.items.splice(idx, 1);
    }
  }

  clone(): UserCache {
    const userCache: UserCache = new UserCache();
    userCache.items = this.items?.map((u) => u.clone());

    return userCache;
  }
}
