import ISOControl from 'models/isoControl';
import Tag from 'models/tag';
import Task from 'models/tasks/task';
import { extractAlpha, extractNumber, startsWithNumber } from './string';

export const sortOnCode = (value1: string, value2: string): number => {
  //1. items that start with a number before items that start with alpha
  //2. if the string starts with a number part, extract the number and compare on that. if equal, extract the alpha part and compare on that
  //3. if the string starts with a alpha part, extract the alpha part, compare on that. If equal, compare on the number part
  const sn1 = startsWithNumber(value1);
  const sn2 = startsWithNumber(value2);

  //sort items that start with a number before items that start with alpha
  if (sn1 !== sn2) {
    if (sn1 === true) return -1;

    return 1;
  }

  if (sn1) {
    //starts with number
    const ca = extractNumber(value1);
    const cb = extractNumber(value2);
    const r1 = cb - ca;

    if (r1 === 0) {
      const aa = extractAlpha(value1);
      const ab = extractAlpha(value2);

      return aa.localeCompare(ab);
    } else {
      return cb > ca ? -1 : 1;
    }
  } else {
    //starts with alpha
    const aa = extractAlpha(value1);
    const ab = extractAlpha(value2);

    const r1 = aa.localeCompare(ab);
    if (r1 === 0) {
      const ca = extractNumber(value1);
      const cb = extractNumber(value2);

      if (ca === cb) return 0;

      return cb > ca ? -1 : 1;
    } else {
      return r1;
    }
  }
};

export const sortOnChapter = (value1: string, value2: string): number => {
  //1. split the chapter on .
  //2. sort on each part
  const sn1 = value1.split('.');
  const sn2 = value2.split('.');

  const max = Math.max(sn1.length, sn2.length);

  for (let idx = 0; idx < max; idx++) {
    const p1 = sn1[idx];
    const p2 = sn2[idx];

    if (!p1) return -1;
    if (!p2) return 1;

    const v1 = Number.parseInt(p1);
    const v2 = Number.parseInt(p2);
    if (isNaN(v1) && !isNaN(v2)) return 1;
    if (isNaN(v2) && !isNaN(v1)) return -1;

    if (isNaN(v1) && isNaN(v2)) {
      //compare as text
      const result = p1.localeCompare(p2);
      if (result !== 0) return result;
    } else {
      //compare as number
      const result = v1 - v2;
      if (result !== 0) return result;
    }
  }

  return 0;
};

export const sortOnArray = <T>(a1: T[] | undefined, a2: T[] | undefined): number => {
  if (a1 === undefined && a2 !== undefined) return -1;
  if (a1 !== undefined && a2 === undefined) return 1;

  if (a1 && a2) {
    if (a1.length !== a2.length) {
      return a1.length - a2?.length;
    }
  }

  return 0;
};

export const sortOnTag = (tag1: Tag, tag2: Tag): number => {
  const r1 = tag1.tagName.localeCompare(tag2.tagName);
  if (r1 === 0) {
    return tag1.tagValue.localeCompare(tag2.tagValue);
  } else {
    return r1;
  }
};

export const sortOnDate = (t1: Date | undefined, t2: Date | undefined): number => {
  if (t1 === t2) return 0;

  if (t1 === undefined && t2 !== undefined) return -1;
  if (t1 !== undefined && t2 === undefined) return 1;

  if (t1 && t2) {
    if (t1 < t2) return -1;
  }

  return 1;
};

export const sortOnNumber = (b1: number | undefined, b2: number | undefined): number => {
  if (b1 === b2) return 0;
  if (b1 !== undefined && b2 === undefined) return -1;
  if (b1 === undefined && b2 !== undefined) return 1;
  if (b1 === undefined || b2 === undefined) return 0; //cannot happen but for type checking

  return b1 - b2;
};

export const sortOnString = (b1: string | undefined, b2: string | undefined): number => {
  if (b1 === b2) return 0;
  if (b1 && !b2) return -1;
  if (!b1 && b2) return 1;
  if (!b1 || !b2) return 0; //cannot happen but for type checking

  return b1?.localeCompare(b2);
};

export const sortOnBoolean = (b1: boolean, b2: boolean): number => {
  if (b1 === b2) return 0;
  if (b1 === true && b2 === false) return -1;

  return 1;
};

export const sortOnISOControl = (c1: ISOControl, c2: ISOControl): number => {
  if (c1.isoNormId === c2.isoNormId) {
    return sortOnCode(c1.code, c2.code);
  } else {
    return (c1.isoNormId || 0) - (c2.isoNormId || 0);
  }
};

export const sortOnTaskTags = (a: Task, b: Task): number => {
  const tagCountA = a.tagIds?.length || 0;
  const tagCountB = b.tagIds?.length || 0;

  if (tagCountA === 1 && tagCountB === 1 && a.tagIds && b.tagIds) {
    return a.tagIds[0] - b.tagIds[0];
  } else {
    return tagCountA - tagCountB;
  }
};

export const sortOnTaskContext = (a: Task, b: Task): number => {
  let entityCountA = a.risks ? 1 : 0;
  entityCountA += a.controls.length;
  entityCountA += a.themes.length;

  let entityCountB = b.risks ? 1 : 0;
  entityCountB += b.controls.length;
  entityCountB += b.themes.length;

  if (entityCountA === 1 && entityCountB === 1) {
    let codeNameA = '';
    let codeNameB = '';
    if (a.risks && a.risks.length > 0) {
      codeNameA = codeNameA + ` ${a.risks[0].code} ${a.risks[0].name}`;
    }
    if (a.controls && a.controls.length > 0) {
      codeNameA = codeNameA + ` ${a.controls[0].code} ${a.controls[0].name}`;
    }
    if (a.themes && a.themes.length > 0) {
      codeNameA = codeNameA + ` ${a.themes[0].code} ${a.themes[0].name}`;
    }
    if (a.processes && a.processes.length > 0) {
      codeNameA = codeNameA + ` ${a.processes[0].code} ${a.processes[0].name}`;
    }
    if (a.objectives && a.objectives.length > 0) {
      codeNameA = codeNameA + ` ${a.objectives[0].code} ${a.objectives[0].name}`;
    }
    if (a.assets && a.assets.length > 0) {
      codeNameA = codeNameA + ` ${a.assets[0].code} ${a.assets[0].name}`;
    }

    if (b.risks && b.risks.length > 0) {
      codeNameB = codeNameB + ` ${b.risks[0].code} ${b.risks[0].name}`;
    }
    if (b.controls && b.controls.length > 0) {
      codeNameB = codeNameB + ` ${b.controls[0].code} ${b.controls[0].name}`;
    }
    if (b.themes && b.themes.length > 0) {
      codeNameB = codeNameB + ` ${b.themes[0].code} ${b.themes[0].name}`;
    }
    if (b.processes && b.processes.length > 0) {
      codeNameB = codeNameB + ` ${b.processes[0].code} ${b.processes[0].name}`;
    }
    if (b.objectives && b.objectives.length > 0) {
      codeNameB = codeNameB + ` ${b.objectives[0].code} ${b.objectives[0].name}`;
    }
    if (b.assets && b.assets.length > 0) {
      codeNameB = codeNameB + ` ${b.assets[0].code} ${b.assets[0].name}`;
    }

    return codeNameA.localeCompare(codeNameB);
  } else {
    return entityCountA - entityCountB;
  }
};
