import Task, { TaskState } from './task';

export default class Tasks {
  tasks: Task[];

  taskStates: TaskState[];

  constructor() {
    this.tasks = [];
    this.taskStates = [];
  }
}
