import Task, { TaskWorkflowStatus } from './tasks/task';
import ResourceLink, { ResourceLinkVersion } from './resourceLink';
import { TFunction } from 'i18next';
import { getDateTimeDiffMinute } from 'utils/datetime';
import { sortOnDate, sortOnNumber } from 'utils/sorting';
import Activity from './activity';

export enum ApprovalState {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
  Concept = 3,
}

export class Approval {
  link: ResourceLink | undefined;

  mainTask: Task;

  approvalTasks: Task[];

  constructor() {
    this.mainTask = new Task();
    this.approvalTasks = [];
  }

  workflowActive(): boolean {
    return this.mainTask.webhookStatus === TaskWorkflowStatus.Success;
  }

  getMainStatus(): ApprovalMainStatus {
    let mainStatus = new ApprovalMainStatus();
    mainStatus.templateId = this.mainTask.templateId;

    //set deadline except for concept state
    if (this.mainTask.taskStateId !== 6) {
      mainStatus.deadline = this.mainTask.getDeadline();
    }

    if (!this.workflowActive()) {
      //no workflow has been started

      //check if this is a concept
      if (this.mainTask.taskStateId === 6) {
        mainStatus.status = ApprovalState.Concept;
        mainStatus.date = this.mainTask.created;
      } else {
        //the main status is based on the sub-statusses: everyone must approve
        const details = this.getDetailStatus();
        let approvedCount: number = 0;
        let maxApprovedDate: Date = new Date(0);

        for (let idx = 0; idx < details.length; idx++) {
          const detail = details[idx];
          if (detail.status === ApprovalState.Rejected) {
            mainStatus.status = ApprovalState.Rejected;
            mainStatus.date = detail.date;

            return mainStatus;
          } else if (detail.status === ApprovalState.Approved) {
            approvedCount++;
            if (detail.date && getDateTimeDiffMinute(detail.date, maxApprovedDate) > 0) {
              maxApprovedDate = detail.date;
            }
          }
        }

        if (approvedCount === details.length) {
          mainStatus.status = ApprovalState.Approved;
          mainStatus.date = maxApprovedDate;
        } else {
          mainStatus.status = ApprovalState.Pending;
          mainStatus.date = this.mainTask.created;
        }
      }
    } else {
      //a workflow has been started
      //the workflow sets the final state on the main task
      mainStatus.status = this.mainTask.approved ?? ApprovalState.Pending;
      mainStatus.date = this.mainTask.completed ?? this.mainTask.created;
    }

    return mainStatus;
  }

  getUniqueUsers(): string[] {
    //all distinct users of all tasks
    let userIds: string[] = this.approvalTasks.filter((t) => t.userId).map((t) => t.userId as string);
    userIds = [...new Set(userIds)];

    return userIds;
  }

  getDetailStatus(): ApprovalDetailStatus[] {
    let details: ApprovalDetailStatus[] = [];

    for (let idx = 0; idx < this.approvalTasks.length; idx++) {
      const task = this.approvalTasks[idx];
      if (task.isAssigned()) {
        const status = new ApprovalDetailStatus();
        status.userId = task.userId ?? '';
        status.status =
          this.mainTask.taskStateId === 6 ? ApprovalState.Concept : task.approved ?? ApprovalState.Pending;
        status.date = task.completed ?? task.created;

        details.push(status);
      }
    }

    return details;
  }

  static getStatusText(status: ApprovalState | undefined, t: TFunction<string[]>): string {
    switch (status) {
      case ApprovalState.Approved:
        return t('library:ApprovalTasks.States.Approved');
      case ApprovalState.Rejected:
        return t('library:ApprovalTasks.States.Rejected');
      case ApprovalState.Pending:
        return t('library:ApprovalTasks.States.Pending');
      default:
        return t('library:ApprovalTasks.States.Concept');
    }
  }
}

export class ApprovalMainStatus {
  status: ApprovalState;

  date: Date | undefined;

  deadline: Date | undefined;

  templateId: number | undefined;

  constructor() {
    this.status = ApprovalState.Pending;
  }
}

export class ApprovalDetailStatus {
  userId: string;

  status: ApprovalState;

  date: Date | undefined;

  constructor() {
    this.userId = '';
    this.status = ApprovalState.Pending;
    this.date = new Date();
  }
}

export class ApprovalVersion {
  version: string;

  date: Date;

  activity: Activity | undefined;

  constructor() {
    this.version = '';
    this.date = new Date();
  }

  static sortOnApprovalVersion = (v1: ApprovalVersion, v2: ApprovalVersion): number => {
    if (v1.date == null && v2.date == null) return 0;
    if (v1.date == null) return 1;
    if (v2.date == null) return -1;

    const result = sortOnDate(v1.date, v2.date) * -1;

    //when the date is equal, the approval record must be sorted above the version record for merging
    if (result === 0) {
      if (!v1.version && v2.version) {
        return 1;
      } else if (v1.version && !v2.version) {
        return -1;
      }
    }

    return result;
  };

  static getApprovalVersions = (versions: ResourceLinkVersion[], activities: Activity[]): ApprovalVersion[] => {
    const approvalVersions: ApprovalVersion[] = [];

    versions.forEach((v) => {
      const newApprovalVersion = new ApprovalVersion();
      newApprovalVersion.version = v.version;
      newApprovalVersion.date = v.date;
      approvalVersions.push(newApprovalVersion);
    });

    activities.forEach((a) => {
      const newApprovalVersion = new ApprovalVersion();
      newApprovalVersion.date = a.created;
      newApprovalVersion.activity = a;
      approvalVersions.push(newApprovalVersion);
    });

    approvalVersions.sort((a, b) => ApprovalVersion.sortOnApprovalVersion(a, b));
    const approvalVersionsToRemove: number[] = [];

    //merge approvals with versions
    for (let idx = 0; idx < approvalVersions.length; idx++) {
      const approvalVersion = approvalVersions[idx];
      let latestApprovalIdx = -1;
      if (!approvalVersion.version) {
        //when this is an approval (and not a version)
        //-remember this index
        //-skip to the next version
        latestApprovalIdx = idx++;
        while (idx < approvalVersions.length && !approvalVersions[idx].version) {
          approvalVersionsToRemove.push(idx++);
        }

        if (idx < approvalVersions.length) {
          approvalVersions[idx].activity = approvalVersions[latestApprovalIdx].activity;
          approvalVersionsToRemove.push(latestApprovalIdx);
        }
      }
    }

    //remove the approvals that were done 'in between': we only want to see the latest approval for a version in this report
    approvalVersionsToRemove.sort((a, b) => -1 * sortOnNumber(a, b));
    for (let idx = 0; idx < approvalVersionsToRemove.length; idx++) {
      approvalVersions.splice(approvalVersionsToRemove[idx], 1);
    }

    return approvalVersions;
  };
}
