import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Image, IImageProps, ImageFit, FontIcon, PrimaryButton } from '@fluentui/react';
import { Stack } from '@fluentui/react';
import Config from 'services/Config/configService';
import {
  globalStackStylesNoHeightPaddingSmall,
  globalStackTokensGapLarge,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  rightArrowIcon,
} from 'globalStyles';

interface IFirstRunProps {
  onStart: Function;
}

const FirstRun: React.FunctionComponent<IFirstRunProps> = (props: IFirstRunProps) => {
  const { t } = useTranslation(['translation', 'outlook']);

  const imageProps: IImageProps = {
    src: `${Config.getImageURL()}/logo_64x64.png`,
    imageFit: ImageFit.contain,
    width: 64,
    height: 64,
  };

  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      styles={globalStackStylesNoHeightPaddingSmall}
      tokens={globalStackTokensGapLarge}
    >
      <Image {...imageProps} />
      <Text variant="xxLarge">{t('outlook:Enroll.Welcome')}</Text>
      <Text variant="large">{t('outlook:Welcome.Main')}</Text>
      <Text variant="large">{t('outlook:Enroll.Intro')}</Text>

      <Stack tokens={globalStackTokensGapMedium}>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <FontIcon {...rightArrowIcon} />
          <Text variant="medium">{t('outlook:Enroll.Sub1')}</Text>
        </Stack>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <FontIcon {...rightArrowIcon} />
          <Text variant="medium">{t('outlook:Enroll.Sub2')}</Text>
        </Stack>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <FontIcon {...rightArrowIcon} />
          <Text variant="medium">{t('outlook:Enroll.Sub3')}</Text>
        </Stack>
      </Stack>

      <PrimaryButton onClick={() => props.onStart()}>{t('outlook:Enroll.ButtonStart')}</PrimaryButton>
    </Stack>
  );
};

export default FirstRun;
