import Entity, { EntityTypes } from 'models/entity';
import logger from 'services/Logging/logService';
import Config from 'services/Config/configService';

export const getEntityUrl = (entity: Entity): string => {
  let url: string = '';

  switch (entity.typeOfEntity) {
    case EntityTypes.Theme:
      url = `/theme/${entity.entityId}/details`;
      break;
    case EntityTypes.Process:
      url = `/process/${entity.entityId}/details`;
      break;
    case EntityTypes.Risk:
      url = `/risk/${entity.entityId}/details`;
      break;
    case EntityTypes.Control:
      url = `/control/${entity.entityId}/details`;
      break;
    case EntityTypes.Objective:
      url = `/objective/${entity.entityId}/details`;
      break;
    case EntityTypes.Task:
      url = `/tasks/${entity.entityId}`;
      break;
    case EntityTypes.Asset:
      url = `/asset/${entity.entityId}/details`;
      break;
    case EntityTypes.KPI:
      url = `/kpi/${entity.entityId}/data`;
      break;
    case EntityTypes.Link:
      url = entity.data ?? '';
      break;
    default:
      break;
  }

  return url;
};

export const navigateToExternalEntity = (entity: Entity, tenantId: string, orgUnitId: string) => {
  const url = getEntityUrl(entity);
  navigateToExternalUrl(url, tenantId, orgUnitId);
};

export function getExternalUrl(url: string, azureTenantId: string, orgUnitId: string): string {
  try {
    if (azureTenantId) {
      if (!url.includes('?')) {
        url = url + `?tid=${azureTenantId}`;
      } else {
        url = url + `&tid=${azureTenantId}`;
      }
      if (orgUnitId) {
        url = url + `&ouid=${orgUnitId}`;
      }

      //add base URL if needed for navigation from other apps (e.g. Teams)
      const baseUrl = Config.get('App.BaseURL');
      if (!url.startsWith(baseUrl)) {
        url = baseUrl + url;
      }
    }

    //decode first to make sure we don't encode twice
    let decodedUrl = decodeURIFromHtml(url);
    if (!decodedUrl) return '';
    url = encodeURI(decodedUrl);

    return url;
  } catch (err) {
    logger.debug('getExternalUrl', err);

    return url;
  }
}

export function decodeURIFromHtml(url: string | undefined): string {
  try {
    if (!url) return '';

    let decodedUrl = decodeURI(url);

    //ampersand is not decoded but SharePoint can report such an Url with & encoded as %26 when a file name contains &
    decodedUrl = decodedUrl.replaceAll('%26', '&');
    //also, '&amp;' is not decoded and must be replaced
    decodedUrl = decodedUrl.replace(/&amp;/g, '&');

    return decodedUrl;
  } catch (err) {
    logger.debug('decodeURIFromHtml', err);

    return url || '';
  }
}

export function navigateToExternalUrl(
  url: string,
  azureTenantId: string,
  orgUnitId: string,
  shouldOpenNewTab: boolean = true,
) {
  //when you want to navigate to an ISOPlanner url, set tenantId and orgUnitsId, otherwise pass empty strings
  url = getExternalUrl(url, azureTenantId, orgUnitId);

  if (shouldOpenNewTab) {
    window.open(url, '_blank', 'noreferrer');
  } else {
    window.location.href = url;
  }
}

export function GetCurrentURL(onlyLastPart: boolean) {
  const path = window.location.pathname;

  if (onlyLastPart) {
    return path.substring(path.lastIndexOf('/'));
  } else {
    return path;
  }
}

export function getCurrentURLWithoutLastPart(): string {
  const url = GetCurrentURL(false);

  let parts = url.split('/');
  parts.pop();

  return parts.join('/');
}

export function getURLLastPart(url: string): string | undefined {
  let parts = url.split('/');

  return parts.pop();
}

export function getURLWithoutLastPart(url: string): string | undefined {
  let parts = url.split('/');
  parts.pop();

  return parts.join('/');
}

export function getFilenameFromURL(url: string): string | undefined {
  try {
    const file = url.split('#').shift()?.split('?').shift()?.split('/').pop();

    return file ? decodeURI(file) : '';
  } catch (err) {
    logger.debug('getFilenameFromURL', err);

    return '';
  }
}

export function getRelativeURL(url: string): string {
  const a = document.createElement('a');
  a.href = url;

  return a.pathname;
}

export function validateURL(str: string): boolean {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  return !!pattern.test(str);
}

export function removeUrlHash() {
  // eslint-disable-next-line no-restricted-globals
  history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
}

export function removeUrlQuery() {
  // eslint-disable-next-line no-restricted-globals
  history.replaceState('', document.title, window.location.origin + window.location.pathname);
}

export function getTeamsChannelUrl(channelId: string, tenantId: string) {
  let url = encodeURI(`https://teams.microsoft.com/l/team/${channelId}/conversations&tenantId=${tenantId}`);
  url = url.replaceAll('@', '%40'); //channels contain @ which must be encoded for teams

  return url;
}
