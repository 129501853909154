import CurrentUser from 'models/currentuser';
import TaskType, { SystemTaskTypes } from 'models/tasks/taskType';
import UserLanguage from 'models/userLanguage';
import { EntityCache, IEntityCache } from './entityCache';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetTaskTypes } from 'services/Api/taskService';

export class TaskTypeCache extends EntityCache<TaskType> implements IEntityCache<TaskType, TaskTypeCache> {
  private isLoading: boolean = false;

  private taskTypePromise: Promise<TaskType[]> | undefined;

  itemSet: Map<number, TaskType> = new Map<number, TaskType>();

  has(id: number | undefined): boolean {
    let state = this.items?.find((i) => i.taskTypeId === id);
    if (!state) return false;

    return true;
  }

  get(id: number | undefined): TaskType {
    let state = this.items?.find((i) => i.taskTypeId === id);
    if (state) {
      return state;
    }
    if (id) {
      this.addMiss();
      state = new TaskType();
    } else {
      state = new TaskType();
    }

    return state;
  }

  getSystem(systemType: SystemTaskTypes): TaskType | undefined {
    const taskType = this.items.find((i) => i.systemTaskType === systemType);
    if (taskType) {
      return taskType;
    } else {
      return undefined;
    }
  }

  getTypeOfSystemType(systemTaskType: SystemTaskTypes): TaskType | undefined {
    const taskType = this.items.find((i) => i.systemTaskType === systemTaskType);
    if (taskType) {
      return taskType;
    } else {
      return undefined;
    }
  }

  getSystemTaskType(taskTypeId: number | undefined): SystemTaskTypes | undefined {
    if (!taskTypeId) return undefined;
    const taskType = this.items.find((i) => i.taskTypeId === taskTypeId);
    if (taskType && taskType.systemTaskType) {
      return taskType.systemTaskType;
    } else {
      return undefined;
    }
  }

  add(item: TaskType) {
    this.items.push(item);
    this.itemSet.set(item.taskTypeId, item);
  }

  update(item: TaskType) {
    const idx = this.items.findIndex((i) => i.taskTypeId === item.taskTypeId);
    if (idx >= 0) {
      this.items[idx] = item;
      this.itemSet.set(item.taskTypeId, item);
    }
  }

  remove(item: TaskType) {
    const idx = this.items.findIndex((i) => i.taskTypeId === item.taskTypeId);
    if (idx >= 0) {
      this.items.splice(idx, 1);
      this.itemSet.delete(item.taskTypeId);
    }
  }

  createItemSet() {
    this.items.forEach((i) => {
      this.itemSet.set(i.taskTypeId, i);
    });
  }

  getItemsForId(ids: number[] | undefined): TaskType[] {
    if (!ids) return [];

    if (this.itemSet.size === 0) {
      this.createItemSet();
    }

    const result: TaskType[] = [];
    ids.forEach((i) => {
      const list = this.itemSet.get(i);
      if (list) result.push(list);
    });

    return result;
  }

  setCurrentUserLanguage(user: CurrentUser) {
    //set the translations for the current user
    this.items.forEach((i) => {
      if (user.language.code) {
        i.transIdx = i.getTransIdx(user.language.code, UserLanguage.getFallBack());
        if (i.transIdx === -1 && i.trans.length > 0) {
          i.transIdx = 0;
        }
        if (i.transIdx >= 0) {
          i.name = i.trans[i.transIdx].name;
          i.description = i.trans[i.transIdx].description;
        }
      }
    });
  }

  async getItems(refresh: boolean = false): Promise<TaskType[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.taskTypePromise = apiGetTaskTypes(accessToken);
          this.isLoading = true;
          this.items = await this.taskTypePromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.isLoading = false;
          this.taskTypePromise = undefined;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.taskTypePromise) {
      return await this.taskTypePromise;
    } else {
      return this.items;
    }
  }

  clone(): TaskTypeCache {
    const taskStateCache: TaskTypeCache = new TaskTypeCache();
    taskStateCache.items = this.items?.map((u) => u.clone());

    return taskStateCache;
  }
}
