import { apiGetNorms } from 'services/Api/normService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, IEntityCache } from './entityCache';
import Norm from 'models/norm';

export class NormCache extends EntityCache<Norm> implements IEntityCache<Norm, NormCache> {
  private isLoading: boolean = false;

  private normPromise: Promise<Norm[]> | undefined;

  itemSet: Map<number, Norm> = new Map<number, Norm>();

  has(id: number | undefined): boolean {
    let norm = this.items?.find((i) => i.normId === id);
    if (!norm) return false;

    return true;
  }

  hasISO(id: number | undefined): boolean {
    let norm = this.items?.find((i) => i.isoNormId === id);
    if (!norm) return false;

    return true;
  }

  get(id: number | undefined): Norm {
    let norm = this.items?.find((i) => i.normId === id);
    if (norm) {
      return norm;
    }
    if (id) {
      this.addMiss();
      norm = new Norm();
    } else {
      norm = new Norm();
    }

    return norm;
  }

  getISO(id: number | undefined): Norm {
    let norm = this.items?.find((i) => i.isoNormId === id);
    if (norm) {
      return norm;
    }
    if (id) {
      this.addMiss();
      norm = new Norm();
    } else {
      norm = new Norm();
    }

    return norm;
  }

  add(item: Norm) {
    this.items.push(item);
    this.itemSet.set(item.normId, item);
  }

  update(item: Norm) {
    const idx = this.items.findIndex((i) => i.normId === item.normId);
    if (idx >= 0) {
      this.items[idx] = item;
      this.itemSet.set(item.normId, item);
    }
  }

  remove(item: Norm) {
    const idx = this.items.findIndex((i) => i.normId === item.normId);
    if (idx >= 0) {
      this.items.splice(idx, 1);
      this.itemSet.delete(item.normId);
    }
  }

  getItemsForId(ids: number[] | undefined): Norm[] {
    if (!ids) return [];

    this.items.forEach((i) => {
      this.itemSet.set(i.normId, i);
    });

    const result: Norm[] = [];
    ids.forEach((i) => {
      const norm = this.itemSet.get(i);
      if (norm) {
        result.push(norm);
      } else {
        result.push(new Norm());
      }
    });

    return result;
  }

  async getItems(refresh: boolean = false): Promise<Norm[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.normPromise = apiGetNorms(undefined, true, accessToken);
          this.isLoading = true;
          this.items = await this.normPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.isLoading = false;
          this.normPromise = undefined;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.normPromise) {
      return await this.normPromise;
    } else {
      return this.items;
    }
  }

  getItemsCustom(): Norm[] {
    return this.items.filter((n) => !n.isoNormId);
  }

  getItemsISO(): Norm[] {
    return this.items.filter((n) => n.isoNormId);
  }

  clone(): NormCache {
    const normCache: NormCache = new NormCache();
    normCache.items = this.items?.map((u) => u.clone());

    return normCache;
  }
}
