import GroupDTO from 'models/dto/groupDTO';
import { GroupMembersDTO } from 'models/dto/groupMembersDTO';
import { GroupMember } from 'models/groupMembers';
import Group from 'models/group';
import { TeamsChannel } from 'models/teamsChannel';
import { TeamsChannelDTO } from 'models/dto/teamsChannelDTO';

export function mapToGroups(group: Group[]): GroupDTO[] {
  return group.map((groupItem) => {
    return mapToGroup(groupItem);
  });
}

export function mapFromGroups(groupDTO: GroupDTO[]): Group[] {
  try {
    return groupDTO.map((groupDTOItem) => {
      return mapFromGroup(groupDTOItem) as Group;
    });
  } catch (err) {
    return [];
  }
}

export function mapFromGroup(groupDTO: GroupDTO | undefined): Group | undefined {
  if (!groupDTO) {
    return undefined;
  }

  return new Group(groupDTO.groupId, groupDTO.name, groupDTO.email);
}

export function mapToGroup(group: Group): GroupDTO {
  return new GroupDTO(group.id, group.name, group.email);
}

export function mapFromSingleGroupMember(member: GroupMembersDTO): GroupMember {
  const output = new GroupMember();
  output.groupId = member.groupId;
  output.members = [...member.members];

  return output;
}

export function mapFromGroupMember(member: GroupMembersDTO[]): GroupMember[] {
  return member.map((_info) => mapFromSingleGroupMember(_info));
}

export function mapFromTeamChannels(channelDTOs: TeamsChannelDTO[]): TeamsChannel[] {
  return channelDTOs.map((channel) => mapFromTeamChannel(channel));
}

export function mapFromTeamChannel(channelDTO: TeamsChannelDTO): TeamsChannel {
  const output = new TeamsChannel();
  output.channelId = channelDTO.channelId;
  output.name = channelDTO.name;

  return output;
}
