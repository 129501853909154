import { appRoles } from 'services/Auth/appRoles';

export default class User {
  id: string;

  name: string;

  email: string;

  created?: Date;

  lastLogin?: Date;

  hasLicense: boolean = false;

  roles?: Role[];

  constructor(id: string, email: string, name: string) {
    this.id = id;
    this.name = name;
    this.email = email;
  }

  clone() {
    const newUser = new User(this.id, this.email, this.name);
    newUser.created = this.created;
    newUser.lastLogin = this.lastLogin;
    newUser.hasLicense = this.hasLicense;
    newUser.roles = this.roles ? [...this.roles] : undefined;

    return newUser;
  }

  static getEmptyUser() {
    return new User('', '', '');
  }

  hasPaidLicense = (): boolean => {
    if (!this.hasLicense) return false;

    if (this.roles) {
      for (let idx = 0; idx < this.roles.length; idx++) {
        const role = this.roles[idx];
        if (role.id.toString() === appRoles.Admin || role.id.toString() === appRoles.Manager) {
          return true;
        }
      }
    }

    return false;
  };
}

export class Role {
  id: appRoles;

  constructor(id: number) {
    this.id = id as unknown as appRoles;
  }

  name(id?: string): string {
    const indexOfS = Object.values(appRoles).indexOf((id ?? this.id) as appRoles);

    return Object.keys(appRoles)[indexOfS];
  }
}
