import Theme_TranslationDTO from './theme_TranslationDTO';
import IdListDTO from './IdListDTO';
import TaskDTO from 'models/dto/tasks/taskDTO';
import { PDCAState } from 'models/pdca';
import EntityDTO from './entityDTO';
import ISOControlDTO from './isoControlDTO';

export default class ThemeDTO {
  themeId: number;

  code: string;

  parentThemeId?: number;

  recurringPattern?: string;

  state: PDCAState;

  groupId?: string;

  ownerId?: string;

  commentTrailId: number;

  auditTrailId: number;

  customNormIds?: IdListDTO;

  isoControlIds?: IdListDTO;

  isoNormIds?: IdListDTO;

  trans?: Theme_TranslationDTO[];

  tasks?: TaskDTO[];

  monitoringParent?: EntityDTO;

  tagIds?: IdListDTO;

  isoControls?: ISOControlDTO[];

  constructor() {
    this.themeId = 0;
    this.code = '';
    this.commentTrailId = 0;
    this.auditTrailId = 0;
    this.state = PDCAState.Plan;
  }
}

export class ControlTaskStatsDTO {
  themeId: number;

  taskTotalCount: number;

  taskCompletedCount: number;

  constructor() {
    this.themeId = 0;
    this.taskTotalCount = 0;
    this.taskCompletedCount = 0;
  }
}

export class ThemeTaskStatsDTO {
  themeId: number;

  taskTotalCount: number;

  taskCompletedCount: number;

  constructor() {
    this.themeId = 0;
    this.taskTotalCount = 0;
    this.taskCompletedCount = 0;
  }
}
