import SettingDTO from 'models/dto/settingDTO';
import Setting, { Settings } from 'models/setting';
import http from './httpService';
import { mapFromSettings, mapToSetting, mapFromSetting, mapFromSettingToUserSetting } from 'mappings/dataMapping';
import AppError from 'utils/appError';
import UserSetting from 'models/userSetting';

export async function apiGetSettings(accessToken: string): Promise<Settings> {
  try {
    const ar = await http.get<SettingDTO[]>('/settings', http.getRequestConfig(accessToken));

    return mapFromSettings(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetSetting(setting: string, accessToken: string): Promise<Setting> {
  try {
    const ar = await http.get<SettingDTO>(`/settings/${setting}`, http.getRequestConfig(accessToken));

    return mapFromSetting(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetSettingTopLevelTenant(setting: string, accessToken: string): Promise<Setting> {
  try {
    const ar = await http.get<SettingDTO>(`/settings/${setting}/top`, http.getRequestConfig(accessToken));

    return mapFromSetting(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function apiSetSetting(setting: string, value: any, accessToken: string): Promise<Setting> {
  try {
    const newSetting = new Setting();
    newSetting.setValue(setting, value);
    const settingDTO = mapToSetting(newSetting);
    const ar = await http.put<SettingDTO>('/settings', settingDTO, http.getRequestConfig(accessToken));

    return mapFromSetting(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function apiSetUserSetting(setting: string, value: any, accessToken: string): Promise<UserSetting> {
  try {
    const newSetting = new UserSetting();
    newSetting.setValue(setting, value);
    const settingDTO = mapToSetting(newSetting);
    const ar = await http.put<SettingDTO>('/settings/user', settingDTO, http.getRequestConfig(accessToken));

    return mapFromSettingToUserSetting(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//
// Tenant admin
//
export async function apiGetTenantSetting(tenantId: string, setting: string, accessToken: string): Promise<Setting> {
  try {
    const ar = await http.get<SettingDTO>(
      `/settings/${setting}/tenant/${tenantId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromSetting(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
