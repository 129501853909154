import { Client } from '@microsoft/microsoft-graph-client';
import AppError from 'utils/appError';
import { Site } from 'microsoft-graph';
import logger from 'services/Logging/logService';

//
// Sharepoint Sites
//
export const graphGetSiteRoot = async (client: Client): Promise<Site> => {
  try {
    const site = await client.api(`/sites/root`).get();

    return site;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetSite = async (client: Client, siteId: string): Promise<Site> => {
  try {
    const site = await client.api(`/sites/${siteId}`).get();

    return site;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetSiteByWebUrl = async (client: Client, webUrl: string): Promise<Site | undefined> => {
  try {
    let relUrl = webUrl.replace('/sites/', ':/sites/');
    if (relUrl.startsWith('https://')) relUrl = relUrl.substring(8);
    const site = await client.api(`/sites/${relUrl}`).get();

    return site;
  } catch (err) {
    return undefined;
  }
};


export const graphGetSites = async (client: Client, text: string): Promise<Site[]> => {
  try {
    let relSite;
    let allSites;
    let relSiteError: boolean = false;
    let allSitesError: boolean = false;

    try {
      allSites = await client.api(`/sites?search=*${text}*`).get();
    } catch (err) {
      allSitesError = true;
    }

    try {
      const root = await graphGetSiteRoot(client);
      if (root) {
        const host = root.siteCollection?.hostname;
        if (host) {
          relSite = await client.api(`/sites/${host}:/sites/${text}`).get();
        }
      }
    } catch (err) {
      relSiteError = true;
    }

    let allSitesTotal: Site[] = [];
    if (allSitesError && relSiteError) {
      throw new AppError('Could not access sites or relative sites', 'accessDenied');
    } else {
      let allSites1: Site[] = [];
      if (!allSitesError && allSites.value) {
        allSites1 = allSites.value;
      }
      allSitesTotal.push(...allSites1);
      if (!relSiteError) {
        allSitesTotal.push(relSite);
      }
    }

    return allSitesTotal;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};

export const graphGetSuggestedSites = async (client: Client): Promise<Site[]> => {
  // return the followed sites and the top 10 of all sites

  try {
    const top: number = 10;
    let followedSites;
    let allSites;
    let followedSitesError: boolean = false;
    let allSitesError: boolean = false;

    try {
      allSites = await client.api(`/sites?search=*&top=${top}`).get();
    } catch (err) {
      allSitesError = true;
    }

    try {
      followedSites = await client.api(`/me/followedSites`).get();
    } catch (err) {
      followedSitesError = true;
    }

    let allSitesTotal: Site[] = [];
    if (allSitesError && followedSitesError) {
      logger.debug('graphGetSuggestedSites: Could not access sites and followed sites');

      return [];
    } else {
      let allSites1: Site[] = [];
      let followedSites1: Site[] = [];
      if (!allSitesError) {
        allSites1 = allSites.value;
      }
      if (!followedSitesError && followedSites.value) {
        followedSites1 = followedSites.value;
      }
      allSitesTotal = allSites1.filter((s) => followedSites1.findIndex((fs) => s.id === fs.id) === -1);
      allSitesTotal.push(...followedSites1);
    }

    return allSitesTotal;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
};
