import ClassificationDTO from '../classification/classificationDTO';
import IdListDTO from '../IdListDTO';
import ResourceLinkDTO from '../resourceLinkDTO';
import { Asset_TranslationDTO } from './asset_TranslationDTO';

export default class AssetDTO {
  assetId: number;

  code: string;

  externalId?: string;

  auditTrailId: number;

  ownerId?: string;

  state: number;

  listId?: number;

  isPhysical: boolean;

  trans: Asset_TranslationDTO[];

  links?: ResourceLinkDTO[];

  containers?: AssetDTO[];

  normIds?: IdListDTO;

  tagIds?: IdListDTO;

  classifications?: ClassificationDTO[];

  lifeCycleStart?: string;

  lifeCycleEnd?: string;

  primaryContainerId?: number;

  classificationGroups?: IdListDTO;

  constructor() {
    this.assetId = 0;
    this.code = '';
    this.auditTrailId = 0;
    this.state = 0;
    this.isPhysical = false;
    this.trans = [];
  }
}

export class AssetProcessDTO {
  assetId: number;

  processId: number;

  constructor(_assetId: number = -1, _processId: number = -1) {
    this.assetId = _assetId;
    this.processId = _processId;
  }
}

export class AssetRiskDTO {
  assetId: number;

  riskId: number;

  constructor(_assetId: number = -1, _riskId: number = -1) {
    this.assetId = _assetId;
    this.riskId = _riskId;
  }
}
