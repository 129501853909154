import { ISONormUpgradeLogDTO, ISONormUpgradeResultDTO } from 'models/dto/normDTO';
import NormDTO from 'models/dto/normDTO';
import Norm, { ISONormUpgradeLog, ISONormUpgradeResult } from 'models/norm';
import http from './httpService';
import { mapFromISONormUpgradeLogs, mapFromISONormUpgradeResult, mapFromNorms, mapToNorm } from 'mappings/dataMapping';
import AppError from 'utils/appError';

export async function apiGetNorms(iso: boolean | undefined, activeOnly: boolean, accessToken: string): Promise<Norm[]> {
  try {
    let query: string = `/norms?&activeOnly=${activeOnly}`;
    if (iso !== undefined) {
      query += `&iso=${iso}`;
    }

    const ar = await http.get<NormDTO[]>(query, http.getRequestConfig(accessToken));
    const norms = mapFromNorms(ar.data);

    return norms.sort((a, b) => a.name.localeCompare(b.name));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateNorm(norm: Norm, accessToken: string): Promise<void> {
  try {
    const normDTO = mapToNorm(norm);
    await http.put<NormDTO[]>(`/norms`, normDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddNorm(norm: Norm, accessToken: string): Promise<number> {
  try {
    const normDTO = mapToNorm(norm);
    const ar = await http.post<NormDTO>(`/norms`, normDTO, http.getRequestConfig(accessToken));

    return ar.data.normId;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDeleteNorm(norm: Norm, accessToken: string): Promise<void> {
  try {
    const normDTO = mapToNorm(norm);
    await http.post<NormDTO>(`/norms/delete/${norm.normId}`, normDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpgradeStandard(
  norm: Norm,
  upgradeSharePoint: boolean,
  accessToken: string,
): Promise<ISONormUpgradeResult> {
  try {
    const normDTO = mapToNorm(norm);
    const ar = await http.post<ISONormUpgradeResultDTO>(
      `/norms/upgrade?sharepoint=${upgradeSharePoint}`,
      normDTO,
      http.getRequestConfig(accessToken),
    );

    return mapFromISONormUpgradeResult(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetStandardUpgradeLog(norm: Norm, accessToken: string): Promise<ISONormUpgradeLog[]> {
  try {
    const ar = await http.get<ISONormUpgradeLogDTO[]>(
      `/norms/upgrade/log/${norm.normId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromISONormUpgradeLogs(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateStandardUpgradeLog(log: ISONormUpgradeLog, accessToken: string): Promise<boolean> {
  try {
    const ar = await http.put<boolean>(`/norms/upgrade/log`, log, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
