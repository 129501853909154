import Joi from 'joi';
import { KPI_Translation } from './kpi_Translation';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { TFunction } from 'i18next';
import ResourceLink from 'models/resourceLink';
import { areDifferent } from 'utils/array';
import KPIData from './kpiData';
import KPIAlert from './KPIAlert';
import KPIGraphData from './kpiGraphData';
import StringValue from 'models/stringValue/stringValue';
import KPIDataContext, { KPIContextFilter } from './kpiDataContext';
import { EntityTypes } from 'models/entity';
import {
  globalRegExKPIExpressionFuncSubTaskMultiply,
  globalRegExKPIExpressionFuncSubTaskSum,
  globalRegExStripKPIExpression,
} from 'globalConstants';
import { expression_eval } from 'utils/expression';
import Task from 'models/tasks/task';

export enum KPITypes {
  NotSet = 0,
  Text = 1,
  TextMultiline = 2,
  Html = 3,
  Number = 4,
  Choice = 5,
  Date = 6,
  SuccesError = 7,
  Expression = 99,
}

export enum KPITypesSystem {
  NotSet = 0,
  Task = 1,
}

export enum KPIAggregationMethods {
  Count = 0,
  Sum = 1,
  Min = 2,
  Max = 3,
  Avg = 4,
}

export enum KPIAggregationIntervals {
  Day = 1,
  Week = 2,
  Month = 3,
  Quarter = 4,
  Year = 5,
}

export enum KPIChartTypes {
  Bar = 1,
  Line = 2,
  Area = 3,
  Grid = 4,
}

export default class KPI {
  kpiId: number;

  auditTrailId: number | undefined;

  type: number;

  minValue: string | undefined;

  maxValue: string | undefined;

  decimalCount: number | undefined;

  choiceStringTypeId: number | undefined;

  defValue: string | undefined;

  required: boolean;

  attachmentMode?: number;

  listId?: number;

  commentMode?: number;

  systemKPIType?: KPITypesSystem;

  trans: KPI_Translation[];

  transIdx: number;

  name: string;

  description?: string;

  instruction?: string;

  expectedResult?: string;

  tagIds: number[];

  private _data: KPIData[]; //protect because each data record must have a ref to this kpi

  alerts: KPIAlert[];

  graphData?: KPIGraphData;

  hasAutomatedEvidence: boolean;

  choices?: StringValue[];

  //
  // Properties to store the values based on the definition
  //
  attachments?: ResourceLink[];

  constructor() {
    this.kpiId = 0;
    this.auditTrailId = 0;
    this.trans = [];
    this.name = '';
    this.transIdx = -1;
    this.type = KPITypes.Text;
    this.required = false;
    this.tagIds = [];
    this.commentMode = 0;
    this.attachmentMode = 0;
    this.alerts = [];
    this.hasAutomatedEvidence = false;

    //add a default data record with a reference to this KPI
    const newData = new KPIData();
    newData.kpi = this;
    newData.kpiId = this.kpiId;
    this._data = [newData];
  }

  //
  // Data accessors
  //
  get data(): KPIData[] {
    return this._data;
  }

  set data(newData: KPIData[]) {
    this._data = newData;
    this._data.forEach((d) => (d.kpi = this));
  }

  addData(newData: KPIData[]) {
    this._data.push(...newData);
    this._data.forEach((d) => (d.kpi = this));
  }

  //
  // Trans helpers
  //
  getTransIdx(langCode: string, defLangCode: string): number {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        let t: KPI_Translation = this.trans[i];
        if (t.lang?.code === langCode) {
          return i;
        }
      }

      //if not found, find the default language
      for (let i = 0; i < this.trans.length; i++) {
        let t: KPI_Translation = this.trans[i];
        if (t.lang?.code === defLangCode) {
          return i;
        }
      }
    }

    return -1;
  }

  getTypeText(t: TFunction<string[]>): string {
    if (this.isSystemType()) {
      return t('kpis:Types.System');
    }

    switch (this.type) {
      case KPITypes.Choice:
        return t('kpis:Types.Choice');
      case KPITypes.TextMultiline:
        return t('kpis:Types.TextMultiline');
      case KPITypes.SuccesError:
        return t('kpis:Types.SuccesError');
      case KPITypes.Html:
        return t('kpis:Types.Html');
      case KPITypes.Number:
        return t('kpis:Types.Number');
      case KPITypes.Text:
        return t('kpis:Types.Text');
      case KPITypes.Date:
        return t('kpis:Types.Date');
      case KPITypes.Expression:
        return t('kpis:Types.Expression');
      default:
        return 'Unknown';
    }
  }

  static getAggregationMethodText(method: KPIAggregationMethods, t: TFunction<string[]>): string {
    switch (method) {
      case KPIAggregationMethods.Count:
        return t('kpis:AggregationMethods.Count');
      case KPIAggregationMethods.Min:
        return t('kpis:AggregationMethods.Min');
      case KPIAggregationMethods.Max:
        return t('kpis:AggregationMethods.Max');
      case KPIAggregationMethods.Avg:
        return t('kpis:AggregationMethods.Avg');
      case KPIAggregationMethods.Sum:
        return t('kpis:AggregationMethods.Sum');
    }
  }

  static getAggregationIntervalText(interval: KPIAggregationIntervals, t: TFunction<string[]>): string {
    switch (interval) {
      case KPIAggregationIntervals.Day:
        return t('kpis:AggregationIntervals.Day');
      case KPIAggregationIntervals.Week:
        return t('kpis:AggregationIntervals.Week');
      case KPIAggregationIntervals.Month:
        return t('kpis:AggregationIntervals.Month');
      case KPIAggregationIntervals.Quarter:
        return t('kpis:AggregationIntervals.Quarter');
      case KPIAggregationIntervals.Year:
        return t('kpis:AggregationIntervals.Year');
    }
  }

  static getChartTypeText(type: KPIChartTypes, t: TFunction<string[]>): string {
    switch (type) {
      case KPIChartTypes.Bar:
        return t('kpis:ChartTypes.Bar');
      case KPIChartTypes.Area:
        return t('kpis:ChartTypes.Area');
      case KPIChartTypes.Line:
        return t('kpis:ChartTypes.Line');
      case KPIChartTypes.Grid:
        return t('kpis:ChartTypes.Grid');
    }
  }

  static expressionNeedsSubtasks(exp: string | undefined): boolean {
    if (exp) {
      const matchesSubSum = [...exp.matchAll(globalRegExKPIExpressionFuncSubTaskSum)];
      if (matchesSubSum.length > 0) return true;
      const matchesSubMultiply = [...exp.matchAll(globalRegExKPIExpressionFuncSubTaskMultiply)];
      if (matchesSubMultiply.length > 0) return true;
    }

    return false;
  }

  static calcExpression(task: Task, exp: string | undefined): number | undefined {
    try {
      if (exp && task.kpiData) {
        const data = task.kpiData;
        let newExp = exp;

        //replace subtask formula SUM
        const matchesSubSum = [...newExp.matchAll(globalRegExKPIExpressionFuncSubTaskSum)];
        for (let idx = matchesSubSum.length - 1; idx >= 0; idx--) {
          //backwards so we don't mess up indexes
          const match = matchesSubSum[idx];
          if (match.index !== undefined) {
            let idStr = match[0];
            idStr = idStr.substring(12, idStr.length - 1);
            const id = Number.parseInt(idStr);
            let subResult: number = 0;

            if (task.instances) {
              for (let subIdx = 0; subIdx < task.instances.length; subIdx++) {
                const subData = task.instances[subIdx].kpiData;
                if (subData) {
                  const kpiData = subData.find((k) => k.kpiId === id);
                  if (kpiData && kpiData.resultNumber) {
                    subResult += kpiData.resultNumber;
                  }
                }
              }
            }

            if (subResult) {
              //found the KPI with data
              newExp =
                newExp.substring(0, match.index) +
                subResult.toString() +
                newExp.substring(match.index + idStr.length + 13);
            } else {
              //replace the match with zero
              newExp = newExp.substring(0, match.index) + '0' + newExp.substring(match.index + idStr.length + 13);
            }
          }
        }

        //replace subtask formula MULTIPLY
        const matchesSubMultiply = [...newExp.matchAll(globalRegExKPIExpressionFuncSubTaskMultiply)];
        for (let idx = matchesSubMultiply.length - 1; idx >= 0; idx--) {
          //backwards so we don't mess up indexes
          const match = matchesSubMultiply[idx];
          if (match.index !== undefined) {
            let idStr = match[0];
            idStr = idStr.substring(17, idStr.length - 1);
            const id = Number.parseInt(idStr);
            let subResult: number = 0;

            if (task.instances) {
              for (let subIdx = 0; subIdx < task.instances.length; subIdx++) {
                const subData = task.instances[subIdx].kpiData;
                if (subData) {
                  const kpiData = subData.find((k) => k.kpiId === id);
                  if (kpiData && kpiData.resultNumber) {
                    subResult *= kpiData.resultNumber;
                  }
                }
              }
            }

            if (subResult) {
              //found the KPI with data
              newExp =
                newExp.substring(0, match.index) +
                subResult.toString() +
                newExp.substring(match.index + idStr.length + 18);
            } else {
              //replace the match with zero
              newExp = newExp.substring(0, match.index) + '0' + newExp.substring(match.index + idStr.length + 18);
            }
          }
        }

        //replace single KPI id's
        const matchesKPI = [...newExp.matchAll(globalRegExStripKPIExpression)];

        for (let idx = matchesKPI.length - 1; idx >= 0; idx--) {
          //backwards so we don't mess up indexes
          const match = matchesKPI[idx];
          if (match.index !== undefined) {
            let idStr = match[0];
            idStr = idStr.substring(1, idStr.length - 1);
            const id = Number.parseInt(idStr);
            const kpiData = data.find(
              (k) => k.kpiId === id && k.kpi?.type !== KPITypes.Expression, //no expressions, this can cause infinite loops
            );
            if (kpiData && kpiData.resultNumber) {
              //found the KPI with data
              newExp =
                newExp.substring(0, match.index) +
                kpiData.resultNumber.toString() +
                newExp.substring(match.index + idStr.length + 2);
            } else {
              //replace the match with zero
              newExp = newExp.substring(0, match.index) + '0' + newExp.substring(match.index + idStr.length + 2);
            }
          }
        }

        //evaluate expression
        const result = expression_eval(newExp);
        if (isNaN(result)) {
          return NaN;
        } else {
          return result;
        }
      } else {
        return undefined;
      }
    } catch (err) {
      return NaN;
    }
  }

  static calcPreviewExpression(exp: string | undefined): number | undefined {
    try {
      if (exp) {
        let newExp = exp;

        //replace subtask formula SUM
        const matchesSubSum = [...newExp.matchAll(globalRegExKPIExpressionFuncSubTaskSum)];
        for (let idx = matchesSubSum.length - 1; idx >= 0; idx--) {
          //backwards so we don't mess up indexes
          const match = matchesSubSum[idx];
          if (match.index !== undefined) {
            let idStr = match[0];
            newExp = newExp.substring(0, match.index) + '0' + newExp.substring(match.index + idStr.length);
          }
        }

        //replace subtask formula MULTIPLY
        const matchesSubMultiply = [...newExp.matchAll(globalRegExKPIExpressionFuncSubTaskMultiply)];
        for (let idx = matchesSubMultiply.length - 1; idx >= 0; idx--) {
          //backwards so we don't mess up indexes
          const match = matchesSubMultiply[idx];
          if (match.index !== undefined) {
            let idStr = match[0];
            newExp = newExp.substring(0, match.index) + '0' + newExp.substring(match.index + idStr.length);
          }
        }

        //replace single KPI id's
        const matchesKPI = [...newExp.matchAll(globalRegExStripKPIExpression)];

        for (let idx = matchesKPI.length - 1; idx >= 0; idx--) {
          //backwards so we don't mess up indexes
          const match = matchesKPI[idx];
          if (match.index !== undefined) {
            let idStr = match[0];
            newExp = newExp.substring(0, match.index) + '0' + newExp.substring(match.index + idStr.length);
          }
        }

        //evaluate expression
        const result = expression_eval(newExp);
        if (isNaN(result)) {
          return NaN;
        } else {
          return result;
        }
      } else {
        return undefined;
      }
    } catch (err) {
      return NaN;
    }
  }

  resetTypeConfig() {
    this.choiceStringTypeId = undefined;
    this.choices = undefined;
    this.decimalCount = 0;
    this.required = false;
    this.minValue = undefined;
    this.maxValue = undefined;
    this.defValue = undefined;
  }

  isEqual(kpi: KPI): boolean {
    if (kpi.kpiId !== this.kpiId) return false;
    if (kpi.choiceStringTypeId !== this.choiceStringTypeId) return false;
    if (kpi.type !== this.type) return false;
    if (kpi.decimalCount !== this.decimalCount) return false;
    if (kpi.commentMode !== this.commentMode) return false;
    if (kpi.attachmentMode !== this.attachmentMode) return false;
    if (kpi.required !== this.required) return false;
    if (kpi.minValue !== this.minValue) return false;
    if (kpi.maxValue !== this.maxValue) return false;
    if (kpi.defValue !== this.defValue) return false;
    if (kpi.expectedResult !== this.expectedResult) return false;
    if (kpi.instruction !== this.instruction) return false;
    if (kpi.description !== this.description) return false;
    if (kpi.auditTrailId !== this.auditTrailId) return false;
    if (kpi.name !== this.name) return false;
    if (kpi.listId !== this.listId) return false;

    if (
      areDifferent(kpi.tagIds, this.tagIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(kpi.choices, this.choices, (a: StringValue, b: StringValue) => {
        return a.isEqual(b);
      }) === true
    ) {
      return false;
    }

    return true;
  }

  clone(): KPI {
    const output = new KPI();
    output.kpiId = this.kpiId;
    output.choiceStringTypeId = this.choiceStringTypeId;
    output.type = this.type;
    output.decimalCount = this.decimalCount;
    output.commentMode = this.commentMode;
    output.attachmentMode = this.attachmentMode;
    output.required = this.required;
    output.minValue = this.minValue;
    output.maxValue = this.maxValue;
    output.defValue = this.defValue;
    output.expectedResult = this.expectedResult;
    output.instruction = this.instruction;
    output.description = this.description;
    output.auditTrailId = this.auditTrailId;
    output.tagIds = [...this.tagIds];
    output.name = this.name;
    output.transIdx = this.transIdx;
    output.trans = [...this.trans];
    output.attachments = this.attachments?.map((r) => r.clone());
    output.choices = this.choices?.map((c) => c.clone());
    output.data = this.data?.map((d) => d.clone());
    output.systemKPIType = this.systemKPIType;
    output.listId = this.listId;
    output.alerts = this.alerts.map((a) => a.clone());

    return output;
  }

  getMaxAttachments = (): number => {
    if (!this.attachmentMode) return 0;

    return this.attachmentMode < 0 ? Math.abs(this.attachmentMode) : 5;
  };

  getMinAttachments = (): number => {
    if (!this.attachmentMode) return 0;

    return this.attachmentMode > 0 ? this.attachmentMode : 0;
  };

  getMaxComment = (): number => {
    if (!this.commentMode) return 0;

    return this.commentMode < 0 ? Math.abs(this.commentMode) : 512;
  };

  getMinComment = (): number => {
    if (!this.commentMode) return 0;

    return this.commentMode > 0 ? this.commentMode : 0;
  };

  getAttachments = (): KPIDataContext[] => {
    if (!this.data || this.data.length === 0 || !this.data[0].contexts) return [];

    return this.data[0].contexts.filter((c) => c.entityType === EntityTypes.KPILink);
  };

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate(
      {
        name: this.name,
      },
      { abortEarly: false },
    );
  }

  isStackedType = (): boolean => {
    return this.type === KPITypes.SuccesError || this.type === KPITypes.Choice;
  };

  isCalcType = (): boolean => {
    return (
      this.type === KPITypes.Number ||
      this.type === KPITypes.SuccesError ||
      this.type === KPITypes.Expression ||
      this.type === KPITypes.Choice
    );
  };

  isSystemType = (): boolean => {
    return this.systemKPIType !== undefined && this.systemKPIType !== null && this.systemKPIType !== 0;
  };
}

export class KPIGraphDataConfig {
  kpiId: number;

  periodStart: Date | undefined;

  periodEnd: Date | undefined;

  aggregationMethod: KPIAggregationMethods;

  aggregationInterval: KPIAggregationIntervals;

  chartType: KPIChartTypes;

  contextFilter?: KPIContextFilter;

  constructor() {
    this.kpiId = 0;
    this.periodStart = undefined;
    this.periodEnd = undefined;
    this.aggregationMethod = KPIAggregationMethods.Count;
    this.aggregationInterval = KPIAggregationIntervals.Month;
    this.chartType = KPIChartTypes.Bar;
  }
}
