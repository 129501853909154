import { TFunction } from 'i18next';
import Dashboard, { DashboardSharedDataSets } from './dashboard';
import Language from './language';
import { IAppContext } from 'App/AppContext';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { IIconProps } from '@fluentui/react';

export enum WidgetCategories {
  Task = 1,
  Risk = 2,
  Standard = 3,
  Library = 4,
  Process = 5,
  AzureDefender = 6,
  KPI = 7,
}

export enum WidgetTypes {
  NotSet = 0,
  MyUpcomingTasks = 1,
  MyOverdueTasks = 2,
  MyRecentItems = 8,
  MyActions = 9,
  SingleRisk = 20,
  SingleTask = 21,
  SingleKPI = 30,
  SingleKPILastPeriod = 31,
  RecentlyClosedTasks = 50,
  LibraryContent = 51,
  OpenTasksPerUser = 52,
  NormCoverage = 53,
  CustomNormCoverage = 54,
  RiskStatus = 55,
  RiskStatusNotAccepted = 56,
  MyNormCoverage = 57,
  CustomMyNormCoverage = 58,
  AzureDefenderSecureScore = 59,
  TaskStatus = 60,
  LibraryReadResponse = 61,
}

export interface IWidgetCustomAction {
  name: string;
  iconProps?: IIconProps;
  disabled?: boolean;
  callback: (widget: Widget) => void;
}

export interface IWidgetCategory {
  id: WidgetCategories;
  name: string;
}

export interface IWidgetType {
  id: number;
  type: WidgetTypes;
  name: string;
  description: string;
  category: WidgetCategories;
}

export function getWidgetCategories(appContext: IAppContext, t: TFunction<string[]>) {
  const widgetCategories: IWidgetCategory[] = [];

  const widgetCategoriesTask: IWidgetCategory = {
    id: WidgetCategories.Task,
    name: t('widgets:Categories.Task'),
  };
  widgetCategories.push(widgetCategoriesTask);

  const widgetCategoriesRisk: IWidgetCategory = {
    id: WidgetCategories.Risk,
    name: t('widgets:Categories.Risk'),
  };
  widgetCategories.push(widgetCategoriesRisk);

  const widgetCategoriesNorm: IWidgetCategory = {
    id: WidgetCategories.Standard,
    name: t('widgets:Categories.Norm'),
  };
  widgetCategories.push(widgetCategoriesNorm);

  const widgetCategoriesLibrary: IWidgetCategory = {
    id: WidgetCategories.Library,
    name: t('widgets:Categories.Library'),
  };
  widgetCategories.push(widgetCategoriesLibrary);

  if (hasUserFeature(appContext, FeatureTypes.KPIsAndForms)) {
    const widgetCategoriesKPI: IWidgetCategory = {
      id: WidgetCategories.KPI,
      name: t('widgets:Categories.KPI'),
    };
    widgetCategories.push(widgetCategoriesKPI);
  }

  if (hasUserFeature(appContext, FeatureTypes.PAWorkflows)) {
    const widgetCategoriesAzureDefender: IWidgetCategory = {
      id: WidgetCategories.AzureDefender,
      name: t('widgets:Categories.AzureDefender'),
    };
    widgetCategories.push(widgetCategoriesAzureDefender);
  }

  return widgetCategories;
}

export function getWidgetTypes(t: TFunction<string[]>) {
  const widgetTypes: IWidgetType[] = [];

  const widgetTypeMyUpcomingTasks: IWidgetType = {
    id: 1,
    type: WidgetTypes.MyUpcomingTasks,
    name: t('widgets:MyUpcomingTasks.Name'),
    description: t('widgets:MyUpcomingTasks.Description'),
    category: WidgetCategories.Task,
  };
  widgetTypes.push(widgetTypeMyUpcomingTasks);

  const widgetTypeLibraryContent: IWidgetType = {
    id: 2,
    type: WidgetTypes.LibraryContent,
    name: t('widgets:LibraryContent.Name'),
    description: t('widgets:LibraryContent.Description'),
    category: WidgetCategories.Library,
  };
  widgetTypes.push(widgetTypeLibraryContent);

  const widgetTypeMyOverdueTasks: IWidgetType = {
    id: 3,
    type: WidgetTypes.MyOverdueTasks,
    name: t('widgets:MyOverdueTasks.Name'),
    description: t('widgets:MyOverdueTasks.Description'),
    category: WidgetCategories.Task,
  };
  widgetTypes.push(widgetTypeMyOverdueTasks);

  const widgetTypeRecentlyClosedTasks: IWidgetType = {
    id: 4,
    type: WidgetTypes.RecentlyClosedTasks,
    name: t('widgets:RecentlyClosedTasks.Name'),
    description: t('widgets:RecentlyClosedTasks.Description'),
    category: WidgetCategories.Task,
  };
  widgetTypes.push(widgetTypeRecentlyClosedTasks);

  const widgetTypeSingleRisk: IWidgetType = {
    id: 5,
    type: WidgetTypes.SingleRisk,
    name: t('widgets:SingleRisk.Name'),
    description: t('widgets:SingleRisk.Description'),
    category: WidgetCategories.Risk,
  };
  widgetTypes.push(widgetTypeSingleRisk);

  const widgetOpenTasksPerUser: IWidgetType = {
    id: 6,
    type: WidgetTypes.OpenTasksPerUser,
    name: t('widgets:OpenTasksPerUser.Name'),
    description: t('widgets:OpenTasksPerUser.Description'),
    category: WidgetCategories.Task,
  };
  widgetTypes.push(widgetOpenTasksPerUser);

  const widgetNormCoverage: IWidgetType = {
    id: 6,
    type: WidgetTypes.NormCoverage,
    name: t('widgets:NormCoverage.Name'),
    description: t('widgets:NormCoverage.Description'),
    category: WidgetCategories.Standard,
  };
  widgetTypes.push(widgetNormCoverage);

  const widgetCustomNormCoverage: IWidgetType = {
    id: 7,
    type: WidgetTypes.CustomNormCoverage,
    name: t('widgets:CustomNormCoverage.Name'),
    description: t('widgets:CustomNormCoverage.Description'),
    category: WidgetCategories.Standard,
  };
  widgetTypes.push(widgetCustomNormCoverage);

  const widgetRiskStatus: IWidgetType = {
    id: 8,
    type: WidgetTypes.RiskStatus,
    name: t('widgets:RiskStatus.Name'),
    description: t('widgets:RiskStatus.Description'),
    category: WidgetCategories.Risk,
  };
  widgetTypes.push(widgetRiskStatus);

  const widgetRiskStatusNotAccepted: IWidgetType = {
    id: 9,
    type: WidgetTypes.RiskStatusNotAccepted,
    name: t('widgets:RiskStatusNotAccepted.Name'),
    description: t('widgets:RiskStatusNotAccepted.Description'),
    category: WidgetCategories.Risk,
  };
  widgetTypes.push(widgetRiskStatusNotAccepted);

  const widgetMyNormCoverage: IWidgetType = {
    id: 10,
    type: WidgetTypes.MyNormCoverage,
    name: t('widgets:MyNormCoverage.Name'),
    description: t('widgets:MyNormCoverage.Description'),
    category: WidgetCategories.Standard,
  };
  widgetTypes.push(widgetMyNormCoverage);

  const widgetCustomMyNormCoverage: IWidgetType = {
    id: 11,
    type: WidgetTypes.CustomMyNormCoverage,
    name: t('widgets:CustomMyNormCoverage.Name'),
    description: t('widgets:CustomMyNormCoverage.Description'),
    category: WidgetCategories.Standard,
  };
  widgetTypes.push(widgetCustomMyNormCoverage);

  const widgetSingleTask: IWidgetType = {
    id: 12,
    type: WidgetTypes.SingleTask,
    name: t('widgets:SingleTask.Name'),
    description: t('widgets:SingleTask.Description'),
    category: WidgetCategories.Task,
  };
  widgetTypes.push(widgetSingleTask);

  const widgetAzureDefenderSecureScore: IWidgetType = {
    id: 13,
    type: WidgetTypes.AzureDefenderSecureScore,
    name: t('widgets:AzureDefenderSecureScore.Name'),
    description: t('widgets:AzureDefenderSecureScore.Description'),
    category: WidgetCategories.AzureDefender,
  };
  widgetTypes.push(widgetAzureDefenderSecureScore);

  const widgetTaskStatus: IWidgetType = {
    id: 14,
    type: WidgetTypes.TaskStatus,
    name: t('widgets:TaskStatus.Name'),
    description: t('widgets:TaskStatus.Description'),
    category: WidgetCategories.Task,
  };
  widgetTypes.push(widgetTaskStatus);

  const widgetSingleKPI: IWidgetType = {
    id: 15,
    type: WidgetTypes.SingleKPI,
    name: t('widgets:SingleKPI.Name'),
    description: t('widgets:SingleKPI.Description'),
    category: WidgetCategories.KPI,
  };
  widgetTypes.push(widgetSingleKPI);

  const widgetSingleKPILastPeriod: IWidgetType = {
    id: 16,
    type: WidgetTypes.SingleKPILastPeriod,
    name: t('widgets:SingleKPILastPeriod.Name'),
    description: t('widgets:SingleKPILastPeriod.Description'),
    category: WidgetCategories.KPI,
  };
  widgetTypes.push(widgetSingleKPILastPeriod);

  const widgetLibraryReadResponse: IWidgetType = {
    id: 17,
    type: WidgetTypes.LibraryReadResponse,
    name: t('widgets:LibraryReadResponse.Name'),
    description: t('widgets:LibraryReadResponse.Description'),
    category: WidgetCategories.Library,
  };
  widgetTypes.push(widgetLibraryReadResponse);

  return widgetTypes;
}

export default class Widget {
  widgetId: number;

  dashboardId: number;

  name: string;

  widgetType: WidgetTypes;

  widgetConfig?: string;

  sortOrder: number;

  dashboard?: Dashboard;

  trans: Widget_Translation[];

  transIdx: number;

  customActions?: IWidgetCustomAction[];

  constructor() {
    this.widgetId = 0;
    this.dashboardId = 0;
    this.name = '';
    this.widgetType = WidgetTypes.NotSet;
    this.sortOrder = -1;
    this.trans = [];
    this.transIdx = -1;
  }

  hasConfig(): boolean {
    switch (this.widgetType) {
      case WidgetTypes.MyUpcomingTasks:
        return true;
      case WidgetTypes.SingleRisk:
        return true;
      case WidgetTypes.LibraryContent:
        return true;
      case WidgetTypes.NormCoverage:
        return true;
      case WidgetTypes.CustomNormCoverage:
        return true;
      case WidgetTypes.MyNormCoverage:
        return true;
      case WidgetTypes.CustomMyNormCoverage:
        return true;
      case WidgetTypes.SingleTask:
        return true;
      case WidgetTypes.AzureDefenderSecureScore:
        return true;
      case WidgetTypes.RiskStatus:
        return true;
      case WidgetTypes.RiskStatusNotAccepted:
        return true;
      case WidgetTypes.OpenTasksPerUser:
        return true;
      case WidgetTypes.TaskStatus:
        return true;
      case WidgetTypes.SingleKPI:
        return true;
      case WidgetTypes.SingleKPILastPeriod:
        return true;
      case WidgetTypes.LibraryReadResponse:
        return true;
    }

    return false;
  }

  needSharedData(set: DashboardSharedDataSets): boolean {
    switch (set) {
      case DashboardSharedDataSets.MyTasks:
        switch (this.widgetType) {
          case WidgetTypes.MyUpcomingTasks:
            return true;
          case WidgetTypes.MyOverdueTasks:
            return true;
          case WidgetTypes.RecentlyClosedTasks:
            return true;
        }
    }

    return false;
  }

  clone(): Widget {
    const newWidget = new Widget();
    newWidget.widgetId = this.widgetId;
    newWidget.dashboardId = this.dashboardId;
    newWidget.dashboard = this.dashboard;
    newWidget.widgetConfig = this.widgetConfig;
    newWidget.name = this.name;
    newWidget.widgetType = this.widgetType;
    newWidget.sortOrder = this.sortOrder;
    newWidget.trans = [...this.trans];
    newWidget.transIdx = this.transIdx;

    return newWidget;
  }
}

export class Widget_Translation {
  widgetId: number;

  languageId: number;

  name: string;

  lang?: Language;

  constructor() {
    this.widgetId = 0;
    this.languageId = 0;
    this.name = '';
  }
}
