export enum LocalStorageKeys {
  NotSet = '',
  UserName = 'REDLAB-Preferred-Username',
  DarkMode = 'REDLAB-DarkMode',
  DarkModeOutlook = 'REDLAB-DarkModeOutlook',
  LocalAccount = 'REDLAB-Local-Account',
  TaskViewType = 'REDLAB-Task-ViewType',
  SharepointOrg = 'REDLAB-SP-Org',
  ShowAllLinks = 'REDLAB-Link-ShowAll',
  QuickFilterAllTasks = 'REDLAB-QuickFilter-AllTasks',
  QuickFilterMyRequests = 'REDLAB-QuickFilter-MyRequests',
  QuickFilterMyTasks = 'REDLAB-QuickFilter-MyTasks',
  QuickFilterMonitoringTasks = 'REDLAB-QuickFilter-MonitoringTasks',
  QuickFilterRisks = 'REDLAB-QuickFilter-Risks',
  TeamsQuickFilterOnlyMyTasks = 'REDLAB-TeamsQuickFilter-OnlyMyTasks',
  DashboardSortOrderForMe = 'REDLAB-DashboardSortOrderForMe',
  DashboardSortOrderForMyTeams = 'REDLAB-DashboardSortOrderForMyTeams',
  ControlTreeListCache = 'REDLAB-ControlTreeListCache',
  NormTreeListCache = 'REDLAB-NormTreeListCache',
  ThemeTreeListCache = 'REDLAB-ThemeTreeListCache',
  ObjectiveTreeListCache = 'ObjectiveTreeListCache',
  ProcessTreeListCache = 'ProcessTreeListCache',
  AuditYear = 'REDLAB-AuditYear',
  MyTasksListSort = 'REDLAB-MyTasksListSort',
  MyRequestListSort = 'REDLAB-MyRequestListSort',
  AllTasksListSort = 'REDLAB-AllTasksListSort',
  RiskTaskListSort = 'REDLAB-RiskTaskListSort',
  ControlTaskListSort = 'REDLAB-ControlTaskListSort',
  ThemeTaskListSort = 'REDLAB-ThemeTaskListSort',
  TaskScheduleSort = 'REDLAB-TaskScheduleSort',
  ProcessTaskListSort = 'REDLAB-ProcessTaskListSort',
  ObjectiveTaskListSort = 'REDLAB-ObjectiveTaskListSort',
  SceneFilterTasks = 'REDLAB-SceneFilterTasks',
  SceneFilterSchedule = 'REDLAB-SceneFilterSchedule',
  ScheduleLevel = 'REDLAB-PlanningLevel',
  MyControlsListSort = 'REDLAB-MyControlsListSort',
  MyNormsListSort = 'REDLAB-MyNormsListSort',
  MyObjectivesListSort = 'REDLAB-MyObjectivesListSort',
  MyProcessesListSort = 'REDLAB-MyProcessesListSort',
  MyThemesListSort = 'REDLAB-MyThemesListSort',
  MyRiskListSort = 'REDLAB-RiskListSort',
  SceneFilterOrganization = 'REDLAB-SceneFilterOrganization',
  SceneFilterControl = 'REDLAB-SceneFilterControl',
  SceneFilterTheme = 'REDLAB-SceneFilterTheme',
  SceneFilterRisk = 'REDLAB-SceneFilterRisk',
  SceneFilterNorm = 'REDLAB-SceneFilterNorm',
  SceneFilterLibrary = 'REDLAB-SceneFilterLibrary',
  ShowResourcePanel = 'REDLAB-ShowResourcePanel',
  MainNavCollapsed = 'REDLAB-MainNavCollapsed',
  StandardElementViewMode = 'REDLAB-StandardElementViewMode',
  SceneFilterApproval = 'REDLAB-SceneFilterApproval',
  SceneFilterSentApproval = 'REDLAB-SceneFilterSentApproval',
  AssetViewMode = 'REDLAB-AssetViewMode',
  SceneFilterAsset = 'REDLAB-SceneFilterAsset',
  MyAssetListSort = 'REDLAB-MyAssetListSort',
  QuickFilterAssets = 'REDLAB-QuickFilterAssets',
  AssetTreeListCache = 'REDLAB-AssetTreeListCache',
  MyAssetsListSort = 'REDLAB-MyAssetsListSort',
  QuickFilterAllEvents = 'REDLAB-QuickFilterAllEvents',
  RiskSectionsExpanded = 'REDLAB-RiskSectionsExpanded',
  MyKPIListSort = 'REDLAB-MyKPIListSort',
  QuickFilterKPIs = 'REDLAB-QuickFilterKPIs',
  KPIPeriodSelector = 'REDLAB-KPIPeriodSelector',
  KPIAggregationSelector = 'REDLAB-KPIAggregationSelector',
  KPIAggregationIntervalSelector = 'REDLAB-KPIGroupingSelector',
  KPIChartSelector = 'REDLAB-KPIChartSelector',
  RiskColumns = 'REDLAB-RiskColumns',
  AssetColumns = 'REDLAB-AssetColumns',
  QuickFilterObjectives = 'REDLAB-QuickFilterObjectives',
  QuickFilterProcesses = 'REDLAB-QuickFilterProcesses',
  QuickFilterControls = 'REDLAB-QuickFilterControls',
  QuickFilterThemes = 'REDLAB-QuickFilterThemes',
  QuickFilterStandards = 'REDLAB-QuickFilterStandards',
  TeamsAppKBOrgUnitMap = 'REDLAB-TeamsAppKBOrgUnitMap',
  TeamsAppMyTasksOrgUnitMap = 'REDLAB-TeamsAppMyTasksOrgUnitMap'
}

export function setLocalStorageData(name: LocalStorageKeys, value: string) {
  try {
    if (name === LocalStorageKeys.NotSet) return;
    window.localStorage.setItem(name, value);
  } catch (err) {
    // no-op
  }
}

export function getLocalStorageData(name: LocalStorageKeys): string | undefined {
  try {
    if (name === LocalStorageKeys.NotSet) return;
    const val = window.localStorage.getItem(name);
    if (val === null) return undefined;

    return val;
  } catch (err) {
    return undefined;
  }
}

export function removeLocalStorageData(name: LocalStorageKeys) {
  try {
    if (name === LocalStorageKeys.NotSet) return;
    window.localStorage.removeItem(name);
  } catch (err) {
    // no-op
  }
}

export function removeAllLocalStorageData() {
  try {
    window.localStorage.clear();
  } catch (err) {
    // no-op
  }
}
