import { mapFromSettings, mapFromSettingsToUserSettings } from './settingMapping';
import { mapFromUsers } from 'mappings/userMapping';
import { mapFromGroups } from 'mappings/groupMapping';
import GlobalDataCacheDTO from 'models/dto/globalDataCacheDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { mapFromRiskStates } from './riskMapping';
import { mapFromTaskStates } from './taskMapping';
import { mapFromTaskTypes } from './taskTypeMapping';

export function mapFromGlobalDataCache(globalDataCacheDTO: GlobalDataCacheDTO): GlobalDataCache {
  const globalDataCache = new GlobalDataCache();

  if (globalDataCacheDTO) {
    if (globalDataCacheDTO.users) {
      globalDataCache.users.items = mapFromUsers(globalDataCacheDTO.users);
    }

    if (globalDataCacheDTO.groups) {
      globalDataCache.groups.items = mapFromGroups(globalDataCacheDTO.groups);
    }

    if (globalDataCacheDTO.settings) {
      globalDataCache.settings = mapFromSettings(globalDataCacheDTO.settings);
    }

    if (globalDataCacheDTO.userSettings) {
      globalDataCache.userSettings = mapFromSettingsToUserSettings(globalDataCacheDTO.userSettings);
    }

    if (globalDataCacheDTO.riskStates) {
      globalDataCache.riskStates.items = mapFromRiskStates(globalDataCacheDTO.riskStates);
    }

    if (globalDataCacheDTO.taskStates) {
      globalDataCache.taskStates.items = mapFromTaskStates(globalDataCacheDTO.taskStates);
    }

    if (globalDataCacheDTO.taskTypes) {
      globalDataCache.taskTypes.items = mapFromTaskTypes(globalDataCacheDTO.taskTypes);
    }
  }

  return globalDataCache;
}
