import { Approval } from './approval';
import { graphSharepointPagesRequest } from './../services/Auth/authConfig';
import { HomeAccount } from './userSetting';
import Entity from 'models/entity';
import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import ResourceList, { ResourceListType } from './resourceList';
import {
  graphGetListItem,
  graphGetDocumentLibraryItemPreview,
  graphGetDocumentLibraryItemThumbnails,
  graphGetSite,
  graphGetSitePage,
  graphValidateListItem,
  graphValidateDocumentLibraryItem,
  graphValidateSitePageItem,
  graphGetDriveItem,
  graphGetDriveItemVersions,
  graphGetSitePageListItem,
  graphGetListItemVersions,
} from 'services/Graph/graphService';
import { IAppContext } from 'App/AppContext';
import { graphSharepointLibraryRequest, graphSharepointListRequest } from 'services/Auth/authConfig';
import { ListItem } from 'microsoft-graph';
import { ISite, ISitePage } from 'services/Graph/SharepointInterfaces';
import logger from 'services/Logging/logService';
import ResourceLinkChannel from './resourceLinkChannel';
import Group from './group';
import { TeamsChannel } from './teamsChannel';
import { newGuidNil } from 'utils/guid';
import AppError from 'utils/appError';
import { TFunction } from 'i18next';
import { IGraphInterface } from 'App/App';
import Activity from './activity';

let newLinkId = -1;

export default class ResourceLink {
  linkId: number;

  listId: number;

  ownerId?: string;

  listItemId?: string;

  driveItemId?: string;

  pageId?: string;

  linkName: string;

  linkURL: string; //don't use to navigate: use getWebURL()

  linkURLFragment: string;

  pinned: boolean;

  usedCount: number;

  entity?: Entity;

  valid?: boolean;

  isValidated: boolean;

  list: ResourceList;

  resourceLinkChannels?: ResourceLinkChannel[];

  kbGroups?: KBTeam[];

  approvals?: Approval[];

  changed?: Date;

  version?: string;

  versions: ResourceLinkVersion[];

  publishingState?: string;

  activity?: Activity;

  constructor() {
    this.linkId = newLinkId;
    this.linkName = '';
    this.linkURL = '';
    this.linkURLFragment = '';
    this.usedCount = 0;
    this.list = new ResourceList();
    this.listId = 0;
    this.pinned = false;
    this.isValidated = false;
    this.version = '';
    this.changed = undefined;
    this.versions = [];
    newLinkId = newLinkId - 1;
  }

  clone(): ResourceLink {
    const newResourceLink = new ResourceLink();
    newResourceLink.linkId = this.linkId;
    newResourceLink.linkName = this.linkName;
    newResourceLink.linkURL = this.linkURL;
    newResourceLink.usedCount = this.usedCount;
    newResourceLink.listId = this.listId;
    newResourceLink.listItemId = this.listItemId;
    newResourceLink.driveItemId = this.driveItemId;
    newResourceLink.list = this.list;
    newResourceLink.pageId = this.pageId;
    newResourceLink.pinned = this.pinned;
    newResourceLink.valid = this.valid;
    newResourceLink.isValidated = this.isValidated;
    newResourceLink.approvals = this.approvals ? [...this.approvals] : undefined;
    newResourceLink.changed = this.changed;
    newResourceLink.version = this.version;
    newResourceLink.publishingState = this.publishingState;
    newResourceLink.ownerId = this.ownerId;
    newResourceLink.versions = [...this.versions];

    return newResourceLink;
  }

  isWholeList(): boolean {
    if (!this.driveItemId && !this.listItemId && !this.pageId && this.linkURL === 'list') return true;

    return false;
  }

  setWholeList(list: ResourceList): boolean {
    this.list = list;
    this.listId = list.listId;
    this.linkName = list.name;
    this.linkURL = 'list';
    this.driveItemId = undefined;
    this.listItemId = undefined;
    this.pageId = undefined;

    return false;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    if (this.isWholeList()) {
      //return a dummy validation result
      const schema: Joi.ObjectSchema = Joi.object({
        name: Joi.string().min(4).max(4),
      });

      return schema.validate({ url: this.linkURL }, { abortEarly: false });
    }

    switch (this.list.listType) {
      case ResourceListType.DocumentLibrary:
        if (!this.driveItemId) throw new AppError('DriveItemId must have a value');
        break;
      case ResourceListType.CustomList:
        if (!this.listItemId) throw new AppError('ListItemId must have a value');
        break;
      case ResourceListType.SitePageLibrary:
        if (!this.pageId) throw new AppError('PageId must have a value');
        break;
    }

    const schema: Joi.ObjectSchema = Joi.object({
      name: Joi.string().min(1).max(512).required().label(localizedFields['name']),
      url: Joi.string().uri().min(1).max(800).required().label(localizedFields['url']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ url: this.linkURL.trim(), name: this.linkName.trim() }, { abortEarly: false });
  }

  async getThumbnail(appContext: IAppContext): Promise<string> {
    if (!this.list.spDriveId || !this.driveItemId) return '';

    try {
      const graphInterface = await appContext.getGraphInterface(
        graphSharepointLibraryRequest.scopes,
        this.list.altTenantId,
      );
      const thumbUrl = await graphGetDocumentLibraryItemThumbnails(
        graphInterface.client,
        this.list.spDriveId,
        this.driveItemId,
      );

      return thumbUrl ?? '';
    } catch (err) {
      logger.debug('Error while getting the thumbnail url', err);

      return '';
    }
  }

  async setMetaData(
    graphInterfacePages: IGraphInterface | undefined,
    graphInterfaceDocuments: IGraphInterface | undefined,
    pageListId?: string,
  ): Promise<void> {
    try {
      if (this.isWholeList()) {
        return;
      }

      if (this.list && this.list.enableMetaData === true && !this.list.isVirtual) {
        switch (this.list.listType) {
          case ResourceListType.DocumentLibrary:
            if (!graphInterfaceDocuments) return;
            if (this.driveItemId && this.list.spDriveId) {
              const versions = await graphGetDriveItemVersions(
                graphInterfaceDocuments.client,
                this.list.spDriveId,
                this.driveItemId,
              );

              for (let idx = 0; idx < versions.length; idx++) {
                const version = versions[idx];
                if (version.id) {
                  const newVersion = new ResourceLinkVersion();
                  newVersion.id = version.id;
                  newVersion.date = version?.lastModifiedDateTime
                    ? new Date(version?.lastModifiedDateTime)
                    : new Date();
                  newVersion.version = version.id;
                  this.versions.push(newVersion);
                }
              }

              if (this.versions.length > 0) {
                const latestVersion = this.versions[0];
                this.changed = latestVersion.date;
                this.version = latestVersion.id;
              }
            }
            break;
          case ResourceListType.SitePageLibrary:
            if (!graphInterfacePages || !pageListId) return;
            if (this.list.spSiteId && this.pageId) {
              //try to get the list item based on the page name
              if (!this.listItemId) {
                const listItem = await graphGetSitePageListItem(
                  graphInterfacePages.client,
                  this.list.spSiteId,
                  pageListId,
                  this.linkURL,
                );
                if (listItem?.id) {
                  this.listItemId = listItem.id;
                }
              }

              const page = await graphGetSitePage(graphInterfacePages.client, this.list.spSiteId, this.pageId, false);
              this.publishingState = page?.publishingState.level ?? undefined;

              if (this.listItemId) {
                //if found, get the complete version history
                const versions = await graphGetListItemVersions(
                  graphInterfacePages.client,
                  this.list.spSiteId,
                  pageListId,
                  this.listItemId,
                );

                for (let idx = 0; idx < versions.length; idx++) {
                  const version = versions[idx];
                  if (version.id) {
                    const newVersion = new ResourceLinkVersion();
                    newVersion.id = version.id;
                    newVersion.version = version.id;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const fields: any = version.fields;
                    if (fields && fields['Modified']) {
                      newVersion.date = new Date(fields['Modified']);
                    } else if (page?.lastModifiedDateTime) {
                      newVersion.date = new Date(page.lastModifiedDateTime);
                    }
                    this.versions.push(newVersion);
                  }
                }

                if (this.versions.length > 0) {
                  const latestVersion = this.versions[0];
                  this.changed = latestVersion.date;
                  this.version = latestVersion.id;
                }
              } else {
                //we cannot get the list item. do not fill the version history, only the current from the page model
                const newVersion = new ResourceLinkVersion();

                if (page?.publishingState?.versionId) {
                  newVersion.id = page?.publishingState?.versionId;
                  newVersion.date = page?.lastModifiedDateTime ? new Date(page?.lastModifiedDateTime) : new Date();
                  newVersion.version = page?.publishingState?.versionId;
                  this.changed = newVersion.date;
                  this.version = newVersion.id;
                }
              }
            }
            break;
          default:
            this.version = '?';
            this.changed = undefined;
        }
      }
    } catch (err) {
      logger.debug('Error while getting the meta data', err);
    }
  }

  async getEmbedURL(appContext: IAppContext): Promise<string> {
    logger.debug('getEmbedURL', this);

    if (this.isWholeList()) {
      //return the web URL of the list
      if (this.list) {
        return this.list.webURL || '';
      }
    }

    let url: string = this.linkURL;

    try {
      if (this.list) {
        switch (this.list.listType) {
          case ResourceListType.DocumentLibrary:
            if (this.driveItemId && this.list.spDriveId) {
              const graphInterface = await appContext.getGraphInterface(
                graphSharepointLibraryRequest.scopes,
                this.list.altTenantId,
              );

              const embedUrl = await graphGetDocumentLibraryItemPreview(
                graphInterface.client,
                this.list.spDriveId,
                this.driveItemId,
              );

              if (embedUrl) {
                url = embedUrl;
              }
            }
            break;
          default:
            url = await this.getWebURL(appContext);

            return url;
        }
      }
    } catch (err) {
      logger.debug('Error while getting the embed url', err);
    } finally {
      if (this.linkURLFragment && this.linkURLFragment.trim() !== '') {
        url += this.linkURLFragment;
      }
    }

    return url;
  }

  async getWebURL(appContext: IAppContext): Promise<string> {
    logger.debug('getWebURL', this);

    if (this.isWholeList() && this.list) {
      //return the web URL of the list
      return this.list.webURL || '';
    }

    let url: string = this.linkURL;

    try {
      if (this.list) {
        switch (this.list.listType) {
          case ResourceListType.DocumentLibrary:
            if (this.driveItemId && this.list.spDriveId) {
              const graphInterface = await appContext.getGraphInterface(
                graphSharepointLibraryRequest.scopes,
                this.list.altTenantId,
              );

              const item = await graphGetDriveItem(graphInterface.client, this.list.spDriveId, this.driveItemId);
              url = item && item.webUrl ? item.webUrl : this.linkURL;
            }
            break;

          case ResourceListType.CustomList:
            if (this.listItemId && this.list.spSiteId && this.list.spListId) {
              const graphInterface = await appContext.getGraphInterface(
                graphSharepointListRequest.scopes,
                this.list.altTenantId,
              );
              const item = await graphGetListItem(
                graphInterface.client,
                this.list.spSiteId,
                this.list.spListId,
                this.listItemId,
              );
              const listItemUrl = this.buildLinkURLFromListItem(item);
              if (listItemUrl) {
                url = listItemUrl;
              }
            }
            break;

          case ResourceListType.SitePageLibrary:
            if (this.pageId && this.list.spSiteId) {
              const graphInterface = await appContext.getGraphInterface(
                graphSharepointPagesRequest.scopes,
                this.list.altTenantId,
              );

              const page: ISitePage = await graphGetSitePage(
                graphInterface.client,
                this.list.spSiteId,
                this.pageId,
                false,
              );

              url = page.webUrl;
              if (!url.startsWith('https://')) {
                const site: ISite = await graphGetSite(graphInterface.client, this.list.spSiteId);
                url = site.webUrl + '/' + url;
              }
            }
            break;
          case ResourceListType.WebURL:
          //nothing to-do
        }
      }
    } catch (err) {
      logger.debug('Error while getting the web url', err);
    } finally {
      if (this.linkURLFragment && this.linkURLFragment.trim() !== '') {
        url += this.linkURLFragment;
      }
    }

    return url;
  }

  buildLinkURLFromListItem = (item: ListItem | undefined): string | undefined => {
    if (item && item.webUrl) {
      let url = item.webUrl.substring(0, item.webUrl.lastIndexOf('/'));
      url = url + '/DispForm.aspx?';
      url = url + 'ID=';
      url = url + item.id?.toString();

      return url;
    }

    return undefined;
  };

  async isValid(appContext: IAppContext): Promise<boolean | undefined> {
    this.isValidated = true;
    if (this.isWholeList()) return true;
    if (!this.list) return false;
    if (this.list.isVirtual) return true;

    if (this.list.altTenantId) {
      //don't validate links in other tenants except the home account of the current user
      const currentHomeAccount = appContext.globalDataCache.userSettings.get(HomeAccount) as string;
      if (!currentHomeAccount || this.list.altTenantId !== currentHomeAccount) {
        return undefined;
      }
    }

    switch (this.list.listType) {
      case ResourceListType.DocumentLibrary:
        if (this.driveItemId && this.list.spDriveId) {
          const graphInterface = await appContext.getGraphInterface(
            graphSharepointLibraryRequest.scopes,
            this.list.altTenantId,
          );

          return graphValidateDocumentLibraryItem(graphInterface.client, this.list.spDriveId, this.driveItemId);
        } else {
          return false;
        }

      case ResourceListType.CustomList:
        if (this.listItemId && this.list.spSiteId && this.list.spListId) {
          const graphInterface = await appContext.getGraphInterface(
            graphSharepointListRequest.scopes,
            this.list.altTenantId,
          );

          return graphValidateListItem(graphInterface.client, this.list.spSiteId, this.list.spListId, this.listItemId);
        } else {
          return false;
        }

      case ResourceListType.SitePageLibrary:
        if (this.pageId && this.list.spSiteId) {
          const graphInterface = await appContext.getGraphInterface(
            graphSharepointListRequest.scopes,
            this.list.altTenantId,
          );

          return graphValidateSitePageItem(graphInterface.client, this.list.spSiteId, this.pageId);
        } else {
          return false;
        }

      case ResourceListType.WebURL:
        return fetch(this.linkURL, { mode: 'no-cors' })
          .then((r) => {
            return true;
          })
          .catch((e) => {
            return false;
          });

      default:
        return undefined;
    }
  }

  getPagePublicationStateText(t: TFunction): string {
    //the states are hard coded by Microsoft Graph API
    switch (this.publishingState) {
      case 'published':
        return t('sharepoint:PagePublicationState.Published');
      case 'draft':
        return t('sharepoint:PagePublicationState.Draft');
      case 'checkout':
        return t('sharepoint:PagePublicationState.Checkout');
      default:
        return '';
    }
  }
}

export class ResourceLinkVersion {
  id: string;

  date: Date;

  version: string;

  constructor() {
    this.id = '';
    this.date = new Date();
    this.version = '';
  }
}

export interface ILinkRow {
  item: ResourceLink;
  isBusy: boolean;
}

export class EntityLink extends Entity {
  pinned: boolean;

  linkURLFragment?: string;

  constructor() {
    super();
    this.pinned = false;
  }
}

export class KBTeam {
  group: Group;

  channels: TeamsChannel[];

  links: ResourceLink[];

  hash: string;

  constructor() {
    this.group = new Group(newGuidNil());
    this.channels = [];
    this.links = [];
    this.hash = newGuidNil();
  }

  setHash() {
    this.hash = this.group.id + '#' + this.channels.map((c) => c.channelId ?? newGuidNil()).join(',');
  }
}
